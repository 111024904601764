import styled from 'styled-components';

export const Content = styled.div`
    display: grid;
    grid-template-columns: 60% 40%;
    
    margin-left: -10%;
    margin-right: -10%;

    @media only screen and (max-width: 600px) {
        grid-template-columns: 100%;
    }
`;

export const WebcamContainer = styled.div`
    
`;

export const InfoContainer = styled.div`
    padding-left: 56px;
    
    border-left: 1px solid #cccc;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    svg {
        margin-top: 56px;
    }

    @media only screen and (max-width: 600px) {
        border-left: 0px;

        padding-left: 0px;

        margin-top: 32px;
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    
    padding-right: 62px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 600px) {
        padding-right: 0px;
    }
`;
