/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';

import { getSettings } from '../services/client';

import i18n from '../i18n';

const changeFavicon = (link) => {
  let $favicon = document.querySelector('link[rel="icon"]');
  if ($favicon !== null) {
    $favicon.href = link;
  } else {
    $favicon = document.createElement('link');
    $favicon.rel = 'icon';
    $favicon.href = link;
    document.head.appendChild($favicon);
  }
};

function setZendesk(zendesk) {
  const zd = document.createElement('script');
  zd.id = 'ze-snippet';
  zd.src = zendesk;
  document.head.appendChild(zd);
}

function setHuggy(huggyInfo) {
  const info = JSON.parse(huggyInfo);
  window.$_PowerZAP = {
    defaultCountry: info.defaultCountry,
    widget_id: info.widget_id,
    company: info.company,
  };
  // eslint-disable-next-line no-param-reassign
  (function (i, s, o, g, r, a, m) { i[r] = { context: { id: info.id } }; a = o; o = s.createElement(o); o.async = 1; o.src = g; m = s.getElementsByTagName(a)[0]; m.parentNode.insertBefore(o, m); }(window, document, 'script', 'https://w-cdn.huggy.io/widget.min.js?v=6.5.1', 'pwz'));
}

function setDefaultWidget(sett) {
  const existingAtend = document.getElementById('atendimento');
  if (!existingAtend) {
    const atend = document.createElement('div');
    atend.id = 'atendimento';
    document.body.appendChild(atend);
    document.getElementById('atendimento').innerHTML = `
          <div style="
            position: fixed;
            right: 20px;
            bottom: 20px;
            width: 90px;
            height: 40px;
            background-color: ${sett.colors.primary};
            border-radius: 25px;
            display: flex;
            justify-content: space-around;
            padding: 15px;
            align-items: center;
            font-size: 10pt;
            color: ${sett.colors.text};
            opacity: 0.9;
            cursor: pointer;"
          >
            <span style="color: white; font-size: 8pt">💬  </span>  Ajuda
          </div>`;
    document.getElementById('atendimento').addEventListener('click', () => {
      window.open('/support', '_blank', 'noopener,noreferrer');
    });
  }
}

const SettingsContext = createContext({});

// eslint-disable-next-line react/prop-types
function SettingsProvider({ children }) {
  const [settings, setSettings] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState();

  const getEmailSupportMessage = (login = false) => {
    const emailDisplay = settings.support_email
     && settings.support_email !== '-' ? settings.support_email : settings.display_name;

    return login
      ? `Ocorreu um erro no login, gentileza entre em contato com o suporte pelo email ${emailDisplay}`
      : `Em caso de dúvida, entre em contato com ${emailDisplay}`;
  };

  const changeLanguage = useCallback((language) => {
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
  }, []);

  const updateSettings = useCallback(async () => {
    const sett = await getSettings();

    const faviconPath = `/favicon_${sett?.name || 'default'}.png`;
    changeFavicon(faviconPath);

    const ibLanguage = localStorage.getItem('@qesh:ib_language');
    changeLanguage(ibLanguage !== null ? ibLanguage : sett?.ib_language);

    if (sett.support_zendesk) {
      setZendesk(sett.support_zendesk);
    } else if (sett.support_huggy) {
      setHuggy(sett.support_huggy);
    } else {
      setDefaultWidget(sett);
    }

    setSettings(sett);
    return sett;
  }, [changeLanguage]);

  useEffect(() => {
    updateSettings();
  }, [updateSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        language: currentLanguage,
        setLanguage: setCurrentLanguage,
        updateSettings,
        getEmailSupportMessage,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

function useSettings() {
  const context = useContext(SettingsContext);
  return context;
}

export { SettingsProvider, useSettings };
