import api from '../api';

export const identityCardFront = async (frontDocument, token) => {
  const formData = new FormData();
  formData.append('file', frontDocument);
  const headers = token ? {
    'Content-Type': 'multipart/form-data',
    authorization: `bearer ${token}`,
  } : {
    'Content-Type': 'multipart/form-data',
  }
  return api
    .post('onboarding/documents/IDENTITY_CARD_FRONT', formData, {
      headers
    })
    .then((response) => [false])
    .catch((error) => {
      console.log('Error IdentityFront: ', error.response.data.message);
      return [true, error.response?.data?.message];
    });
};

export const identityCardVerse = async (verseDocument, token) => {
  const formData = new FormData();
  formData.append('file', verseDocument);
  const headers = token ? {
    'Content-Type': 'multipart/form-data',
    authorization: `bearer ${token}`,
  } : {
    'Content-Type': 'multipart/form-data',
  }
  return api
    .post('onboarding/documents/IDENTITY_CARD_VERSE', formData, {
      headers
    })
    .then((response) => [false])
    .catch((error) => {
      console.log('Error IdentityVerse: ', error.response.data.message);
      return [true, error?.response?.data?.message];
    });
};

export const driverLicenseFront = async (driverDocumentFront, token) => {
  const formData = new FormData();
  formData.append('file', driverDocumentFront);
  const headers = token ? {
    'Content-Type': 'multipart/form-data',
    authorization: `bearer ${token}`,
  } : {
    'Content-Type': 'multipart/form-data',
  }

  return api
    .post('onboarding/documents/DRIVER_LICENSE_FRONT', formData, {
      headers
    })
    .then(() => [false])
    .catch((error) => {
      console.log(error.response.data.message);
      return [true, error?.response?.data?.message];
    });
};

export const driverLicenseVerse = async (driverVerseDocument, token) => {
  const formData = new FormData();
  formData.append('file', driverVerseDocument);
  const headers = token ? {
    'Content-Type': 'multipart/form-data',
    authorization: `bearer ${token}`,
  } : {
    'Content-Type': 'multipart/form-data',
  }
  return api
    .post('onboarding/documents/DRIVER_LICENSE_VERSE', formData, {
     headers
    })
    .then(() => [false])
    .catch((error) => [true, error?.response?.data?.message]);
};

export const selfieIdentity = async (selfieImg, token) => {
  const formData = new FormData();
  formData.append('file', selfieImg.file[0]);
  const headers = token ? {
    'Content-Type': 'multipart/form-data',
    authorization: `bearer ${token}`,
  } : {
    'Content-Type': 'multipart/form-data',
  }
  return api
    .post('onboarding/documents/SELFIE', formData, {
      headers
    })
    .then(() => [false])
    .catch((error) => {
      console.log(error.response.data.message);
      return [true, error?.response?.data?.message];
    });
};

/**
 * Request type post, to send documents PF foreign person
 * @property {object} document - obj with arquives documents uploads
 * @property {string} token - token account user PF
 * @property {string} documentType - type document in the send, in router defined
 * ('CRNM_FRONT' ,'CRNM_VERSE', 'PASSPORT_CARD' , 'RNE_FRONT' , 'RNE_VERSE')
 * @returns {Promise<>}
 */

export const documentsForeign = async (document, token, documentType) => {
  const formData = new FormData();
  formData.append('file', document);
  const headers = token ? {
    'Content-Type': 'multipart/form-data',
    authorization: `bearer ${token}`,
  } : {
    'Content-Type': 'multipart/form-data',
  }
  return api
    .post(`onboarding/documents/${documentType}`, formData, {
      headers
    })
    .then((response) => [false])
    .catch((error) => {
      console.log('erro envio documento foreign', error.response.data);
      return [true, error?.response?.data?.message];
    });
};
