/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import DayPicker from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';

import {
  format, parseISO, startOfMonth, isValid,
} from 'date-fns';

import {
  Table, Skeleton, Spin, Modal, Tabs,
} from 'antd';
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';

import {
  FaReceipt, FaExchangeAlt, FaDonate, FaBarcode, FaCalendarAlt,
} from 'react-icons/fa';

import api from '../../services/api';
import { HandleFormatMoney } from '../../services/utils';
import { useSettings } from '../../hooks/settings';

import {
  ContentTab, HeaderTab, FilterDate, DownloadButtonsContainer,
} from './styles';
import Receipt from './Receipt';
import CustomFileIcon from '../../components/CustomFileIcon';
import useOnClickOutside from '../../hooks/onClickOutside';

const { Column } = Table;
const { TabPane } = Tabs;

function Extract() {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [extracts, setExtracts] = useState([]);
  const [inAmount, setInAmount] = useState(0);
  const [outAmount, setOutAmount] = useState(0);
  const [metaData, setMetaData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [start, setStart] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [end, setEnd] = useState(format(new Date(), 'yyyy-MM-dd'));
  const endRef = useRef(null);
  const startRef = useRef(null);
  const { settings, language } = useSettings();
  const [receipt, setReceipt] = useState('');
  const [reportLoading, setReportLoading] = useState(false);
  const refStartCalendar = useRef();
  const refEndCalendar = useRef();
  const { t } = useTranslation();
  useOnClickOutside(refStartCalendar, () => startRef.current.handleInputBlur());
  useOnClickOutside(refEndCalendar, () => endRef.current.handleInputBlur());

  const getTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/transactions?page=0&start=${start}&end=${end}`);

      const translatedItems = [];
      if (settings.name === 'wibbank' && language !== 'ptbr') {
        data.items?.forEach((it) => {
          translatedItems.push({
            ...it,
            description: it.description
              .replace('TRANSFERÊNCIA PARA', '银行转账到')
              .replace('ESTORNO', '转移逆转')
              .replace('TRANSFERÊNCIA RECEBIDA DE', '银行转账收到 - ')
              .replace('PG RECEBIDO', '已收到付款')
              .replace('PAGAMENTO DE CONTAS', '已付帐单')
              .replace('TARIFA', '关税'),
          });
        });
      }

      setExtracts(translatedItems.length ? translatedItems : data.items);
      setMetaData({
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_items: data.total_items,
        total_items_page: data.total_items_page,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [start, end, language, settings.name]);

  const getMoreTransactions = useCallback(async () => {
    setLoadingMore(true);
    try {
      const { data } = await api.get(
        `/transactions?page=${currentPage + 1}&start=${start}&end=${end}`,
      );
      setCurrentPage(currentPage + 1);
      const translatedItems = [];
      if (settings.name === 'wibbank' && language !== 'ptbr') {
        data.items?.forEach((it) => {
          translatedItems.push({
            ...it,
            description: it.description
              .replace('TRANSFERÊNCIA PARA', '银行转账到')
              .replace('ESTORNO', '转移逆转')
              .replace('TRANSFERÊNCIA RECEBIDA DE', '银行转账收到 - ')
              .replace('PG RECEBIDO', '已收到付款')
              .replace('PAGAMENTO DE CONTAS', '已付帐单')
              .replace('TARIFA', '关税'),
          });
        });
      }
      setExtracts([...extracts, ...(translatedItems.length ? translatedItems : data.items)]);
      setMetaData({
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_items: data.total_items,
        total_items_page: data.total_items_page,
      });
      setLoading(false);
      setLoadingMore(false);
    } catch (err) {
      setLoading(false);
      setLoadingMore(false);
    }
  }, [extracts, currentPage, start, end, language, settings.name]);

  const getAllTransactions = useCallback(async () => {
    setLoadingMore(true);
    const extract = [];
    try {
      const { data } = await api.get(
        `/transactions?page=0&start=${start}&end=${end}`,
      );
      const translatedItems = [];
      if (settings.name === 'wibbank' && language !== 'ptbr') {
        data.items?.forEach((it) => {
          translatedItems.push({
            ...it,
            description: it.description
              .replace('TRANSFERÊNCIA PARA', '银行转账到')
              .replace('ESTORNO', '转移逆转')
              .replace('TRANSFERÊNCIA RECEBIDA DE', '银行转账收到 - ')
              .replace('PG RECEBIDO', '已收到付款')
              .replace('PAGAMENTO DE CONTAS', '已付帐单')
              .replace('TARIFA', '关税'),
          });
        });
      }
      extract.push(...(translatedItems.length ? translatedItems : data.items));
      for (let i = 1; i <= data.total_pages; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const { data: data2 } = await api.get(
          `/transactions?page=${i}&start=${start}&end=${end}`,
        );
        if (settings.name === 'wibbank' && language !== 'ptbr') {
          data.items?.forEach((it) => {
            translatedItems.push({
              ...it,
              description: it.description
                .replace('TRANSFERÊNCIA PARA', '银行转账到')
                .replace('ESTORNO', '转移逆转')
                .replace('TRANSFERÊNCIA RECEBIDA DE', '银行转账收到 - ')
                .replace('PG RECEBIDO', '已收到付款')
                .replace('PAGAMENTO DE CONTAS', '已付帐单')
                .replace('TARIFA', '关税'),
            });
          });
        }
        extract.push(...(translatedItems.length ? translatedItems : data2.items));
      }
      setExtracts([...extract]);
      setMetaData({
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_items: data.total_items,
        total_items_page: data.total_items_page,
      });
      setCurrentPage(data.total_pages + 1);
      setLoadingMore(false);
      return extract;
    } catch (err) {
      setLoadingMore(false);
      return [];
    }
  }, [start, end, language, settings.name]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const formatAmount = useCallback((item) => {
    if (item.amount === 0) {
      return <span style={{ fontWeight: 600, fontSize: '16px' }}>{HandleFormatMoney(item.amount)}</span>;
    }
    if (item.credit) {
      return (
        <span style={{ color: '#38C77E', fontWeight: 600, fontSize: '16px' }}>
          + {HandleFormatMoney(item.amount)}
        </span>
      );
    }

    if (!item.credit) {
      return (
        <span style={{ color: 'red', fontWeight: 600, fontSize: '16px' }}>- {HandleFormatMoney(item.amount)}</span>
      );
    }

    return <span style={{ fontWeight: 600, fontSize: '16px' }}>-{HandleFormatMoney(item.amount)}</span>;
  }, []);

  const formatIcon = useCallback((item) => {
    if ((item.type === 'TED' || item.type === 'TEF' || item.type === 'PIX') && !item.credit) {
      return <FaExchangeAlt />;
    }

    if ((item.type === 'TED' || item.type === 'TEF' || item.type === 'PIX') && item.credit) {
      return <FaDonate />;
    }

    if ((item.type === 'PAYMENT' || item.type === 'BILLET')) {
      return <FaBarcode />;
    }

    return <FaReceipt />;
  }, []);

  const startDate = useMemo(() => format(parseISO(start), 'dd/MM/yyyy'), [start]);
  const endDate = useMemo(() => format(parseISO(end), 'dd/MM/yyyy'), [end]);

  function formatDate(date) {
    return format(date, 'dd/MM/yyyy');
  }

  const handleChangeDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setStart(formatDay);
      } else {
        setEnd(formatDay);
      }
    }
  }, []);

  const handleSelectTab = useCallback(async (selected) => {
    let allItems = [];
    let filtered = [];
    let inValue = 0;
    let outValue = 0;
    if (metaData) {
      switch (selected) {
        case '1':
          setExtracts([]);
          setCurrentPage(0);
          await getTransactions();
          break;
        case '2':
          allItems = await getAllTransactions();
          filtered = allItems?.filter((i) => i.type === 'TED' || i.type === 'TEF');
          setExtracts(filtered);
          break;
        case '3':
          allItems = await getAllTransactions();
          filtered = allItems?.filter((i) => i.type === 'BILLET');
          setExtracts(filtered);
          break;
        case '4':
          allItems = await getAllTransactions();
          filtered = allItems?.filter((i) => i.type === 'PAYMENT');
          setExtracts(filtered);
          break;
        case '5':
          allItems = await getAllTransactions();
          filtered = allItems?.filter((i) => i.type === 'PIX');
          setExtracts(filtered);
          break;
        default:
      }
      filtered.forEach((item) => {
        if (item?.credit) {
          inValue += item?.amount;
        } else {
          outValue += item?.amount;
        }
      });
      setInAmount(inValue);
      setOutAmount(outValue);
    }
  }, [metaData, getAllTransactions, getTransactions]);

  const handleDownloadReport = useCallback(async (type) => {
    setReportLoading(true);
    try {
      const { data } = await api.get(`/transactions/reports/${type}?start=${start}&end=${end}`);
      setTimeout(() => {
        window.open(data.fileName, '_blank', 'noopener,noreferrer');
        setReportLoading(false);
      }, 4000);
    } catch (err) {
      console.log(err);
    }
  }, [start, end]);

  return (
    <ContentTab color={settings.colors.primary}>
      <HeaderTab>
        <FilterDate>
          <span>{t('de')}</span>
          <section ref={refStartCalendar}>
            <DayPicker
              ref={startRef}
              isDisabled
              dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
              format="dd/MM/yyyy"
              formatDate={formatDate}
              value={startDate}
              onDayChange={(day) => handleChangeDate(day, 'start')}
            />
            <FaCalendarAlt color={settings.colors.primary} onClick={() => startRef.current.handleInputClick()} />
          </section>

          <span>{t('ate')}</span>

          <section ref={refEndCalendar}>
            <DayPicker
              ref={endRef}
              dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
              format="dd/MM/yyyy"
              formatDate={formatDate}
              onDayChange={(day) => handleChangeDate(day, 'end')}
              value={endDate}
            />
            <FaCalendarAlt color={settings.colors.primary} onClick={() => endRef.current.handleInputClick()} />
          </section>
        </FilterDate>
        <DownloadButtonsContainer>
          {reportLoading
            ? <LoadingOutlined size={20} style={{ marginRight: 'auto', marginLeft: 'auto' }} />
            : (
              <>
                <span style={{ fontSize: 10, marginTop: 'auto', marginBottom: 'auto' }}>{t('exportar para')}</span>
                <CustomFileIcon
                  size={30}
                  text="XLS"
                  onClick={() => handleDownloadReport('xlsx')}
                />
                <CustomFileIcon
                  size={30}
                  text="OFX"
                  onClick={() => handleDownloadReport('ofx')}
                />
                <CustomFileIcon
                  size={30}
                  text="PDF"
                  onClick={() => handleDownloadReport('pdf')}
                />
              </>
            ) }

        </DownloadButtonsContainer>
      </HeaderTab>
      {loading ? (
        <>
          <Skeleton active title={t('extrato')} loading={loading} paragraph={2} rows={2} />
          <Skeleton active title={t('extrato')} loading={loading} paragraph={2} rows={2} />
          <Skeleton active title={t('extrato')} loading={loading} paragraph={2} rows={2} />
        </>
      ) : (
        <>
          <Tabs onChange={handleSelectTab} type="line">
            <TabPane tab={t('todas as transacoes')} key="1">
              <Table
                dataSource={extracts}
                pagination={false}
                rowKey="id"
                loading={loading}
                locale={{
                  emptyText: t('nenhum lancamento para exibir'),
                }}
                scroll={{ x: true }}
              >

                <Column
                  title=""
                  dataIndex="name"
                  key="name"
                  responsive={['xs', 'sm', 'md', 'xl']}
                  render={(text, record) => (
                    <div className="icon">
                      {formatIcon(record)}
                      <div />
                    </div>
                  )}
                />

                <Column
                  title=""
                  dataIndex="description"
                  key="description"
                  responsive={['xs', 'sm', 'md', 'xl']}
                />

                <Column
                  title=""
                  dataIndex="date"
                  key="date"
                  responsive={['xs', 'sm', 'md', 'xl']}
                  render={(text, record) => (
                    <div style={{ textAlign: 'right' }}>
                      <div>{format(parseISO(record.date), 'dd/MM/yyyy')}</div>
                      {formatAmount(record)}
                    </div>
                  )}
                />

                <Column
                  title=""
                  dataIndex="namex"
                  key="namex"
                  responsive={['xs', 'sm', 'md', 'xl']}
                  render={(text, record) => (
                    <div>
                      {record.type === 'OPEN_ACCOUNT' || record.type === 'FEE' ? null
                        : (
                          <FaReceipt
                            style={{ cursor: 'pointer' }}
                            size={19}
                            color="#636466"
                            onClick={() => setReceipt(record)}
                          />
                        )}
                    </div>
                  )}
                />

              </Table>

              <div style={{
                cursor: 'pointer',
                margin: '15px auto',
                display: 'flex',
                width: '200px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                {loadingMore ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#121212' }} spin />} />
                ) : (
                  <>
                    {metaData.total_items_page >= 50 && metaData.current_page < metaData.total_pages && (
                    <div
                      onClick={() => getMoreTransactions()}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '18px',
                      }}
                    >
                      <span style={{ paddingRight: '5px' }}>{t('carregar mais')}</span> <PlusCircleOutlined />
                    </div>
                    )}
                  </>
                )}
              </div>
            </TabPane>
            <TabPane tab={t('transferencias')} key="2">
              {loadingMore ? (
                <><Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                  <Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                  <Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                </>
              ) : (
                <>
                  <div style={{
                    width: '100%',
                    marginBottom: 20,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    backgroundColor: '#F4F4F4',
                    minHeight: 100,
                    alignItems: 'center',
                    borderRadius: 8,
                  }}
                  >
                    <span style={{
                      fontSize: 21, color: '#24A148', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(inAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Entradas
                      </span>
                    </span>
                    <span style={{
                      fontSize: 21, color: '#DA1E28', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(outAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Saídas
                      </span>
                    </span>
                    <span style={{
                      fontSize: 21, color: '#42c1c7', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(inAmount - outAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Saldo por movimentação
                      </span>
                    </span>
                  </div>
                  <Table
                    dataSource={extracts}
                    pagination={false}
                    rowKey="id"
                    loading={loading}
                    locale={{
                      emptyText: t('nenhum lancamento para exibir'),
                    }}
                    scroll={{ x: true }}
                  >

                    <Column
                      title=""
                      dataIndex="name"
                      key="name"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div className="icon">
                          {formatIcon(record)}
                          <div />
                        </div>
                      )}
                    />

                    <Column
                      title=""
                      dataIndex="description"
                      key="description"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="date"
                      key="date"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div style={{ textAlign: 'right' }}>
                          <div>{format(parseISO(record.date), 'dd/MM/yyyy')}</div>
                          {formatAmount(record)}
                        </div>
                      )}
                    />

                    <Column
                      title=""
                      dataIndex="namex"
                      key="namex"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div>
                          {record.type === 'OPEN_ACCOUNT' || record.type === 'FEE' ? null
                            : (
                              <FaReceipt
                                style={{ cursor: 'pointer' }}
                                size={19}
                                color="#636466"
                                onClick={() => setReceipt(record)}
                              />
                            )}
                        </div>
                      )}
                    />
                  </Table>
                </>
              )}
            </TabPane>
            <TabPane tab={t('recebimento de boletos')} key="3">
              {loadingMore ? (
                <><Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                  <Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                  <Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                </>
              ) : (
                <>
                  <div style={{
                    width: '100%',
                    marginBottom: 20,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    backgroundColor: '#F4F4F4',
                    minHeight: 100,
                    alignItems: 'center',
                    borderRadius: 8,
                  }}
                  >
                    <span style={{
                      fontSize: 21, color: '#24A148', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(inAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Entradas
                      </span>
                    </span>
                    <span style={{
                      fontSize: 21, color: '#DA1E28', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(outAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Saídas
                      </span>
                    </span>
                    <span style={{
                      fontSize: 21, color: '#42c1c7', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(inAmount - outAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Saldo por movimentação
                      </span>
                    </span>
                  </div>
                  <Table
                    dataSource={extracts}
                    pagination={false}
                    rowKey="id"
                    loading={loading}
                    locale={{
                      emptyText: t('nenhum lancamento para exibir'),
                    }}
                    scroll={{ x: true }}
                  >

                    <Column
                      title=""
                      dataIndex="name"
                      key="name"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div className="icon">
                          {formatIcon(record)}
                          <div />
                        </div>
                      )}
                    />

                    <Column
                      title=""
                      dataIndex="description"
                      key="description"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="date"
                      key="date"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div style={{ textAlign: 'right' }}>
                          <div>{format(parseISO(record.date), 'dd/MM/yyyy')}</div>
                          {formatAmount(record)}
                        </div>
                      )}
                    />

                    <Column
                      title=""
                      dataIndex="namex"
                      key="namex"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div>
                          {record.type === 'OPEN_ACCOUNT' || record.type === 'FEE' ? null
                            : (
                              <FaReceipt
                                style={{ cursor: 'pointer' }}
                                size={19}
                                color="#636466"
                                onClick={() => setReceipt(record)}
                              />
                            )}
                        </div>
                      )}
                    />

                  </Table>
                </>
              )}
            </TabPane>
            <TabPane tab={t('pagamentos')} key="4">
              {loadingMore ? (
                <><Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                  <Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                  <Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                </>
              ) : (
                <>
                  <div style={{
                    width: '100%',
                    marginBottom: 20,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    backgroundColor: '#F4F4F4',
                    minHeight: 100,
                    alignItems: 'center',
                    borderRadius: 8,
                  }}
                  >
                    <span style={{
                      fontSize: 21, color: '#24A148', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(inAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Entradas
                      </span>
                    </span>
                    <span style={{
                      fontSize: 21, color: '#DA1E28', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(outAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Saídas
                      </span>
                    </span>
                    <span style={{
                      fontSize: 21, color: '#42c1c7', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(inAmount - outAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Saldo por movimentação
                      </span>
                    </span>
                  </div>
                  <Table
                    dataSource={extracts}
                    pagination={false}
                    rowKey="id"
                    loading={loading}
                    locale={{
                      emptyText: t('nenhum lancamento para exibir'),
                    }}
                    scroll={{ x: true }}
                  >

                    <Column
                      title=""
                      dataIndex="name"
                      key="name"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div className="icon">
                          {formatIcon(record)}
                          <div />
                        </div>
                      )}
                    />

                    <Column
                      title=""
                      dataIndex="description"
                      key="description"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="date"
                      key="date"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div style={{ textAlign: 'right' }}>
                          <div>{format(parseISO(record.date), 'dd/MM/yyyy')}</div>
                          {formatAmount(record)}
                        </div>
                      )}
                    />

                    <Column
                      title=""
                      dataIndex="namex"
                      key="namex"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div>
                          {record.type === 'OPEN_ACCOUNT' || record.type === 'FEE' ? null
                            : (
                              <FaReceipt
                                style={{ cursor: 'pointer' }}
                                size={19}
                                color="#636466"
                                onClick={() => setReceipt(record)}
                              />
                            )}
                        </div>
                      )}
                    />

                  </Table>
                </>
              )}
            </TabPane>
            <TabPane tab="PIX" key="5">
              {loadingMore ? (
                <><Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                  <Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                  <Skeleton active title={t('extrato')} loading paragraph={2} rows={2} />
                </>
              ) : (
                <>
                  <div style={{
                    width: '100%',
                    marginBottom: 20,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    backgroundColor: '#F4F4F4',
                    minHeight: 100,
                    alignItems: 'center',
                    borderRadius: 8,
                  }}
                  >
                    <span style={{
                      fontSize: 21, color: '#24A148', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(inAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Entradas
                      </span>
                    </span>
                    <span style={{
                      fontSize: 21, color: '#DA1E28', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(outAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Saídas
                      </span>
                    </span>
                    <span style={{
                      fontSize: 21, color: '#42c1c7', margin: 10, lineHeight: 1, fontWeight: 'bold',
                    }}
                    >
                      {HandleFormatMoney(inAmount - outAmount)}<br />
                      <span
                        style={{ fontSize: 10, fontWeight: 'normal', color: 'rgba(0,0,0,0.8)' }}
                      >Saldo por movimentação
                      </span>
                    </span>
                  </div>
                  <Table
                    dataSource={extracts}
                    pagination={false}
                    rowKey="id"
                    loading={loading}
                    locale={{
                      emptyText: t('nenhum lancamento para exibir'),
                    }}
                    scroll={{ x: true }}
                  >

                    <Column
                      title=""
                      dataIndex="name"
                      key="name"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div className="icon">
                          {formatIcon(record)}
                          <div />
                        </div>
                      )}
                    />

                    <Column
                      title=""
                      dataIndex="description"
                      key="description"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="date"
                      key="date"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div style={{ textAlign: 'right' }}>
                          <div>{format(parseISO(record.date), 'dd/MM/yyyy')}</div>
                          {formatAmount(record)}
                        </div>
                      )}
                    />

                    <Column
                      title=""
                      dataIndex="namex"
                      key="namex"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text, record) => (
                        <div>
                          {record.type === 'OPEN_ACCOUNT' || record.type === 'FEE' ? null
                            : (
                              <FaReceipt
                                style={{ cursor: 'pointer' }}
                                size={19}
                                color="#636466"
                                onClick={() => setReceipt(record)}
                              />
                            )}
                        </div>
                      )}
                    />

                  </Table>
                </>
              )}
            </TabPane>

          </Tabs>
        </>
      )}
      <Modal
        centered
        visible={!!receipt}
        onOk={() => setReceipt('')}
        closable={false}
        onCancel={() => setReceipt('')}
        footer={false}
        destroyOnClose
        style={{ padding: 0, margin: 0 }}
      >
        <Receipt item={receipt} onCancel={() => setReceipt('')} settings={settings} />
      </Modal>
    </ContentTab>
  );
}

export default Extract;
