import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { Row, Col } from '../../styles/components';
import Input from '../../components/Input';
import Button from '../../components/Button';
import RegisterHeader from '../../components/RegisterHeader';
import { FormContainer } from './styles';
import { documentMask, findGetParameter } from '../../services/utils';
import Stepper from '../../components/Stepper';

function Register() {
  const formRef = useRef();
  const history = useHistory();
  const [document, setDocument] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (findGetParameter('is_pool')) {
      localStorage.setItem('@is_pool', !!findGetParameter('is_pool'));
    }
  }, []);

  const handleSubmit = useCallback(async (_data) => {
    formRef.current.setErrors({});
    try {
      const rawDocument = document.replace(/[-./]/g, '');

      if (!cpf.isValid(rawDocument) && !cnpj.isValid(rawDocument)) {
        formRef.current.setErrors({ document: t('o documento nao e valido') });
        return false;
      }

      if (cpf.isValid(rawDocument)) {
        history.push(`/register/pf/${rawDocument}${findGetParameter('is_pool') ? '?is_pool' : ''}`);
        return true;
      }

      history.push(`/register/pj/${rawDocument}${findGetParameter('is_pool') ? '?is_pool' : ''}`);
      return true;
    } catch (err) {
      formRef.current.setErrors({ document: t('o documento nao e valido') });
    }
    return false;
  }, [document, t, history]);

  return (
    <>
      <RegisterHeader />
      <FormContainer style={{
        textAlign: 'center',
        width: '84%',
        marginLeft: '8%',
      }}
      >
        <h1>{t('digite seu cpf/cnpj para se cadastrar')}</h1>
        <br />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row gutter={16} style={{ justifyContent: 'center' }}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14} className="bottom">
              <Input
                id="document"
                value={documentMask(document)}
                onChange={(e) => setDocument(e.target.value)}
                name="document"
                label={t('cpf / cnpj')}
                type="text"
              />
            </Col>
          </Row>
          <Row style={{
            marginBottom: '40px', marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <Button type="submit" style={{ marginLeft: 'auto', marginBottom: '0' }}>
              <span>{t('proximo')}</span>
            </Button>
          </Row>
        </Form>
        <Stepper numPag={1} />

      </FormContainer>
    </>
  );
}

export default Register;
