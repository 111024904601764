/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect } from 'react';
import Cleave from 'cleave.js/react';
import { cpf } from 'cpf-cnpj-validator';

import { InputPropTypes, DefaultPropTypes } from './Types';
import { Container, StyledInput } from './styles';
import { Alert } from 'antd';
import messages from '../MessageError';

const Cpf = ({
  setIsValid, value, className, ...rest
}) => {
  const optionsCPF = { blocks: [3, 3, 3, 2], delimiters: ['.', '.', '-'], numericOnly: true };

  useEffect(() => {
    if (typeof setIsValid === 'function') {
      if (cpf.isValid(value)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [value, setIsValid]);
  return (
    <Container>
      {!!rest.label && <label htmlFor={rest.id}>{rest.label}</label>}
      <StyledInput
        className={`ant-input ${className} ${
          value?.length === 11 ? (cpf.isValid(value) ? 'is-valid' : 'is-invalid') : ''
        }`}
      >
        <Cleave {...rest} value={value} className="ant-input" options={optionsCPF} autoFocus />
        <i />
      </StyledInput>
      {(!cpf.isValid(value) && value.length === 11) && (<Alert
        style={{ border: 'none', backgroundColor: 'transparent', padding: '8px 0px' }}
        type="error"
        message={messages.cpfInvalid}
      />)}
    </Container>
  );
};

export default Cpf;

Cpf.propTypes = {
  ...InputPropTypes,
  value: (props) => props.value === undefined && new Error('Value necessário para validação!'),
  setIsValid: (props) => props.setIsValid && typeof props.setIsValid !== 'function' && new Error('setIsValid deve ser uma função!'),
};

Cpf.defaultProps = {
  ...DefaultPropTypes,
  value: null,
  setIsValid: undefined,
};
