/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSun,
  faHiking,
  faGlasses,
  faCamera,
  faFaceLaugh,
  faPerson,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import Webcam from 'react-webcam';
import { useHistory } from 'react-router-dom';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import FormData from 'form-data';
import { useSettings } from '../../../../../../hooks/settings';
import { useCheckpoint } from '../../../../../../hooks/checkpoint';
import { useSteps } from '../../../../../../hooks/steps';
import ButtonN from '../../../../../../components/ButtonN';
import {
  Section,
  TextStyled,
  InfoStyled,
  CamStyled,
  Preview,
  ButtonPicture,
  ButtonPhoto,
  FalseModel,
  OverlayContainer,
  Container,
  Box,
  Corner1,
  Corner2,
  Corner3,
  Corner4,
} from './styles';
import { Title } from '../../../../styles';
import api from '../../../../../../services/api';
import eyesMask from '../../../../../../assets/eyes-mask.png';
import { HeaderArea, WhiteText, WhiteTitle, } from '../../../../../RefreshData/Components/Cam/styles';
import { RedText, TipsArea, TitleArea } from '../../../../../RefreshData/Validation/Selfie/styles';

const Cam = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const {
    nextStep, step, stepsPf, exactStep, resetStep, setPhotoAttempts, photoAttempts
  } = useSteps();
  const history = useHistory();
  const { getEmailSupportMessage } = useSettings();
  const [image, setImage] = useState('');
  const webcamRef = React.useRef(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [photoData, setPhotoData] = useState({ photo: '' });
  const [loading, setLoading] = useState(false);
  const [, setError] = useState('');
  const token = localStorage.getItem('@qesh:token');
  const lastAttempt = photoAttempts === 1;

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setPhotoData({ ...photoData, photo: imageSrc });
  }, [webcamRef, photoData]);

  useEffect(() => {
    if (
      localStorage.getItem('@qesh:token')
      && sessionStorage.getItem('@qesh:step') === '10'
    ) {
      exactStep(10);
    } else {
      resetStep();
      history.replace('/register/');
    }
  }, []);

  const videoConstraints = {
    width: 300,
    height: 400,
    facingMode: 'user',
  };

  const eyesMaskSvg = () => (
    <img
      style={{ width: '300px', height: '400px' }}
      src={eyesMask}
      alt="eyes-mask"
    />
  );

  const handleAdvance = async () => {
    setCheckpoint({ ...checkpoint, photoData });
    nextStep();
    if (stepsPf.length >= step + 1) {
      history.replace(`/register/${stepsPf[step + 1]}`);
    } else {
      history.replace(`/register/${stepsPf[step]}`);
    }
  };

  const handleApiSelfie = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', photoData.photo);

    fetch(photoData.photo)
      .then((res) => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const file = new File([blob], 'selfie.png');
        formData.append('file', file);
        api
          .post('onboarding/documents/SELFIE', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `bearer ${token}`,
            },
          })
          .then(() => {
            setLoading(false);
            setError(false);
            handleAdvance();
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
            setPhotoAttempts(error?.response?.data?.message?.attempts);
            if (
              error.response.data.message.message
              === 'Could not detect a face. Try another image. '
            ) {
              message.error(
                'Não foi possível detectar sua face. Tente novamente.',
                6,
              );
            } else if (
              error.response.data.message.message
              === 'Please try to avoid excessive light in the background. '
            ) {
              return message.error(
                'Evite excesso de luz no ambiente. Tente novamente. ',
                6,
              );
            } else if (error.response.data.message.attempts < 1) {
              return message.error(
                `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`,
                7,
              );
            } else {
              return message.error(
                `Erro ao validar a imagem, gentileza envie outra foto.`,
                7,
              );
            }
          });
      });
  };
  return (
    <>
      {!cameraActive && window.alert('Por favor ative sua camera')}
      <Section>
        <CamStyled>
        <HeaderArea>
            {lastAttempt ? (
              <>
                <WhiteTitle>Parece que tivemos algum problema com a sua foto</WhiteTitle>
                <WhiteTitle>Você só tem mais <span style={{ color: '#e22012' }}>1 tentativa</span>!</WhiteTitle>
              </>
            ) : (
              <>
                <WhiteTitle>Hora da foto!</WhiteTitle>
                <WhiteText>
                  Para garantir que ninguém esteja se passando por você, precisamos que nos envie uma foto do
                  seu rosto. Você terá apenas <span style={{ color: '#e22012' }}>5 tentativas </span>
                  para tirar a foto, então leia bem e siga todas as
                  as intruções. Vamos lá?
                </WhiteText>
              </>
            )}
          </HeaderArea>
          <>
            {image === '' ? (
              <Box>
                <Container>
                  <Webcam
                    audio={false}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/png"
                    ref={webcamRef}
                    screenshotQuality={1}
                    imageSmoothing
                    mirrored
                  />

                  <OverlayContainer>
                    <Corner1 borderColor={lastAttempt && '#e22012'} />
                    <Corner2 borderColor={lastAttempt && '#e22012'} />
                    <Corner3 borderColor={lastAttempt && '#e22012'} />
                    <Corner4 borderColor={lastAttempt && '#e22012'} />
                    {eyesMaskSvg()}
                  </OverlayContainer>
                </Container>
              </Box>
            ) : (
              <Preview src={image} />
            )}
          </>

          {image !== '' ? (
            <ButtonPhoto>
              <ButtonN
                style={{ background: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  setImage('');
                }}
                disabled={loading}
              >
                Tirar outra
              </ButtonN>
              <ButtonN
                onClick={() => {
                  handleApiSelfie();
                }}
                type="primary"
                loading={loading}
                icon={loading && <Spin indicator={<LoadingOutlined />} />}
              >
                {loading ? 'Enviando' : 'Ficou boa'}
              </ButtonN>
            </ButtonPhoto>
          ) : (
            <ButtonPicture
              onClick={(e) => {
                setCameraActive(true);
                e.preventDefault();
                capture(() => {});
              }}
            >
              <FontAwesomeIcon icon={faCamera} size="2x" color="#ffff" />
            </ButtonPicture>
          )}
        </CamStyled>
      </Section>
      <TitleArea style={{width: '100%'}}>
        {
          lastAttempt ? (
            <>
              <Title>Você só tem mais <span style={{ color: '#e22012' }}>1 tentativa</span>!</Title>
              <TextStyled>Você só tem mais uma chance de tirar uma foto dentro dos padrões adequados</TextStyled>
            </>
          ) : (
            <><Title>Confira algumas dicas para você</Title>
              <Title> se sair bem na foto</Title>
            </>
          )
        }
      </TitleArea>
      {
        lastAttempt && (
          <>
            <TipsArea style={{width: "100%"}}>
              <RedText>
                <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
                Verifique a distância entre seu rosto e o câmera
              </RedText>
              <RedText>
                <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
                Confira a orientação da foto (paisagem/horizontal)
              </RedText>
              <RedText>
                <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
                Confira se sua foto possui nitidez suficiente
              </RedText>
              <RedText>
                <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
                Certifique de que seu rosto está centralizado no local indicado
              </RedText>
            </TipsArea>
            <Title style={{ marginTop: '5rem' }}>Além disso</Title>
          </>
        )
      }
      <TextStyled>
          <FontAwesomeIcon icon={faFaceLaugh} />
          Lembre-se de encaixar o seu rosto no círculo para melhores resultados.
        </TextStyled>
        <TextStyled>
          <FontAwesomeIcon icon={faSun} />
          Encontre um lugar com boa iluminação.
        </TextStyled>
        <TextStyled>
          <FontAwesomeIcon icon={faPerson} />
          Essa é uma foto formal, então não faça poses ou caretas.
        </TextStyled>
        <TextStyled>
          <FontAwesomeIcon icon={faGlasses} />
          Retire os acessórios que cobrem seu rosto, como óculos e bonés.
        </TextStyled>
        <TextStyled>
          <FontAwesomeIcon icon={faHiking} />
          Siga as instruções apresentadas na tela.
        </TextStyled>
      <InfoStyled>
        Quando essa mensagem aparecer, clique em permitir para que possamos
        acessar a sua câmera.
      </InfoStyled>
      <FalseModel src="https://res.cloudinary.com/djbzvkmqm/image/upload/v1638384172/Platter_3_gqtbtn.png" />
    </>
  );
};

export default Cam;
