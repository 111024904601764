/* eslint-disable import/no-cycle */
import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ButtonN from '../../../../../../components/ButtonN';
import LowerLeftMessage from '../../../../../../components/LowerLeftMessage';
import { useCheckpoint } from '../../../../../../hooks/checkpoint';
import { useSteps } from '../../../../../../hooks/steps';
import { ButtonDisposition, Title } from '../ApprovedAccountPf/styles';
import { Container, TextContainer, Text } from '../AprrovedAccountPj/styles';

const WaitingApprove = () => {
  const { resetStep } = useSteps();
  const { resetCheckpoint } = useCheckpoint({
    userName: '',
  });

  return (
    <>
      <Container>
        <TextContainer>
          <FontAwesomeIcon icon={faCheck} className="fa-lg" />
          <Title>
            <span style={{ color: 'var(--primary)' }}>Parabéns</span>, sua conta foi criada!
          </Title>
          <Text>
            A conta da sua empresa está em processo de aprovação. Você pode continuar usando sua conta
            pessoal normalmente.
          </Text>
        </TextContainer>
        <ButtonDisposition>
          <ButtonN
            onClick={() => {
              resetStep();
              resetCheckpoint();
            }}
            className="botao"
          >
            <Link to="/"> Acessar meu banco</Link>
          </ButtonN>
        </ButtonDisposition>
        <LowerLeftMessage />
      </Container>
    </>
  );
};

export default WaitingApprove;
