/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Select, Spin } from 'antd';
import {
  ButtonContainer,
  Container, Content, HeaderContainer, HelpSection, IconButton, InputContainer, LoginSection, SpanLink,
} from './styles';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import ModalForTransition from '../../components/ModalForTransition';
import i18n from '../../i18n';

import InputN from '../../components/InputN';
import ButtonN from '../../components/ButtonN';
import api from '../../services/api';
import CnpjInfo from '../../components/CnpjInfo';
import { useSettings } from '../../hooks/settings';
import { useRefresh } from '../../hooks/refresh';
import appStore from '../../assets/app-store.png';
import googlePlay from '../../assets/google-play.png';

const { Option } = Select;
function Main() {
  const {
    signInNewOnboarding, setOnboarding,
  } = useAuth();
  const { getEmailSupportMessage, settings } = useSettings();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState(undefined);
  const [message, setMessage] = useState('');
  const [alterPassword, setAlterPassword] = useState(false);
  // Error handlers
  const [validDocument, setValidDocument] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const { setUser, setToken, resetRefresh } = useRefresh();
  useEffect(() => {
    clearInterval();
    sessionStorage.clear();
  }, []);

  const handleSelectedLanguage = useCallback((language) => {
    localStorage.setItem('@qesh:ib_language', `${language}`);
    i18n.changeLanguage(language);
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (!validDocument || !validPass) {
      setError('Credenciais inválidas.');
      setLoading(false);
      return;
    }
    sessionStorage.setItem('@qesh:document', `${document}`);
    try {
      await signInNewOnboarding({
        document,
        password,
      });
      setLoading(false);
    } catch (err) {
      if (err.response?.data?.message === 'The user must update their information') {
        resetRefresh();
        setToken(err?.response?.data?.payload?.token);
        setUser(err?.response?.data?.payload?.user);
        history.push('/refreshData');
        return;
      }
      if (err?.response?.data?.message?.includes('BLACKLIST')) {
        setError(getEmailSupportMessage());
        setLoading(false);
        return;
      }
      if (err.message === 'Pendente de aprovação') {
        setError('Sua conta está em análise. Ao ser aprovada, você poderá acessá-la normalmente.');
        setLoading(false);
        return;
      }
      if (err.response?.data?.message === 'User not found') {
        setError('Usuário não encontrado.');
        setLoading(false);
        return;
      }
      setError(err.response?.data?.message);
      setLoading(false);
    }
  }, [signInNewOnboarding, document, password, validDocument, validPass,
    getEmailSupportMessage, setToken, setUser, history]);

  const handleAdvanceToRegister = () => {
    sessionStorage.setItem('@qesh:step', '0');
    setOnboarding(true);
    history.push('/register/');
  };

  useEffect(() => () => setLoading(false), []);

  const recoveryPassword = async () => {
    setLoading(true);
    setAlterPassword(false);
    if (document) {
      try {
        const response = await api.post('/forgot-password', {
          document,
          client_name: settings.name,
        });
        setLoading(false);
        setShowConfirmationModal(true);
        setMessage(response.data.message);
      } catch (error) {
        setLoading(false);
        setShowConfirmationModal(true);
        setMessage(error.response?.data?.message);
      }
    }
  };

  const componentModalCpf = () => (
    <ModalForTransition
      visibility={alterPassword && error === false}
      title={(
        <h1
          style={{
            height: '30%',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            marginBottom: '1rem',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          Alterar Senha
        </h1>
   )}
      style={{ height: '100%' }}
      setVisibility={() => setAlterPassword()}
      maskClosable={!loading}
    >
      <p style={{
        height: '40%', marginBottom: '1rem', width: '100%', maxWidth: '100%',
      }}
      >
        <span>Preencha o campo do CPF da conta para recuperar a senha de acesso</span>
      </p>

      <ButtonN
        loading={loading}
        style={{ marginTop: '1rem' }}
        size="large"
        type="primary"
        className="botao"
        onClick={() => {
          // recoveryPassword()
          setAlterPassword(false);
        }}
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
      >
        {!loading && 'Confirmar'}
      </ButtonN>
    </ModalForTransition>
  );

  const componentModal = () => (
    <ModalForTransition
      visibility={showConfirmationModal && error === false}
      title={(
        <h1
          style={{
            height: '30%',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            marginBottom: '1rem',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          Alterar Senha
        </h1>
   )}
      style={{ height: '100%' }}
      setVisibility={() => setShowConfirmationModal()}
      maskClosable={!loading}
    >
      <p style={{
        height: '40%', marginBottom: '1rem', width: '100%', maxWidth: '100%',
      }}
      >
        <span>{message}</span>
      </p>
      <ButtonN
        loading={loading}
        size="large"
        type="primary"
        className="botao"
        onClick={() => {
          setShowConfirmationModal(false);
        }}
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
      >
        {!loading && 'Confirmar'}
      </ButtonN>
    </ModalForTransition>
  );

  return settings && settings.name ? (
    <Container settings={settings} showGradient={settings.name !== 'nmpay'}>
      <Notification type="error" visible={error} title={t('erro ao fazer login')} hidden={() => setError(false)}>
        {error}
      </Notification>
      <LoginSection>
        <Content>
          <HeaderContainer>
            <img
              style={{ margin: 'auto', maxWidth: '10.125rem', maxHeight: '5.625rem' }}
              src={settings.default_logo}
              alt={t('logo')}
            />
            <h2>{t('acesse sua conta')}</h2>
          </HeaderContainer>
          <InputContainer>
            <InputN.CpfCnpj
              placeholder={t('Insira números apenas')}
              label="CPF/CNPJ"
              id="cpf"
              value={document}
              onChange={(e) => setDocument(e.target.rawValue)}
              setIsValid={setValidDocument}
              onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
            />
            <InputN.Password
              placeholder={t('Insira sua senha')}
              label="Senha"
              id="senha"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              isValid={validPass}
              setIsValid={setValidPass}
              onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
            />
            <ButtonN
              type="primary"
              loading={loading}
              icon={loading && <Spin indicator={<LoadingOutlined />} />}
              onClick={handleSubmit}
            >
              {!loading && t('Entrar')}
            </ButtonN>

          </InputContainer>
          <HelpSection>
            <SpanLink
              onClick={() => {
                setDocument('');
                setValidDocument('');
                document ? recoveryPassword() : setAlterPassword(true);
              }}
            >
              {t('esqueci minha senha')}
            </SpanLink>
            {['bancovida', 'banksul', 'braip', 'brzbank', 'paygress', 'imobibank', 'faemg', 'wip',
              'conectbank', 'royalpago', 'marinabank', 'propagar', 'somosbank', 't28',
              'vibe', 'wibbank', 'xmax', 'mpmm', 'shiftbank', 'chofer'].includes(settings.name) ? (
                null
              ) : (
                <ButtonN
                  onClick={() => handleAdvanceToRegister()}
                  style={{ zIndex: settings.name === 'diverse' ? -44 : null }}
                >
                  {t('cadastrar')}
                </ButtonN>
              )}
          </HelpSection>
          {settings.name === 'wibbank' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <GlobalOutlined style={{ fontSize: '1.063rem', color: settings.colors.primary }} />
            <Select
              bordered={false}
              placeholder={t('alterar idioma')}
              style={{ fontWeight: 'bold', color: settings.colors.primary }}
              onChange={(value) => handleSelectedLanguage(value)}
            >
              <Option value="ptbr" style={{ width: '100%' }}>
                Português
              </Option>
              <Option value="zh">中文</Option>
            </Select>
          </div>
          )}
          <CnpjInfo />
          {(settings?.play_store_url && settings?.app_store_url) && (
          <ButtonContainer style={{ marginBottom: 54 }}>
            <a
              style={{ width: '50%' }}
              href={settings.app_store_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <IconButton alt="app store" src={appStore} />
            </a>
            <a
              style={{ width: '50%' }}
              href={settings.play_store_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <IconButton alt="google play" src={googlePlay} />
            </a>
          </ButtonContainer>
          )}
        </Content>

      </LoginSection>
      {showConfirmationModal ? componentModal() : alterPassword ? componentModalCpf() : null}
    </Container>
  ) : (
    <></>
  );
}

export default Main;
