import styled from 'styled-components';
import { bg } from '../../../../../../assets/cam.png';

export const Section = styled.section`
 @media screen and (min-width: 48.1rem) {
  display: flex;
  position: absolute;
  left: 0rem;
  top: 0rem;
  max-width: 50%;
  height: 100vh;
  background-color: rgba(15, 15, 15, 0.5);
  overflow-y: scroll;
 }
 @media screen and (min-width: 62rem) {
  max-width: 65%;
 }
`;

export const TextStyled = styled.text`
 align-items: center;
 font-size: 1rem;
 padding-bottom: 1.4rem;
 display: flex;
 gap: 0.5rem;
 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
  'Helvetica Neue', sans-serif;
`;

export const InfoStyled = styled.text`
 align-items: center;
 font-size: 1rem;
 padding-top: 1.4rem;
 display: flex;
 gap: 0.5rem;
 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
  'Helvetica Neue', sans-serif;
 text-align: center;
`;

export const CamStyled = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 width: 90%;
 height: auto;
 align-items: center;
`;

export const Preview = styled.img`
 margin: 0 !important;
 max-height: 100% !important;
 max-width: 100% !important;
 background-repeat: no-repeat;
 object-fit: cover;
 background-image: ${(props) => props.src};
 align-items: center;
`;

export const ButtonPicture = styled.path`
 display: flex;
 align-self: center;
 align-items: center;
 justify-content: center;
 padding: 1rem;
 border-radius: 50%;
 margin: 1rem 0;
 width: 4rem;
 height: 4em;
 background: #42c1c7;
`;

export const ButtonPhoto = styled.div`
 display: flex;
 margin: 2rem;
 gap: 2rem;
`;

export const FalseModel = styled.img`
 margin-top: 25px;
 min-width: 280px;
 min-height: 150px;
 box-shadow: 2px 2px #ccc;
 border: 1px solid #ccc;
`;

export const WhiteText = styled.span`
 color: white;
 align-items: center;
 text-align: center;
 font-size: 1rem;
 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
  'Helvetica Neue', sans-serif;
 font-weight: bold;

 @media screen and (max-width: 60rem) {
  width: 100%;
 }
 @media screen and (max-width: 48.1rem) {
  color: var(--label);
  margin: 1rem 0;
 }
`;
export const Container = styled.section`
 height: fit-content !important;
 width: fit-content !important;
 @-moz-document url-prefix() {
  height: 100% !important;
  width: 100% !important;
 }
 position: relative;
 align-self: center;
`;
export const OverlayContainer = styled.div`
 position: absolute;
 height: 100%;
 width: 100%;

`;

export const Corner1 = styled.div`
position: absolute;
height: 3rem;
width: 3rem;
margin: -0.8rem;
border-bottom: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
border-left: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
bottom: 0;
`;
export const Corner2 = styled.div`
position: absolute;
height: 3rem;
width: 3rem;
margin: -0.8rem;
border-top: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
border-left: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
`;
export const Corner3 = styled.div`
position: absolute;
height: 3rem;
width: 3rem;
margin: -0.8rem;
border-bottom: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
border-right: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
bottom: 0;
right: 0;
`;
export const Corner4 = styled.div`
position: absolute;
height: 3rem;
width: 3rem;
margin: -0.8rem;
border-top: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
border-right: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
right: 0;
`;

export const Box = styled.div`
 padding: 0.3rem;
 align-items: center;
 position: relative;
 width: fit-content;
 height: 100%;
`;

export const WhiteTitle = styled.span`
 color: white;
 align-items: center;
 font-size: 1.5rem;
 font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
 text-align: center;
 font-weight: bold;
 @media screen and (max-width: 48.1rem) {
  color: var(--label);
 }
`;
