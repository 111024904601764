/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import ButtonN from '../../../../../components/ButtonN';
import InputN from '../../../../../components/InputN';
import { useSteps } from '../../../../../hooks/steps';
import { Title } from './styles';
import messages from '../../../../../components/MessageError';
import ModalForTransition from '../../../../../components/ModalForTransition';
import Alert from '../../../../../components/SpanError/index';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { addPartner } from '../../../../../services/endPointRegister/PJ/updatePartners';
import { handleGetPartners } from '../../../../../services/endPointRegister/PJ/getPartners';

const RegisterPartner = () => {
  const {
    exactStep, stepsPj, step, resetStep, stepsPjEnum,
  } = useSteps();
  const { checkpoint } = useCheckpoint();
  const history = useHistory();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [partnerData, setPartnerData] = useState({
    document: '',
    email: '',
    name: '',
    qualification: '',
  });
  const [emailValido, setEmailValido] = useState(false);
  const [cpfCnpjValid, setCpfCnpjValid] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState(false);
  const [partnerList, setPartnerList] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
//     const isTheRightPage = localStorage.getItem('@qesh:token')
// && stepsPjEnum[localStorage.getItem('@qesh:step')] === 5
// && checkpoint.status === 'RegisterPartners';
    if (checkpoint?.list !== undefined ) {
      exactStep(5);
    // } else {
    //   resetStep();
    //   history.replace('/transaction');
    // }
    }
  }, []);

  const verifyEmailAlreadyRegister = () => partnerList.some((partner) => partner.email === partnerData.email);
  const verifyCpfAlreadyRegister = () => partnerList.some((partner) => partner.document === partnerData.document);

  const isCpfCnpjValid = () => {
    if (partnerData.document.length < 12) {
      return cpf.isValid(partnerData.document);
    } if (partnerData.document.length >= 12) {
      return cnpj.isValid(partnerData.document);
    }
  };

  const verifyPartnerNotExists = (partnerList, currentPartner) => !partnerList.find(
    (partner) => partner.document === currentPartner.document || partner.email === currentPartner.email,
  );

  const handleSubmit = async () => {
    const partnerNotExists = verifyPartnerNotExists(partnerList, partnerData);

    if (partnerNotExists) {
      const { id: userPf } = JSON.parse(localStorage.getItem('@qesh:account'));
      const accountPj = localStorage.getItem('@qesh:accountPj');
      const token = localStorage.getItem('@qesh:token');
      setLoadingButton(true);
      await addPartner(partnerData, token, accountPj, userPf);
      setShowConfirmationModal(true);
    }
    if (!partnerNotExists) {
      setLoadingButton(true);
      setShowConfirmationModal(true);
      setError(true);
    }
  };

  const minimumInputCaracterChecker = (string) => {
    const stringArray = string.split(' ');
    if (stringArray[0]?.length >= 2 && stringArray[1]?.length >= 2) {
      return false;
    }
    return true;
  };

  const handleDisabled = () => {
    if (
      partnerData.document.length < 11
|| partnerData.document.length > 14
|| !partnerData.email
|| !partnerData.name
|| !cpfCnpjValid
|| minimumInputCaracterChecker(partnerData.name)
|| !partnerData.qualification
|| !emailValido
    ) {
      return true;
    }
  };

  // const handleAddPartner = () => {
  // if (!handleDisabled()) {
  // // setShowConfirmationModal(true)
  // }
  // }

  const modalConfirm = () => {
    setShowConfirmationModal(false);
    history.push(`/register/${stepsPj[step]}`);
  };

  const emailCheck = /^[a-z0-9.+_]{1,61}@[a-z0-9]{4,}.[a-z]{2,}(.[a-z]{2,})?$/m;

  useEffect(() => {
    const getPartnerList = async () => {
      const { id: userPf } = JSON.parse(localStorage.getItem('@qesh:account'));
      const accountPj = localStorage.getItem('@qesh:accountPj');
      const token = localStorage.getItem('@qesh:token');
      const { res } = await handleGetPartners(userPf, accountPj, token);
      setPartnerList(res.partners || []);
    };
    getPartnerList();
  }, []);

  useEffect(() => {
    if (emailCheck.test(partnerData.email)) {
      setEmailValido(!verifyEmailAlreadyRegister());
    } else {
      setEmailValido(false);
    }
    if (isCpfCnpjValid()) {
      setCpfCnpjValid(!verifyCpfAlreadyRegister());
    } else {
      setCpfCnpjValid(false);
    }
  }, [partnerData.email, partnerData.document]);
  return (
    <>
      <Title>{t('Dados de cadastro do sócio')}</Title>
      <InputN.CpfCnpj
        id="cpf-cnpj"
        label={t('CPF')}
        value={partnerData.document}
        onChange={(e) => setPartnerData({ ...partnerData, document: e.target.rawValue })}
        placeholder={t('Insira apenas números')}
        className={`ant-input ${
          isCpfCnpjValid() && cpfCnpjValid
            ? 'is-valid'
            : isCpfCnpjValid() && !cpfCnpjValid && partnerData.document.length === 11
              ? 'is-invalid'
              : ''
        }`}
        nullClass
      />
      {isCpfCnpjValid() ? (
        cpfCnpjValid ? null : (
          <Alert message="CPF já cadastrado para outro sócio" type="error" />
        )
      ) : null}
      <InputN
        label="Email"
        id="email"
        placeholder="Insira seu e-mail pessoal"
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        className={`ant-input ${
          emailCheck.test(partnerData.email) && emailValido
            ? 'is-valid'
            : emailCheck.test(partnerData.email) && !emailValido
              ? 'is-invalid'
              : ''
        }`}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        value={partnerData.email}
        onChange={(e) => {
          const string = e.target.value;
          const stringFilter = string.replace(' ', '').trim();
          setPartnerData({ ...partnerData, email: stringFilter });
        }}
      />

      {emailCheck.test(partnerData.email) ? (
        !emailValido ? (
          <Alert message="E-mail já cadastrado para outro sócio." type="error" />
        ) : null
      ) : null}

      <InputN
        id="nome"
        label={t('Nome Completo')}
        placeholder={t('Insira seu nome completo')}
        onChange={(e) => {
          let string = e.target.value;
          if (string.substring(0, 1) === ' ') {
            string = string.slice(1);
          }
          const inputText = string.replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/g, '');
          const arrayOfWords = inputText.split(' ');
          for (let i = 0; i < arrayOfWords.length; i += 1) {
            arrayOfWords[i] = arrayOfWords[i].charAt(0).toUpperCase() + arrayOfWords[i].slice(1);
          }
          const finalString = arrayOfWords.join(' ');
          setPartnerData({ ...partnerData, name: finalString });
        }}
        value={partnerData.name}
      />
      {partnerData.name !== '' && minimumInputCaracterChecker(partnerData.name) && (
      <Alert message={t(messages.incompleteName)} type="error" />
      )}
      <InputN
        maxLength={100}
        id="qualificação"
        label="Qualificação do Sócio"
        placeholder="Informe sua qualificação"
        value={partnerData.qualification}
        onChange={(e) => setPartnerData({ ...partnerData, qualification: e.target.value })}
      />
      <ButtonN onClick={() => handleSubmit()} type="primary" className="botao" disabled={handleDisabled()}>
        {!loadingButton ? t('Enviar link') : <LoadingOutlined style={{ fontSize: 15, color: 'white' }} spin />}
      </ButtonN>
      <ModalForTransition
        visibility={showConfirmationModal}
        setVisibility={setShowConfirmationModal}
        title={(
          <h1
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '1rem',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            {!error ? 'Link enviado!' : 'Atenção!'}
          </h1>
   )}
        style={{ height: '100%' }}
      >
        <p
          style={{
            height: '30%',
            width: '100%',
            marginBottom: '1rem',
          }}
        >
          {!error
            ? `O link de confirmação foi enviado com sucesso para o e-mail ${partnerData.email}.`
            : 'Não é possivel cadastrar dois sócios com as mesmas informações'}
        </p>
        <p>
          {!error
            ? 'Quando o processo for concluído você será notificado'
            : 'Gentileza recadastre o sócio corretamente'}
        </p>

        <ButtonN
          size="large"
          type="primary"
          className="botao"
          onClick={() => {
            modalConfirm();
          }}
        >
          Entendido
        </ButtonN>
      </ModalForTransition>
    </>
  );
};

export default RegisterPartner;
