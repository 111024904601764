import styled from 'styled-components';

export const Content = styled.div`
  max-width: 100%;
  width: 100%;

  h2 {
    color: #121212;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h3 {
    color: #121212;
    font-size: 17px;
    margin-top: 35px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  p {
    margin-bottom: 30px;
  }
`;

export const FormContainer = styled.div`
  width: 80%;
  margin-left: 10%;
  margin-top: 30px;
`;
