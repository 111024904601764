/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'antd';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { useHistory, useParams } from 'react-router-dom';
import InputN from '../../../../../components/InputN';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import ModalForTransition from '../../../../../components/ModalForTransition';

import {
  ModalTitle, Subtitle, Text, Title, TermText, MainContainer, ButtonContainer,
} from './styles';
import ButtonN from '../../../../../components/ButtonN';
import Span from '../../../../../components/SpanError';
import messsages from '../../../../../components/MessageError';

import { useSteps } from '../../../../../hooks/steps';
import { useSettings } from '../../../../../hooks/settings';
import api from '../../../../../services/api';

const Onboarding = () => {
  const history = useHistory();
  const params = useParams();
  const { checkpoint, setCheckpoint, resetCheckpoint } = useCheckpoint();
  const { settings, getEmailSupportMessage } = useSettings();
  // User Settings
  const {
    resetStep, nextStep, prevStep, stepsPf, stepsPj, step, exactStep, token,
  } = useSteps();
  const [userRegistred, setUserRegistred] = useState(false);
  const [blacklistTest, setBlackListTest] = useState(false);
  const [minor, setMinor] = useState(false);
  const [typeError, setTypeError] = useState('');
  const [cpfTest, setCpfTest] = useState('');
  const [serviceTerms, setServicesTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cpfCnpjInput, setCpfCnpjInput] = useState('');
  const isPj = params.step === 'basicDataPj';
  // Tradução
  const { t } = useTranslation();
  useEffect(() => {
    exactStep(0);
    if (!checkpoint.cpfCnpj) {
      setCpfCnpjInput('');
      return;
    }
    if (isPj && checkpoint.cpfCnpj.length < 14) {
      setCpfCnpjInput('');
      return;
    }
    setCpfCnpjInput(checkpoint.cpfCnpj);
    // resetStep()
  }, []);

  // @TO-DO: Essa função precisará ser refatorada para evitar a reptição continua do código em todas as páginas.
  const handleAdvance = async () => {
    if (blacklistTest) {
      setLoading(false);
      setUserRegistred(false);
      setBlackListTest(false);
      setMinor(false);
      resetStep();
      resetCheckpoint();
      history.replace('/');
      setLoading(false);
      return;
    }
    if (userRegistred) {
      setLoading(false);
      setUserRegistred(false);
      setBlackListTest(false);
      setMinor(false);
      resetStep();
      resetCheckpoint();
      history.push('/register/');
      setLoading(false);
      return;
    }
    if (minor) {
      setLoading(false);
      setUserRegistred(false);
      setBlackListTest(false);
      setMinor(false);
      resetStep();
      resetCheckpoint();
      history.push('/register/');
      setCheckpoint({ ...checkpoint, cpfCnpj: '' });
      setLoading(false);
      return;
    }
    if (params.step === 'basicDataPj') {
      setLoading(false);
      nextStep();
      if (stepsPj.length >= step + 1) {
        return history.push(`/register/${stepsPj[step + 1]}`);
      }
      return history.push(`/register/${stepsPj[step]}`);
    }
    nextStep();
    setLoading(false);
    if (stepsPf.length >= step + 1) {
      return history.push(`/register/${stepsPf[step + 1]}`);
    }
    return history.push(`/register/${stepsPf[step]}`);
  };

  useEffect(() => {
    if (checkpoint.cpfCnpj?.length === 11 || checkpoint.cpfCnpj?.length === 14) {
      setCpfTest(cpfCnpjInput);
    }
  }, [cpfCnpjInput]);

  const requestValidateDocument = () => api
    .get(`onboarding/validate?document=${cpfTest}&client_name=${settings.name}&accept_term=${true}`)
    .then((response) => {
      setLoading(false);
      setUserRegistred(false);
      setBlackListTest(false);
      setMinor(false);
      if (response.data.blacklisted === true) {
        setBlackListTest(true);
        setTypeError('blacklist');
      }
      handleAdvance();
    })
    .catch((error) => {
      setLoading(false);
      if (error?.response?.data?.message?.includes('BLACKLIST')) {
        setUserRegistred(false);
        setBlackListTest(true);
        setMinor(false);
        setTypeError('blacklist');
        return;
      }
      console.error(4, error?.message);
      if (error?.message === 'Request failed with status code 404') {
        setUserRegistred(false);
        setBlackListTest(true);
        setMinor(false);
        setTypeError('blacklist');
      }
      if (error?.message === 'Request failed with status code 409') {
        setUserRegistred(true);
        setBlackListTest(false);
        setMinor(false);
        setTypeError('registred');
      }
      if (error?.response?.data?.message === 'Age is not enough to create an account') {
        setUserRegistred(false);
        setBlackListTest(false);
        setMinor(true);
        setTypeError('minor');
      }
    });

  const reqValid = () => {
    setLoading(true);
    if (params.step === 'basicDataPj') {
      sessionStorage.setItem('@qesh:steplist', 'PJ');
      const objeto = JSON.parse(localStorage.getItem('@qesh:account'));
      const account = objeto.id;
      const cnpj = cpfTest;
      const tokenRequest = !token ? localStorage.getItem('@qesh:token') : token;
      const headers = {
        Authorization: `bearer ${tokenRequest}`,
        account,
      };
      return api
        .get(`/api-id/neoway/companie/basic/${cnpj}`, { headers })
        .then((response) => {
          const cnpjInfo = response.data;
          setLoading(false);
          setCheckpoint({ ...checkpoint, cnpjInfo });
          requestValidateDocument();
          return { status: true, res: cnpjInfo };
        })
        .catch((error) => {
          setLoading(false);
          console.error(error.message || error);
          return { status: false, res: error?.message };
        });
    }

    return api
      .get(`onboarding/validate?document=${cpfTest}&client_name=${settings.name}&accept_term=${serviceTerms}`)
      .then((response) => {
        setUserRegistred(false);
        setBlackListTest(false);
        setMinor(false);
        if (response.data.blacklisted === true) {
          setBlackListTest(true);
          setTypeError('blacklist');
        }
        handleAdvance();
      })
      .catch((error) => {
        setLoading(false);
        console.error(4, error.message);
        if (error.response.data.message.includes('BLACKLIST')) {
          setUserRegistred(false);
          setBlackListTest(true);
          setMinor(false);
          setTypeError('blacklist');
          return;
        }
        if (error.message === 'Request failed with status code 404') {
          setUserRegistred(false);
          setBlackListTest(true);
          setMinor(false);
          setTypeError('blacklist');
          return;
        }
        if (error.message === 'Request failed with status code 409') {
          setUserRegistred(true);
          setBlackListTest(false);
          setMinor(false);
          setTypeError('registred');
          return;
        }
        if (error.response.data.message === 'Age is not enough to create an account') {
          setUserRegistred(false);
          setBlackListTest(false);
          setMinor(true);
          setTypeError('minor');
        }
      });
  };

  const optionsModal = () => {
    switch (typeError) {
      case 'blacklist':
        return (
          <>
            <Subtitle>Infelizmente não vamos dar continuidade ao seu cadastro no momento.</Subtitle>
            <Text>{getEmailSupportMessage()}</Text>
            <ButtonN
              type="primary"
              className="botao"
              onClick={() => {
                handleAdvance();
              }}
            >
              Confirmar
            </ButtonN>
          </>
        );
      case 'registred':
        return (
          <>
            <Subtitle>{`O ${isPj ? 'CNPJ' : 'CPF'} informado já está cadastrado no nosso banco.`}</Subtitle>

            <ButtonN
              style={{ marginBottom: '1rem' }}
              type="primary"
              className="botao"
              onClick={() => {
                handleAdvance();
              }}
            >
              Entendido
            </ButtonN>
            {/* <ButtonN
       className='botao'
       onClick={() => {
        handleForgotPassword()
       }}
      >
       Esqueci minha senha
      </ButtonN> */}
          </>
        );

      case 'minor':
        return (
          <>
            <Text>
              Infelizmente não vamos dar continuidade ao seu cadastro no momento. {getEmailSupportMessage()}
            </Text>
            <ButtonN
              type="primary"
              className="botao"
              onClick={() => {
                handleAdvance();
              }}
            >
              Entendido
            </ButtonN>
          </>
        );
    }
  };

  return (
    <MainContainer>
      <Title>Dados de cadastro{params.step === 'basicDataPj' && ' da empresa'}</Title>

      {params.step === 'basicDataPj' ? (
        <>
          <InputN.Cnpj
            id="cnpj"
            label="CNPJ"
            onChange={(e) => {
              setUserRegistred(false);
              setCpfCnpjInput(e.target.rawValue);
              setCheckpoint({ ...checkpoint, cpfCnpj: e.target.rawValue });
            }}
            placeholder={t('Insira apenas números')}
            value={cpfCnpjInput}
          />
        </>
      ) : (
        <>
          <InputN.Cpf
            id="cpf"
            label="CPF"
            value={cpfCnpjInput}
            autoFocus
            onChange={(e) => {
              setCpfCnpjInput(e.target.rawValue);
              setCheckpoint({ ...checkpoint, cpfCnpj: e.target.rawValue });
            }}
            placeholder={t('Insira apenas números')}
          />
          <div style={{ display: 'flex', flexFlow: 'row nowrap', gap: '10px' }}>
            <Checkbox
              onClick={() => {
                setServicesTerms(!serviceTerms);
              }}
              style={{ height: '100%' }}
            />
            <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
              <TermText>
                {'Ao criar sua conta, você concorda com nossos '}
                <a href={`${settings.ib_url}/terms`} target="_blank" rel="noopener noreferrer">
                  Termos de Serviços
                </a>
                {' e '}
                <a href={`${settings.ib_url}/privacy`} target="_blank" rel="noopener noreferrer">
                  Política de Privacidade
                </a>
                .
              </TermText>
            </div>
          </div>
        </>
      )}
      {userRegistred === true ? <Span message={isPj ? messsages.cnpjRegistred : messsages.cpfRegistred} /> : null}
      <ButtonContainer>
        <ButtonN
          loading={loading}
          type="primary"
          className="botao"
          onClick={() => {
            setCheckpoint({ ...checkpoint, cpfCnpj: cpfCnpjInput });
            reqValid();
          }}
          disabled={
      !(cpf.isValid(cpfCnpjInput) || cnpj.isValid(cpfCnpjInput))
      || (params.step === 'basicDataPj' ? false : !serviceTerms)
      || (params.step === 'basicDataPj' ? cpfCnpjInput < 1 : false)
      || blacklistTest
      || userRegistred
      || minor
     }
        >
          {!loading && 'Continuar'}
        </ButtonN>
        <ButtonN
          onClick={() => {
            prevStep();
            history.replace('/');
          }}
        >
          Voltar
        </ButtonN>
      </ButtonContainer>
      <ModalForTransition
        visibility={blacklistTest || (userRegistred && !isPj) || minor}
        setVisibility={setBlackListTest}
        title={
     blacklistTest ? (
       <ModalTitle>Sentimos muito!</ModalTitle>
     ) : minor ? (
       <ModalTitle>Sentimos muito!</ModalTitle>
     ) : (
       <ModalTitle>Ops!</ModalTitle>
     )
    }
        style={{ height: '100%' }}
      >
        {optionsModal()}
      </ModalForTransition>
    </MainContainer>
  );
};

export default Onboarding;
