import api from '../../api';

/**
 * request to recover all the registered partners
 * @property {string} token - token bearer login account user PF
 * @property {string} account - id to account the user PF
 * @returns {{response || error}}
 */
export const handleGetStepPj = async (token, account) => {
  const headers = {
    account,
  };

  return api
    .get('/onboarding/companies/incompleted', { headers })
    .then((response) => {
      console.log('get status: ', response.data);
      localStorage.setItem('@qesh:namepj', response.data.companie.name);
      return { status: true, res: response.data };
    })
    .catch((error) => {
      console.log('handleGetStepPj error: ', error.response);
      return { status: false, res: error?.response.data };
    });
};


export const handleGetStepPjV2 = async (_token, account) => {
  const headers = {
    account,
  };

  return api.get('/onboarding/companies/incompleted', { headers });
};
