import { useTranslation } from 'react-i18next';
import { React } from 'react';
import {
  Container, InputContainer, Subtitle, Text, TextContainer, Title,
} from '../styles';
import InputN from '../../../components/InputN';
import ButtonN from '../../../components/ButtonN';
import { useRefresh } from '../../../hooks/refresh';
import { listOfNationality } from '../../../utilitys/listOfNationality';

export function BasicData() {
  const { t } = useTranslation();
  const { basicData, setBasicData, setStep } = useRefresh();

  const handleNext = async () => {
    setStep('contact');
  };

  const handleChange = (key, value) => {
    setBasicData({ ...basicData, [key]: value });
  };

  const { name, nationality, motherName } = basicData;

  const buttonAble = () => Object.keys(basicData).some((key) => (
    ['email', 'phone'].includes(key)
      ? false
      : basicData[key] === '' || !basicData[key]));

  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>Atualização de contato</Title>
          <Subtitle>Alguma dessas informações mudou?</Subtitle>
          <Text>Você pode editar as informações que foram alteradas.</Text>
        </TextContainer>
        <InputN
          placeholder="Insira seu nome completo"
          label={t('Nome completo')}
          id="fullName"
          value={name}
          onChange={({ target: { value } }) => handleChange('name', value)}
        />
        <InputN
          placeholder="Insira o nome completo da sua mãe"
          label={t('Nome completo da mãe')}
          id="motherFullName"
          value={motherName}
          onChange={({ target: { value } }) => handleChange('motherName', value)}
        />
        <InputN.SelectandSearchModal
          id="nacionalidade"
          label={t('Nacionalidade')}
          value={nationality}
          onChange={(value) => handleChange('nationality', value[0].name)}
          list={listOfNationality}
          needSearchInput
          placeholder="Selecione sua nacionalidade"
          required
          title={<Title>Escolha sua nacionalidade</Title>}
        />
      </InputContainer>
      <ButtonN disabled={buttonAble()} type="primary" onClick={handleNext}>
        Continuar
      </ButtonN>
    </Container>
  );
}
