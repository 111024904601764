import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonN from '../../../../../components/ButtonN';
import InputN from '../../../../../components/InputN';
// eslint-disable-next-line import/no-cycle
import { useSteps } from '../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { listOfNationality } from '../../../../../utilitys/listOfNationality';
import { ageCalc, CurrentDateAge, yearBirtday } from '../../../../../components/CurrentDate';
import messages from '../../../../../components/MessageError';
import Alert from '../../../../../components/SpanError/index';
import { useSettings } from '../../../../../hooks/settings';
import { Checkbox } from 'antd';

const BasicData = () => {
  const history = useHistory();
  const { checkpoint, setCheckpoint, resetCheckpoint } = useCheckpoint();
  const [error, setError] = useState(false);
  const {
    nextStep, prevStep, stepsPf, step, exactStep, resetStep,
  } = useSteps();
  const {settings} = useSettings()
  const { t } = useTranslation();
  const [cpfData, setCpfData] = useState({
    birthday: '',
    name: '',
    motherName: '',
    nationality: listOfNationality[0],
    indication: ''
  });
  useEffect(() => {
    if (checkpoint.cpfCnpj) {
      exactStep(1);
    } else {
      resetCheckpoint();
      resetStep();
      history.replace('/register/');
    }
  }, []);
  const handleAdvance = () => {
    localStorage.clear();
    setCheckpoint({ ...checkpoint, cpfData });
    if (checkpoint.cpfCnpj.length === 11) {
      nextStep();
      if (stepsPf.length >= step + 1) {
        history.push(`/register/${stepsPf[step + 1]}`);
      } else {
        history.push(`/register/${stepsPf[step]}`);
      }
    }
  };

  useEffect(() => {
    if (checkpoint.cpfCnpj) {
      // eslint-disable-next-line no-unused-expressions
      checkpoint.cpfData && setCpfData({ ...cpfData, ...checkpoint.cpfData });
      exactStep(1);
    } else {
      resetCheckpoint();
      resetStep();
      history.push('/register');
    }
  }, []);

  const minimumInputCaracterChecker = (string) => {
    const stringArray = string.split(' ');
    if (stringArray[0]?.length >= 2 && stringArray[1]?.length >= 2) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const currentDate = new Date().getFullYear();
    const year = +yearBirtday(cpfData.birthday);
    if (cpfData.birthday === '') {
      setError(true);
    }
    if (year > currentDate || year < 1900) {
      setError(true);
    } else setError(false);
  }, [cpfData.birthday]);

  const buttonAble = () => (
    !cpfData.birthday
   || cpfData.birthday === ''
   || minimumInputCaracterChecker(cpfData.name)
   || minimumInputCaracterChecker(cpfData.motherName)
   || !cpfData.nationality
   || error
   || ageCalc(cpfData.birthday) < 18
   || (settings.indication_analytics && cpfData.indication.length < 1)
  );

  return (
    <>
      <InputN.CpfCnpj id="cpf-cnpj" label="CPF" value={checkpoint.cpfCnpj} disabled />
      <InputN
        id="nome"
        label={t('Nome Completo')}
        placeholder={t('Insira seu nome completo')}
        onChange={(e) => {
          let string = e.target.value;
          if (string.substring(0, 1) === ' ') {
            string = string.slice(1);
          }
          const inputText = string
            .replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/g, '')
            .replace('  ', ' ');
          const arrayOfWords = inputText.split(' ');
          for (let i = 0; i < arrayOfWords.length; i += 1) {
            arrayOfWords[i] = arrayOfWords[i].charAt(0).toUpperCase() + arrayOfWords[i].slice(1);
          }
          const finalString = arrayOfWords.join(' ');
          setCpfData({ ...cpfData, name: finalString });
        }}
        value={cpfData.name}
      />
      {cpfData.name !== '' && minimumInputCaracterChecker(cpfData.name) && (
      <Alert message={t(messages.incompleteName)} type="error" />
      )}

      <InputN.Date
        id="birthday"
        label={t('Data de nascimento')}
        value={cpfData.birthday}
        placeholder="01/01/0001"
        style={
     !cpfData.birthday || cpfData.birthday === '0001-01-01' ? { color: '#D6C5BF' } : { color: 'black' }
    }
        onChange={(e) => {
          let { value } = e.target;
          if (value.length > 10) {
            value = value.slice(0, value.length - 1);
          }
          setCpfData({ ...cpfData, birthday: e.target.value });
        }}
        min="1900-10-01"
        max={CurrentDateAge}
      />
      <InputN
        id="nome-mae"
        label={t('Nome Completo da Mãe')}
        placeholder={t('Insira o nome completo da sua mãe')}
        onChange={(e) => {
          let string = e.target.value;
          if (string.substring(0, 1) === ' ') {
            string = string.slice(1);
          }
          const inputText = string
            .replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/g, '')
            .replace('  ', ' ');
          const arrayOfWords = inputText.split(' ');
          for (let i = 0; i < arrayOfWords.length; i += 1) {
            arrayOfWords[i] = arrayOfWords[i].charAt(0).toUpperCase() + arrayOfWords[i].slice(1);
          }
          const finalString = arrayOfWords.join(' ');
          setCpfData({ ...cpfData, motherName: finalString });
        }}
        value={cpfData.motherName}
      />
      {cpfData.motherName !== '' && minimumInputCaracterChecker(cpfData.motherName) && (
      <Alert message={t(messages.incompleteName)} type="error" />
      )}
      <InputN.SelectandSearchModal
        id="nacionalidade"
        label={t('Nacionalidade')}
        placeholder={t('Insira sua nacionalidade')}
        value={cpfData.nationality ? `${cpfData.nationality.name}` : ''}
        onChange={(value) => {
          setCpfData({ ...cpfData, nationality: value[0] });
          sessionStorage.setItem('@qesh:nationality', value[0].id);
        }}
        list={listOfNationality}
        needSearchInput
        required
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            Escolha sua Nacionalidade
          </p>
        )}
      />
      {settings.indication_analytics && (
          <InputN
          id='indication'
          label={t('Indicação')}
          placeholder='Insira a sua indicação'
          value={cpfData.indication}
          onChange={(e) => {
            setCpfData({ ...cpfData, indication: e.target.value });
          }}
          maxLength='100'
          />
      )}
      <ButtonN type="primary" className="botao" onClick={() => handleAdvance()} disabled={buttonAble()}>
        Continuar
      </ButtonN>
      <ButtonN
        onClick={() => {
          prevStep();
          history.replace(`/register/${stepsPf[exactStep(step - 1)]}`);
        }}
      >
        Voltar
      </ButtonN>
    </>
  );
};

export default BasicData;
