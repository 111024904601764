import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import * as styles from './styles';
import { useSettings } from '../../hooks/settings';

function InactiveMessage() {
  const { settings } = useSettings();
  const clientUrl = settings.ib_url;
  return (
    <styles.Container>
      <ExclamationCircleFilled style={{
        display: 'flex',
        color: '#ffffff',
        fontSize: '2rem',
        marginRight: 'auto',
      }}
      />
      <styles.Message>
        {`Este endereço não estará mais disponível a partir do dia 01/07,
         porém, iremos continuar em um novo endereço: `}
        <styles.Link href={clientUrl}>
          {clientUrl}
        </styles.Link>
      </styles.Message>
    </styles.Container>
  );
}

export default InactiveMessage;
