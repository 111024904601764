/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Item } from './styles';
import { useSettings } from '../../hooks/settings';

const Stepper = (props) => {
  const { numPag } = props;
  const { settings } = useSettings();

  return settings && settings.name ? (
    <>
      <Container>
        <Item color={settings.colors.primary} ativo={numPag === 1}>{numPag === 1 ? numPag : ''}</Item>
        <Item color={settings.colors.primary} ativo={numPag === 2}>{numPag === 2 ? numPag : ''}</Item>
        <Item color={settings.colors.primary} ativo={numPag === 3}>{numPag === 3 ? numPag : ''}</Item>
      </Container>
    </>
  ) : (<></>);
};

export default Stepper;
