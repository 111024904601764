import React, { useEffect } from 'react';
import { Input as InputANTD } from 'antd';
import { AntProps, DefaultAntProps } from './Types';

import { Container, StyledInput } from './styles';

const Text = (props) => {
  const {
    value, setIsValid, className, ...rest
  } = props;
  const regex = /[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/g;
  useEffect(() => {
    if (typeof setIsValid === 'function') {
      if (value.test(regex) && value.length > 10) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [value, setIsValid]);

  return (
    <Container>
      {!!props.label && <label htmlFor={props.id}>{props.label}</label>}
      <StyledInput className={`ant-input ${className} ${value.length > 7 ? 'is-valid' : 'is-invalid'}`}>
        <InputANTD {...rest} type="text" value={value.replace(regex, '')} />
        <i />
      </StyledInput>
    </Container>
  );
};

Text.propTypes = {
  ...AntProps,
  setIsValid: (props) => (props.setIsValid === undefined && new Error('setIsValid necessário para validação!'))
|| (typeof props.setIsValid !== 'function' && new Error('setIsValid deve ser uma função!')),
};

Text.defaultProps = {
  ...DefaultAntProps,
  setIsValid: undefined,
};

export default Text;
