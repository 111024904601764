import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
height: 3.5rem;
background-color: #FF4524;
display: flex;
flex-flow: row wrap;
align-items: center;
justify-content: center;
padding: 0 1rem;
`;

export const Message = styled.p`
margin: 0;
padding: 0;
margin-right: auto;
`;

export const Link = styled.a`
color: #521AA3;
text-decoration: none;
font-weight: bold;
:hover {
  color: #521AA3;
}
`;
