import React, { useState } from 'react';
import { Modal, message } from 'antd';
import {
  ButtonContainer,
  Container,
  InputContainer,
  ModalStyle,
  Subtitle,
  Text,
  TextBold,
  TextContainer,
  Title,
} from '../../styles';
import InputN from '../../../../components/InputN';
import ButtonN from '../../../../components/ButtonN';
import { useRefresh } from '../../../../hooks/refresh';
import { useSettings } from '../../../../hooks/settings';

export function Email() {
  const {
    setStep, refreshApi, user, basicData, setBasicData,
  } = useRefresh();
  const [showModal, setShowModal] = useState(false);
  const { settings } = useSettings();
  const [newEmail, setNewEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const handleNext = async () => {
    try {
      const body = {
        client_name: settings.name,
        document: user.document,
        contact: newEmail,
      };
      const response = await refreshApi.sendCode(body);
      setBasicData({ ...basicData, email: newEmail });
      message.success(response.message);
      setStep('codeEmail');
    } catch (error) {
      setShowModal(false);
      if (error.message.includes('already registered')) {
        message.error('Email já cadastrado, favor insira outro e-mail');
        return;
      }
      if (error.message.includes('many trys')) {
        message.error('Número máximo de tentativas excedidas, tente novamente amanhã');
        return;
      }
      message.error(error.message);
    }
  };
  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>Só mais alguns passos...</Title>
          <Subtitle>Qual o seu e-mail?</Subtitle>
          <Text>Agora vamos garantir que somente você terá acesso à sua conta.</Text>
        </TextContainer>
        <InputN.Mask id="actual-email" label="E-mail atual" placeholder={basicData.email} disabled />
        <InputN.Email
          id="conf-email"
          label="Novo e-mail"
          placeholder="Insira seu e-mail pessoal"
          onChange={(e) => {
            setNewEmail(e.target.value);
          }}
          value={newEmail}
          setIsValid={setEmailValid}
        />
      </InputContainer>
      <ButtonN disabled={!emailValid} type="primary" onClick={() => setShowModal(true)}>
        Receber código via e-mail
      </ButtonN>
      <Modal
        visible={showModal}
        footer={null}
        closable={false}
        destroyOnClose
        focusTriggerAfterClose={false}
        onCancel={() => {
          setShowModal(false);
        }}
        bodyStyle={{ padding: '2.5rem' }}
      >
        <ModalStyle>
          <TextBold style={{ textAlign: 'center' }}>Seu e-mail</TextBold>
          <Text style={{ textAlign: 'center' }}>Confirme seu e-mail de contato</Text>
          <ButtonContainer style={{ flexFlow: 'row nowrap' }}>
            <ButtonN
              type="default"
              className="botao"
              onClick={() => {
                setShowModal(false);
              }}
              style={{ marginTop: '1rem' }}
            >
              Alterar
            </ButtonN>
            <ButtonN type="primary" className="botao" onClick={handleNext} style={{ marginTop: '1rem' }}>
              Confirmar
            </ButtonN>
          </ButtonContainer>
        </ModalStyle>
      </Modal>
    </Container>
  );
}
