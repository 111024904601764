/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { useSteps } from '../../../../../hooks/steps';
import { handleGetStepPj } from '../../../../../services/endPointRegister/PJ/statusPj';

import ApprovedAccountPf from './ApprovedAccountPf';
import AprrovedAccountPj from './AprrovedAccountPj';
import FailureInProofOfLife from './FailureInProofOfLife';
import WaitingApprove from './WaitingApprove';

const AccountStatus = () => {
  const { prevStep } = useSteps();
  const history = useHistory();
  const [statusPj, setStatusPj] = useState(null);

  useEffect(() => {
    const getStatus = async () => {
      const token = localStorage.getItem('@qesh:token');
      const accountObject = JSON.parse(localStorage.getItem('@qesh:account'));
      try {
        const {
          res: {
            companie: { status },
          },
        } = await handleGetStepPj(token, accountObject);
        setStatusPj(status);
      } catch {
        setStatusPj(localStorage.getItem('@qesh:statuspagecode'));
      }
    };
    if (sessionStorage.getItem('@qesh:step') === 13) {
      prevStep(1);
      history.go(-1);
    }
    getStatus();
  });

  const renderStatusPage = (statusCode) => {
    switch (statusCode) {
      case 'ApprovedAccountPf': {
        return <ApprovedAccountPf />;
      }
      case 'WaitingApprove': {
        return <WaitingApprove />;
      }
      case 'AprrovedAccountPj': {
        return <AprrovedAccountPj />;
      }
      case 'FailureInProofOfLife': {
        return <FailureInProofOfLife />;
      }
      default: {
        return <FailureInProofOfLife />;
      }
    }
  };
  return statusPj && renderStatusPage(statusPj);
};

export default AccountStatus;
