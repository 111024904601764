import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import {
  Container, Header, Content, Footer,
} from './styles';

import Button from '../Button';
import { useSettings } from '../../hooks/settings';

function Notification({
  title, visible, hidden, type, children,
}) {
  const { settings } = useSettings();
  function handleForm(e) {
    e.preventDefault();
    hidden();
  }

  return settings && settings.name ? (
    <Modal
      wrapClassName="modal-password"
      centered
      visible={visible}
      onOk={hidden}
      closable={false}
      onCancel={hidden}
      footer={false}
      destroyOnClose
      style={{ padding: 0, margin: 0, borderRadius: '8px' }}
      bodyStyle={{ padding: 0 }}
    >
      <Container>
        <Header backgroundcolor={settings.colors.primary}>
          {title}
          <CloseOutlined onClick={hidden} />
        </Header>
        <Content type={type}>
          <i>
            {type === 'error' && <CloseOutlined />}
            {type === 'success' && <CheckOutlined />}
          </i>
          <div>
            {children}
          </div>
          <i>
            {type === 'error' && <CloseOutlined />}
            {type === 'success' && <CheckOutlined />}
          </i>
        </Content>
        <Footer>
          <Button type="button" onClick={hidden}>
            <span>OK</span>
          </Button>
        </Footer>
      </Container>
    </Modal>
  ) : (<></>);
}

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  hidden: PropTypes.func.isRequired,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Notification.defaultProps = {
  type: 'success',
};

export default Notification;
