/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { useSettings } from '../../hooks/settings';
import { ListItem } from './styles';

export default function DynamicList({
  data, setData, label, onClickAdd,
}) {
  const { settings } = useSettings();
  const { t } = useTranslation();

  if (!data || !data.length) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h2 style={{ }}>{label}</h2>
        <FaPlusCircle
          size={25}
          style={{
            cursor: 'pointer', float: 'right', marginBottom: 20, marginRight: 8,
          }}
          color={settings?.colors?.primary || 'black'}
          onClick={onClickAdd}
        />
        <ListItem>
          <span style={{ opacity: 0.5 }}>{t('nenhuma informacao')}</span>
        </ListItem>
      </div>
    );
  }

  return (
    <>
      <h2 style={{ textAlign: 'center' }}>{label}</h2>
      <FaPlusCircle
        size={25}
        style={{
          cursor: 'pointer', float: 'right', marginBottom: 20, marginRight: 8,
        }}
        color={settings?.colors?.primary || 'black'}
        onClick={onClickAdd}
      />
      {data.map((item, index) => (
        <ListItem key={Math.random()}>
          {Object.keys(item).map((key) => <span style={{ opacity: 0.5 }}>{item[key]}</span>)}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaMinusCircle
              onClick={() => {
                const newData = [...data];
                newData.splice(index, 1);
                setData(newData);
              }}
              size={20}
              style={{ cursor: 'pointer', marginRight: 10 }}
              color="red"
            />
          </div>
        </ListItem>
      ))}
    </>
  );
}
