import React, {
  useRef, useCallback, useState,
} from 'react';

import {
  Modal, notification, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { CgAttachment } from 'react-icons/cg';
import { FiLoader } from 'react-icons/fi';
import * as Yup from 'yup';
import formErrors from '../../../services/formErrors';
import { useSettings } from '../../../hooks/settings';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Textarea from '../../../components/Textarea';

import {
  Container,
  Header,
  Content,
  InputNumber,
  ISlider,
  IUpload,
  DragAndDropContainer,
} from './styles';

const AdjustLimitRequest = ({ visible, hidden, currentLimit }) => {
  const { settings } = useSettings();

  const [currentJustificationFile, setCurrentJustificationFile] = useState(false);
  const [currentTermsFile, setCurrentTermsFile] = useState(false);
  const [loadingFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, account } = useAuth();
  const { t } = useTranslation();

  const [limitRequired, setLimitRequired] = useState(currentLimit);
  const formRef = useRef(null);

  const handleLimitRequiredChangeBySlider = useCallback((value) => {
    setLimitRequired(value);
  }, []);

  const handleLimitRequiredInputChange = useCallback((e, n, floatValue) => {
    if (floatValue < currentLimit) {
      setLimitRequired(currentLimit);
      formRef.current.setFieldValue('limitRequired', currentLimit);

      notification.error({
        message: `${t('valor minimo de...')} ${currentLimit.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}`,
      });

      return;
    }

    if (floatValue > currentLimit * 10) {
      setLimitRequired(currentLimit * 10);
      formRef.current.setFieldValue('limitRequired', currentLimit * 10);

      notification.error({
        message: `${t('valor maximo de...')} ${(currentLimit * 10).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}`,
      });

      return;
    }

    setLimitRequired(floatValue);
  }, [currentLimit, t]);

  const handleSendLimitRequest = useCallback(async (data) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const schemaFile = Yup.object().shape({
        limitRequired: Yup.string().required(t('limite obrigatorio')),
        justification: Yup.string().required(t('por favor preencha o campo de justificativa')),
      });

      await schemaFile.validate(data, { abortEarly: false });

      if (!currentJustificationFile || (user.document.split('').length > 11 && !currentTermsFile)) {
        if (!currentJustificationFile) {
          notification.error({
            message: t('documento obrigatorio'),
            description: t('por favor anexe um documento de justificativa'),
          });

          setLoading(false);
        }

        if (user.document.split('').length > 11 && !currentTermsFile) {
          notification.error({
            message: t('termo de autorizacao obrigatorio'),
            description: t('por favor anexe o termo de autorizacao'),
          });

          setLoading(false);
        }

        return;
      }

      const formData = new FormData();

      formData.append('justificationFile', currentJustificationFile, currentJustificationFile.name);

      if (user.document.split('').length > 11) {
        formData.append('limitAuthorizationTermsFile', currentTermsFile, currentTermsFile.name);
      }

      formData.append('requestedLimit', Number(limitRequired));
      formData.append('justification', data.justification);
      formData.append('lastLimit', currentLimit);

      await api.post('/limit-change-requests', formData, {
        headers: {
          account: account.id,
          user: user.id,
          authorization: `Bearer ${localStorage.getItem('@qesh:token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      hidden();
      notification.success({ message: t('solicitacao realizada com sucesso') });

      setLoading(false);
    } catch (err) {
      if (!currentJustificationFile) {
        notification.error({
          message: t('documento obrigatorio'),
          description: t('por favor anexe um documento de justificativa'),
        });

        setLoading(false);
      }

      if (user.document.split('').length > 11 && !currentTermsFile) {
        notification.error({
          message: t('termo de autorizacao obirgatorio'),
          description: t('por favor anexe o termo de autorizacao'),
        });
        setLoading(false);
      }

      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);

        formRef.current?.setErrors(errors);
        setLoading(false);
        return;
      }

      notification.error({ message: t('ocorreu um erro, tente novamente mais tarde') });
      setLoading(false);
      hidden();
    }
  }, [
    account.id,
    currentJustificationFile,
    currentLimit,
    currentTermsFile,
    hidden,
    limitRequired,
    t,
    user.document,
    user.id,
  ]);

  return (
    <Modal
      wrapClassName="adjust-limit-modal"
      centered
      visible={visible}
      onOk={hidden}
      closable={false}
      onCancel={hidden}
      footer={false}
      destroyOnClose
      style={{ padding: 0, margin: 0, borderRadius: '8px' }}
      bodyStyle={{ padding: 0 }}
      width={660}
    >
      <Container>
        <Header>
          <CloseOutlined onClick={hidden} />
        </Header>
        <Content>
          <Form
            onSubmit={handleSendLimitRequest}
            ref={formRef}
            style={{
              width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
          >
            <p>{t('valor desejado')}</p>

            <div className="value">
              <span>R$</span>
              <InputNumber
                label=""
                mask="currency"
                id="limitRequired"
                name="limitRequired"
                decimalSeparator=","
                thousandSeparator="."
                value={limitRequired}
                onChangeEvent={handleLimitRequiredInputChange}
              />
            </div>

            <span style={{ color: '#707070', fontSize: '10px', marginTop: '2px' }}>
              {`${t('seu limite atual e de...')} ${currentLimit.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}`}
            </span>

            <Row style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '46px',
            }}
            >
              <span style={{ marginRight: '6px', color: '#707070' }}>{currentLimit.toLocaleString('pt-BR')}</span>

              <ISlider
                style={{ flex: 1 }}
                min={currentLimit}
                max={currentLimit * 10}
                onChange={handleLimitRequiredChangeBySlider}
                value={typeof limitRequired === 'number' ? limitRequired : 0}
              />

              <span style={{ marginLeft: '6px', color: '#707070' }}>{(currentLimit * 10).toLocaleString('pt-BR')}</span>

            </Row>

            <Textarea
              maxLength={200}
              placeholder={t('digite sua resposta')}
              name="justification"
              id="justification"
              label={t('justificativa')}
              rows={1}
              cols={30}
              showRemainingChar
            />

            <DragAndDropContainer color={settings.colors.primary}>
              <IUpload
                name="file"
                accept="application/msword, text/plain, application/pdf"
                listType="text"
                showUploadList
                maxCount={1}
                onChange={(info) => {
                  setCurrentJustificationFile(info.file);
                }}
                onRemove={() => {
                  setCurrentJustificationFile(null);
                  return currentJustificationFile;
                }}
                beforeUpload={() => false}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: '16px',
                }}
                >
                  {loadingFile
                    ? <LoadingOutlined size={36} color="#707070" />
                    : <CgAttachment size={36} color="#707070" />}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginLeft: '16px',
                  }}
                  >
                    <span style={{ color: '#707070', fontWeight: 'bold' }}>{t('anexar documento')}</span>
                    <span style={{ color: '#707070', textAlign: 'start' }}>
                      {user.document.split('').length <= 11
                        ? `${t('por exemplo: contracheque, comprovante de renda...')}`
                        : `${t('por exemplo: relacao de faturamento, declaracao...')}`}
                    </span>
                  </div>
                </div>
              </IUpload>
              {user.document.split('').length > 11 && (
                <>
                  <IUpload
                    name="file"
                    accept="application/msword, text/plain, application/pdf"
                    listType="text"
                    showUploadList
                    maxCount={1}
                    onChange={(info) => {
                      setCurrentTermsFile(info.file);
                    }}
                    onRemove={() => {
                      setCurrentTermsFile(null);
                      return currentTermsFile;
                    }}
                    beforeUpload={(() => false)}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '16px',
                    }}
                    >
                      {loadingFile
                        ? <LoadingOutlined size={36} color="#707070" />
                        : <CgAttachment size={36} color="#707070" />}
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: '16px',
                      }}
                      >
                        <span style={{ color: '#707070', fontWeight: 'bold' }}>
                          {t('anexar o termo de autorizacao de aumento de limite')}
                        </span>
                        <span style={{ color: '#707070', textAlign: 'start' }}>
                          {t('baixe o modelo do documento de termo de autorizacao clicando no...')}
                        </span>
                      </div>
                    </div>
                  </IUpload>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <a
                      href="https://qesh-storage.s3.amazonaws.com/termos/TERMO_DE_AUTORIZA%C3%87%C3%83O_DE_AUMENTO_DE_LIMITE.pdf"
                      target="blank"
                    >
                      {t('clique aqui para baixar o documento')}
                    </a>
                  </div>
                </>
              )}
            </DragAndDropContainer>

            <Button disabled={loading} type="submit">
              {!loading ? <span>{t('enviar pedido')}</span> : <FiLoader />}
            </Button>
          </Form>

        </Content>
      </Container>
    </Modal>
  );
};

AdjustLimitRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  hidden: PropTypes.func.isRequired,
  currentLimit: PropTypes.number.isRequired,
};

export default AdjustLimitRequest;
