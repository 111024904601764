import styled from 'styled-components';

export const ListItem = styled.div`
  display: flex;
  width: 100%;
  background-color: #e6e6e6;
  min-height: 40px;
  padding-left: 40px;
  align-items: center;
  border-bottom: 1px solid white;
  justify-content: space-between;
  flex-wrap: wrap;
`;
