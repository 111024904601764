import React, { useEffect, useMemo, useState } from 'react';
import StudentPage from './StudentPage';
import PartnerPage from './PartnerPage';
import NmPage from './NmPage';
import chargesApi from '../../../services/charges';
import { Container } from './styles';
import { useAuth } from '../../../hooks/auth';
import { ReactSelect } from './StudentPage/styles';

export const NM_DOCUMENT = '22466925000165';
export const GOD_DOCUMENTS = ['13217999690', '11340898640', '11908799641'];

const EducationalPayments = () => {
  const { user, token } = useAuth();
  const [pageContent, setPageContent] = useState();
  const [partnerId, setPartnerId] = useState();
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (!GOD_DOCUMENTS.includes(user.document)) {
      if (user.document === NM_DOCUMENT) {
        setPageContent('NmPage');
      } else {
        chargesApi.get(`nmpay/partners/${user.document}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          setPartnerId(response.data.id);
          setPageContent('PartnerPage');
        }).catch(() => {
          setPageContent('StudentPage');
        });
      }
    } else { setPageContent('StudentPage'); }
  }, [user.document, token]);

  useEffect(() => {
    if (selectedOption === 'Aluno') {
      setPageContent('StudentPage');
    } else if (selectedOption === 'Faculdade') {
      chargesApi.get(`nmpay/partners/${user.document}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setPartnerId(response.data.id);
        setPageContent('PartnerPage');
      });
    } else if (selectedOption === 'NMPay') {
      setPageContent('NmPage');
    }
  }, [selectedOption, token, user]);

  const Content = useMemo(() => {
    if (pageContent === 'StudentPage') {
      return <StudentPage />;
    }

    if (pageContent === 'PartnerPage') {
      return <PartnerPage partnerId={partnerId} />;
    }

    if (pageContent === 'NmPage') {
      return <NmPage />;
    }

    return (<></>);
  }, [pageContent, partnerId]);

  return (
    <Container>
      {GOD_DOCUMENTS.includes(user.document) && (
        <div style={{
          width: '100%', display: 'flex', alignItems: 'center', flexFlow: 'column',
        }}
        >
          <span style={{ alignSelf: 'center' }}>Ver como</span>
          <ReactSelect
            style={{ alignSelf: 'center' }}
            id="partner_select"
            placeholder="Aluno"
            options={[{ label: 'Aluno', value: 'Aluno' }, { label: 'Faculdade', value: 'Faculdade' },
              { label: 'NMPay', value: 'NMPay' }]}
            onChange={(e) => setSelectedOption(e.value)}
          />
        </div>
      )}
      {Content}
    </Container>
  );
};

export default EducationalPayments;
