import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';

import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';

import { Form } from '@unform/web';
import {
  format, parseISO,
} from 'date-fns';
import * as Yup from 'yup';
import { useSettings } from '../../../hooks/settings';
import { Content, TopicMarker, ButtonsContainer } from './styles';
import { SelectionButton } from '../styles';
import BilletFields from './Fields/Billet';
import CardFields from './Fields/Card';
import api from '../../../services/api';
import getErrors from '../../../services/errors';
import formErrors from '../../../services/formErrors';
import Loading from '../../../components/Loading';
import Notification from '../../../components/Notification';

function NewCharge() {
  const { settings } = useSettings();
  const formRef = useRef(null);
  const billetType = 'BILLET';
  // const pixType = 'PIX';
  const cardType = 'CARD';
  const [selectedType, setSelectedType] = useState('BILLET');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [billet, setBillet] = useState(null);
  const [card, setCard] = useState(null);
  const [amount, setAmount] = useState(null);
  const [juros, setJuros] = useState(null);
  const [multa, setMulta] = useState(null);
  const [split, setSplit] = useState([]);
  const [maxInstallments, setMaxInstallments] = useState({ label: '1', value: 1 });
  const [permitCardCharge, setPermitCardCharge] = useState(null);

  const handleTypeSelection = useCallback((type) => {
    setSelectedType(type);
  }, []);

  const loadCardPaymentsCredentials = useCallback(async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-shadow
      const { data: permitCardCharge } = await api.get('/card-payments-credentials');
      setPermitCardCharge(permitCardCharge);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    loadCardPaymentsCredentials();
  }, [loadCardPaymentsCredentials]);

  const formContent = useCallback(() => {
    switch (selectedType) {
      case billetType:
        return (
          <BilletFields
            amount={amount}
            setAmount={setAmount}
            multa={multa}
            setMulta={setMulta}
            juros={juros}
            setJuros={setJuros}
            split={split}
            setSplit={setSplit}
            billet={billet}
            formRef={formRef}
          />
        );

      case cardType:
        return (
          <CardFields
            amount={amount}
            setAmount={setAmount}
            card={card}
            formRef={formRef}
            credentials={permitCardCharge}
            setMaxInstallments={setMaxInstallments}
            maxInstallments={maxInstallments}
          />

        );

      default:
        return (<></>);
    }
  }, [selectedType, amount, juros, multa, split, billet, card, permitCardCharge, maxInstallments]);

  const handleSubmit = useCallback(async (data) => {
    let billetFormErrors = {};
    let cardFormErrors = {};

    switch (selectedType) {
      case billetType:
        formRef.current.setErrors({});
        setError('');
        try {
          if (!amount || amount < 0.1) {
            billetFormErrors = { value: 'O valor deve ser maior ou igual a R$0,10' };
          }

          if (split && split.length) {
            let totalPercent = 0;
            let notInteger = false;
            let noDigit = false;
            let noName = false;
            let noDocument = false;
            split.forEach((i) => {
              if (!Number.isInteger(i.percent)) {
                notInteger = true;
                return;
              }
              if (!i.digit) {
                noDigit = true;
                return;
              }
              if (!i.name) {
                noName = true;
                return;
              }
              if (!i.document) {
                noDocument = true;
                return;
              }
              totalPercent += i.percent;
            });

            if (notInteger) {
              split.forEach((i) => {
                billetFormErrors = {
                  ...billetFormErrors,
                  [`splits[${i.id}].percent`]: 'As porcentagens devem ser números inteiros.',
                };
              });
            }

            if (noDigit) {
              split.forEach((i) => {
                billetFormErrors = {
                  ...billetFormErrors,
                  [`splits[${i.id}].digit`]: 'O dígito é obrigatório.',
                };
              });
            }

            if (noName) {
              split.forEach((i) => {
                billetFormErrors = {
                  ...billetFormErrors,
                  [`splits[${i.id}].name`]: 'O nome é obrigatório.',
                };
              });
            }

            if (noDocument) {
              split.forEach((i) => {
                billetFormErrors = {
                  ...billetFormErrors,
                  [`splits[${i.id}].document`]: 'O documento é obrigatório.',
                };
              });
            }

            if (totalPercent > 100) {
              split.forEach((i) => {
                billetFormErrors = {
                  ...billetFormErrors,
                  [`splits[${i.id}].percent`]: 'A soma das porcentagens não pode ultrapassar 100%.',
                };
              });
            }
          }

          const schema = Yup.object().shape({
            document: Yup.string().required('O documento é obrigatório.'),
            name: Yup.string().required('O nome é obrigatório.'),
            cep: Yup.string().required('O CEP é obrigatório.'),
            logradouro: Yup.string().required('O logradouro é obrigatório.'),
            state: Yup.string().length(2, 'Deve ter 2 dígitos.').required('O Estado é obrigatório.'),
            city: Yup.string().required('A cidade é obrigatória.'),
            neighborhood: Yup.string().required('O bairro é obrigatório.'),
            number: Yup.string().required('O número é obrigatório.'),
            date: Yup.string().required('A data é obrigatória.'),
            description: Yup.string().required('A descrição é obrigatória.'),
            phone: Yup.string().matches(/(\d{2}\s\d{4,5}\d{4})|^$/, 'O formato do telefone é inválido').nullable(),
            email: Yup.string().email('O email é inválido'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (Object.keys(billetFormErrors).length > 0) {
            formRef.current.setErrors(billetFormErrors);
            return;
          }

          setLoading(true);

          const corpoJuros = Number(juros) > 0
            ? {
              juros: {
                type: 'Percentual',
                valor: Number(juros),
              },
            }
            : {};
          const corpoMulta = Number(multa) > 0
            ? {
              multa: {
                type: 'Percentual',
                valor: Number(multa),
              },
            }
            : {};

          let corpoSplit = {};
          if (split && split.length) {
            corpoSplit = {
              charge_split: split.map((i) => ({ percent: i.percent, account: `${i.account}${i.digit}` })),
            };
          }

          const rawDocument = data.document.replace(/[./-]/g, '');
          const { data: response } = await api.post('billets', {
            type: 'CHARGE',
            description: data.description,
            descriptions: [data.description],
            name: data.name,
            document: rawDocument,
            due_at: format(parseISO(data.date), 'yyyy-MM-dd'),
            address: data.logradouro,
            number: data.number || '',
            city: data.city,
            state: data.state,
            zipcode: data.cep.replace(/[./-]/g, ''),
            neighborhood: data.neighborhood,
            complement: data.complement || '',
            amount,
            charged_email: data.email,
            charged_phone: data.phone,
            ...corpoJuros,
            ...corpoMulta,
            ...corpoSplit,
          });

          setBillet(response);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors({ ...billetFormErrors, ...errors });
            return;
          }

          setError(getErrors(err));
        }
        break;
      case cardType:
        formRef.current.setErrors({});
        setError('');
        try {
          if (!amount || amount < 0.1) {
            cardFormErrors = { value: 'O valor deve ser maior ou igual a R$0,10.' };
          }

          const validateSchema = Yup.object().shape({
            document: Yup.string().required('O documento é obrigatório.'),
            name: Yup.string().required('O nome é obrigatório.'),
            date: Yup.date().required('A data é obrigatória.')
              .min(format(new Date(), 'yyyy-MM-dd'), 'A data não pode ser menor que hoje.'),
            description: Yup.string().required('A descrição é obrigatória.'),
            email: Yup.string().email('O email é inválido.').required('O e-mail é obrigatório.'),
            phone: Yup.string().matches(/(\d{2}\s\d{4,5}\d{4})|^$/, 'O formato do telefone é inválido.')
              .required('O telefone é obrigatório.'),
          });

          await validateSchema.validate(data, {
            abortEarly: false,
          });

          if (Object.keys(cardFormErrors).length > 0) {
            formRef.current.setErrors(cardFormErrors);
            return;
          }

          setLoading(true);

          const formatedDocument = data.document.replace(/[./-]/g, '');

          const { data: response } = await api.post('/card-payments-links', {
            amount,
            description: data.description,
            due_at: format(parseISO(data.date), 'yyyy-MM-dd'),
            charged_name: data.name,
            charged_document: formatedDocument,
            charged_email: data.email,
            charged_phone: data.phone,
            ...(permitCardCharge?.platform === 'GETNET' && maxInstallments && maxInstallments.value && (
              { max_installments_allowed: maxInstallments.value })
            ),
          });

          setCard(response);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors({ ...cardFormErrors, ...errors });
            return;
          }

          setError(getErrors(err));
        }
        break;
      default:
    }
  }, [selectedType, amount, juros, multa, split, permitCardCharge, maxInstallments]);

  return settings && settings.name ? (
    <>
      <Notification
        type="error"
        visible={!!error}
        title="Erro ao gerar cobrança"
        hidden={() => setError('')}
      >
        {error}
      </Notification>
      <Loading hasLoading={loading} />
      <span>Cobranças</span>
      <h1>Nova cobrança</h1>
      <Content>
        {!billet && !card && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TopicMarker />
            <h3 style={{ marginTop: 'auto', marginBottom: 'auto' }}>Escolha o tipo de cobrança:</h3>
          </div>
          <ButtonsContainer>
            {/* <SelectionButton
            onClick={() => handleTypeSelection(pixType)}
            active={selectedType === pixType}
            primaryColor={settings.colors.primary}
          >
            Pix
          </SelectionButton> */}
            <SelectionButton
              onClick={() => {
                setAmount(null);
                handleTypeSelection(billetType);
              }}
              active={selectedType === billetType}
              primaryColor={settings.colors.primary}
            >
              Boleto
            </SelectionButton>

            {permitCardCharge && (
            <SelectionButton
              onClick={() => {
                setAmount(null);
                handleTypeSelection(cardType);
              }}
              active={selectedType === cardType}
              primaryColor={settings.colors.primary}
            >
              Cartão
            </SelectionButton>
            )}
          </ButtonsContainer>
        </>
        )}
        <Form
          id="charges-form"
          ref={formRef}
          onSubmit={handleSubmit}
        >
          {formContent()}
        </Form>
      </Content>
    </>
  ) : (<></>);
}

export default NewCharge;
