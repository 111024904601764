/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable quote-props */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
      //   en: {
      //     translation: {
      //     // pages
      //       // Login
      //       'Welcome to React': 'Welcome to React and react-i18next',
      //       'title Login': 'access your account',
      //       'Password Login': 'Password',
      //       'Button Sign in': 'Sign in',
      //       'Button Forgot': 'I forgot my password',
      //       'Label Register': 'Don\'t have a record?',
      //       'Button Register': 'Register',

      //       // Deposit
      //       'Deposits Title': 'Deposits',
      //       'Button Generate billet': 'Generate billet',

      //       // components
      //       // Header
      //       'Notification': 'No new notifications.',

      //       // Menu
      //       'Transaction Menu': 'Transactions',
      //       'Deposit Menu': 'Deposit',
      //       'Transfer Menu': 'Transfer',
      //       'Payment Menu': 'Payment',
      //       'Charges Menu': 'Charges',
      //       'Settings Menu': 'Settings',
      //       'Exit Menu': 'Exit',

      //     },
      //   },
      zh: {
            translation: {
                  // espaço para chinês
                  // pages

                  // adicionado posteriormente
                  'acesse sua conta': '登入账号',
                  'alterar idioma': '语言',
                  'todas as transacoes': '一切',
                  'recebimento de boletos': '收到付款单',
                  'homepage': '主页',
                  'ofereca diferentes formas': '为您的客户提供不同形式的付款，包括 12 次分期付款。',
                  'conhecer solucoes': '见面',
                  'vendas': '销售',
                  'receita': '收入',
                  'despesas': '支出',
                  'voce no controle': '你在掌控之中！',
                  'wibbank e uma plataforma completa': 'Wibbank 是一个完整的平台，用于捕获信用卡和借记卡交易。',
                  'cartao pre pago': '预付卡',
                  'quer ter cartao': '你想拥有一张没有收入证明的信用卡吗？',
                  'pedir agora': '立即订购',

                  // google tradutor pois nao tinha sido traduzido:
                  'envie foto sorrindo': '发送一张你笑脸的照片',
                  'sorrindo descricao': '为确保只有您可以访问您的帐户，请拍一张微笑的照片。来吧？',
                  'tirar foto': '拍照',
                  'pode continuar pelo telefone': '您可以通过手机继续！',
                  'aponte a camera': '将手机的相机对准下方的二维码，然后继续通过应用程序开设您的帐户。',
                  'capture imagem sorrindo': '请捕捉微笑的图像。',
                  'foto ficou boa?': '提交的照片是否清晰锐利？',
                  'ficou boa': '很好',
                  'tirar outra': '再拍一张',
                  'tire serio': '提交一张照片作为严肃的表情。',
                  'tire serio descricao': '为确保只有您可以访问您的帐户，请拍摄一张严肃的照片。来吧？',

                  //       // Account
                  //       Account/index
                  //       1 - Dados cadastrais
                  'dados cadastrais': '注册资料',
                  // 2 - Atualizar documentos
                  'atualizar documentos': '更新文件',
                  // 3 - Resetar senha de 4 dígitos
                  'resetar senha de 4 digitos': '从新注册4位密码',

                  //       // AccountSelection
                  //       /AccountSelection/index
                  //       4 - Código inválido
                  'codigo invalido': '认证码无效',
                  //       5 - Código enviado
                  'codigo enviado': '认证码以发送',
                  //       6 - Bem-vindo
                  'bem-vindo': '欢迎来到',
                  //       7 - Escolha a conta que deseja acessar
                  'escolha a conta que deseja acessar': '选择您要登入的帐户',
                  //       8 - Insira o código recebido por SMS
                  'insira o codigo recebido por sms': '输入短信收到的验证码',
                  //       9 - Confirmar
                  'confirmar': '确认',

                  //       // Clank
                  //       /Blank/index
                  //       10 - Digite os dados de cobrança
                  'digite os dados de cobrança': '输入账单数据',

                  //       // Charge
                  //       /Charge/ImportCharges/index
                  //       11 - Por favor anexe um documento
                  'por favor anexe um documento': '请附上文件',
                  // 12 - Esse arquivo não nenhuma linha com os dados corretos
                  'esse arquivo não nenhuma linha com os dados corretos': '该文件没有包含正确的数据',
                  // 13 - Todas as linhas do arquivo foram importadas com sucesso
                  'todas as linhas do arquivo foram importadas com sucesso': '文件中的所有行都已成功导入',
                  // 14 - Erro na linha
                  'erro na linha': '线路错误',
                  // 15 - Demais linhas foram criadas com sucesso
                  'demais linhas foram criadas com sucesso': '其他线路已成功创建',
                  // 16 - Erro na importação de todas as linhas da planilha
                  'erro na importação de todas as linhas da planilha': '导入所有电子表格行时出错',
                  // 17 - Ocorreu um erro tente novamente mais tarde
                  'ocorreu um erro tente novamente mais tarde': '发生错误，请稍后再试',
                  // 18 - Importar cobranças em lote
                  'importar cobranças em lote': '导入收款批料',
                  // 19 - Clique aqui para fazer o download da planilha de exemplo
                  'clique aqui para fazer o download da planilha de exemplo': '单击此处下载示例表格',
                  // 20 - Ocorreu um erro ao importar o arquivo
                  'ocorreu um erro ao importar o arquivo': '上传文件时出错',
                  // 21 - Anexar arquivo
                  'anexar arquivo': '附加文件',
                  // 22 - Arraste para esse retângulo, ou clique aqui e selecione um arquivo (xlsx / xls / csv)
                  'arraste para esse retangulo...': '拖动到此矩形，或单击此处并选择一个文件 (xlsx / xls / csv)',
                  // 23 - Enviar arquivo
                  'enviar arquivo': '发送文件',

                  // /Charge/NewCharge/Fields/Billet/index
                  // 24 - Insira os dados da pessoa/empresa recebedora
                  'insira os dados da pessoa/empresa recebedora': '输入接收人/公司的数据',
                  // 25 - CPF / CNPJ
                  'cpf / cnpj': 'CPF或者CNPJ',
                  // 26 - Nome completo
                  'nome completo': '全名',
                  // 27 - Banco
                  'banco': '银行',
                  // 28 - Agência
                  'agencia': '银行编号',
                  // 29 - Conta
                  'conta': '银行账号',
                  // 30 - Dígito
                  'digito': '数字',
                  // 31 - Porcentagem (%) de recebimento
                  'porcentagem de recebimento': '收据的百分比 (%)',
                  // 32 - Remover esse split
                  'remover esse split': '删除此拆分',
                  // 33 - Adicionar split de pagamento
                  'adicionar split de pagamento': '添加付款拆分',
                  // 34 - Dados do boleto
                  'dados do boleto': '付款单数据',
                  // 35 - Linha Digitável
                  'linha digitavel': '数字线',
                  // 36 - Linha digitável copiada com sucesso
                  'linha digitavel copiada com sucesso': '数字线以成功复制',
                  // 37 - TOQUE AQUI PARA COPIAR
                  'toque aqui para copiar': '点击此处复制',
                  // 38 - Valor
                  'valor': '金额',
                  // 39 - Vencimento
                  'vencimento': '截止日期',
                  // 40 - IMPRIMIR BOLETO
                  'imprimir boleto': '打印账单',
                  // 41 - Valor da cobrança
                  'valor da cobrança': '收费金额',
                  // 42 - Juros (%) ao dia
                  'juros ao dia': '日利息',
                  // 43 - Multa (%)
                  'multa': '罚款',
                  // 44 - Data de vencimento
                  'data de vencimento': '支付日期',
                  // 45 - Descrição da cobrança
                  'descricao da cobranca': '帐单说明',
                  // 46 - Quem será cobrado?
                  'quem sera cobrado': '谁将被指控？',
                  // 47 - Insira os dados da pessoa que será cobrada
                  'insira os dados da pessoa que sera cobrada': '输入将被计费的人的详细信息',
                  // 25 - CPF / CNPJ
                  // 26 - Nome completo
                  // 48 - CEP
                  'cep': 'CEP',
                  // 49 - Logradouro
                  'logradouro': '地址',
                  // 50 - Número
                  'numero': '号码',
                  // 51 - Complemento
                  'complemento': '附件',
                  // 52 - Bairro
                  'bairro': '地区',
                  // 53 - Cidade
                  'cidade': '城市',
                  // 54 - Estado
                  'estado': '州',
                  // 55 - E-mail
                  'email': '邮箱',
                  // 56 - Celular
                  'celular': 'DDD+手机号',
                  // 57 - Além de você, alguém mais embolsará o pagamento dessa cobrança?
                  'alem de voce...': '除了你，会有其他人支付这笔费用吗',
                  // 58 - Gerar cobrança
                  'gerar cobranca': '生成账单',
                  // 59 - Carregando
                  'carregando': '加载中',

                  // /Charge/NewCharge/Fields/Card/index
                  // 60 - Dados da cobrança por cartão de crédito
                  'dados da cobranca por cartao de credito': '信用卡账单明细',
                  // 61 - Cobrança gerada com sucesso!
                  'cobranca gerada com sucesso': '账单以成功！',
                  // 62 - Link pagamento Cartão de Crédito
                  'link pagamento cartao de credito': '信用卡支付链接',
                  // 63 - Link para pagamento em cartão de crédito copiado com sucesso
                  'link para pagamento em cartao...': '信用卡付款链接成功复制',
                  // 37 - TOQUE AQUI PARA COPIAR
                  // 38 - Valor
                  // 39 - Vencimento
                  // 41 - Valor da cobrança
                  // 44 - Data de vencimento
                  // 64 - Número máximo de parcelas
                  'numero maximo de parcelas': '最大分期付款次数',
                  // 45 - Descrição da cobrança
                  // 46 - Quem será cobrado?
                  // 47 - Insira os dados da pessoa que será cobrada
                  // 25 - CPF / CNPJ
                  // 26 - Nome completo
                  // 55 - E-mail
                  // 56 - Celular
                  // 58 - Gerar cobrança
                  // 59 - Carregando

                  // /Charge/NewCharge/index
                  // 65 - O valor deve ser maior ou igual a R$0,10
                  'o valor deve ser maior ou igual a...': '金额必须大于或等于 BRL 5.00',
                  // 66 - As porcentagens devem ser números inteiros
                  'as porcentagens devem ser numeros inteiros': '百分比必须是整数',
                  // 67 - O dígito é obrigatório
                  'o digito e obrigatorio': '数字为必填的',
                  // 68 - O nome é obrigatório
                  'o nome e obrigatorio': '名称为必填的',
                  // 69 - O documento é obrigatório
                  'o documento e obrigatorio': '该文件为必填的',
                  // 70 - A soma das porcentagens não pode ultrapassar 100%
                  'a soma das porcentagens nao pode ultrapassar...': 'A soma das porcentagens não pode ultrapassar 100%',
                  // 69 - O documento é obrigatório
                  // 68 - O nome é obrigatório
                  // 71 - O CEP é obrigatório
                  'o cep e obrigatorio': 'CEP为必填',
                  // 72 - O logradouro é obrigatório
                  'o logradouro e obrigatorio': '地址必填',
                  // 73 - Deve ter 2 dígitos
                  'deve ter 2 digitos': '必须有 2 位数字',
                  // 74 - O Estado é obrigatório
                  'o estado e obrigatorio': '州必填',
                  // 75 - A cidade é obrigatória
                  'a cidade e obrigatoria': '城市必填',
                  // 76 - O bairro é obrigatório
                  'o bairro e obrigatorio': '地区必填',
                  // 77 - O número é obrigatório
                  'o numero e obrigatorio': '号码必填',
                  // 78 - A data é obrigatória
                  'a data e obrigatoria': '日期必填',
                  // 79 - A descrição é obrigatória
                  'a descricao e obrigatoria': '说明为必填项',
                  // 80 - O formato do telefone é inválido
                  'O formato do telefone é inválido': '手机号码无效',
                  // 81 - O email é inválido
                  'o email e invalido': '邮件无效',
                  // 82 - O e-mail é obrigatório
                  'o email e obrigatorio': '电子邮件为必填项',
                  // 65 - O valor deve ser maior ou igual a R$0,10
                  // 83 - A data não pode ser menor que hoje
                  'a data nao pode ser menor que hoje': '日期不能小于今天',
                  // 84 - O telefone é obrigatório
                  'o telefone e obrigatorio': '手机号码必填',
                  // 85 - Erro ao gerar cobrança
                  'erro ao gerar cobranca': '生成账单时出错',
                  // 86 - Cobranças
                  'cobrancas': '收款',
                  // 87 - Nova cobrança
                  'nova cobranca': '新收款',
                  // 88 - Escolha o tipo de cobrança
                  'escolha o tipo de cobranca': '选择收款类型',
                  // 89 - Boleto
                  'boleto': '付款单',
                  // 90 - Cartão:
                  'cartao': '卡',

                  // /Charge/index
                  // 91 - Recebido
                  'recebido': '收到',
                  // 92 - Pago (Em processamento)
                  'pago (em processamento)': '已支付（进行中）',
                  // 93 - Vencido
                  'vencido': '逾期',
                  // 94 - Em Aberto
                  'em aberto': '进行中',
                  // 89 - Boleto
                  // 95 - Cartão de crédito
                  'cartao de credito': '信用卡',
                  // 96 - Pix
                  'pix': 'PIX',
                  // 97 - Link copiado com sucesso
                  'link copiado com sucesso': '链接复制成功',
                  // 98 - Deseja mesmo cancelar essa cobrança? Essa ação não pode ser desfeita
                  'deseja mesmo cancelar...': '您要取消这笔收款吗？此操作无法撤消',
                  // 86 - Cobranças
                  // 87 - Nova cobrança
                  // 99 - Importar cobranças
                  'importar cobrancas': '导入收款单',
                  // 100 - De:
                  'de': '从',
                  // 101 - Até:
                  'ate': '直到',
                  // 86- Cobranças
                  // 102 - Relatório de cobranças
                  'relatorio de cobrancas': '帐单报告',
                  // 103 - Pago
                  'pago': '已支付',
                  // 86- cobranças
                  // 104 - cobrança
                  'cobranca': '收款',
                  // 105 - Pendente
                  'pendente': '待办中',
                  // 93 - Vencido
                  // 106 - Total de cobranças nesse período
                  'total de cobrancas nesse periodo': '本期总费用',
                  // 86- cobranças
                  // 107 - Tipos de cobrança
                  'tipos de cobranca': '收款类型',
                  // 108 - Boletos
                  'boletos': '付款单',
                  // 89 - Boleto
                  // 90 - Cartão
                  // 109 - Cobranças em atraso
                  'cobrancas em atraso': '逾期收款单',
                  // 110 - Lista de cobranças
                  'lista de cobrancas': '收款单清单',
                  // 111 - Pesquisar por nome, documento...
                  'pesquisar por nome, documento...': '按名称、文档搜索。。。',
                  // 112 - Baixar extrato
                  'baixar extrato': '下载对账单',
                  // 113 - Nenhuma cobrança para exibir
                  'nenhuma cobranca para exibir': '无收款单',
                  // 114 - Nome
                  'nome': '全名',
                  // 115 - Emissão
                  'emissao': '发行',
                  // 39 - Vencimento
                  // 38 - Valor
                  // 116 - Valor recebido
                  'valor recebido': '收到的金额',
                  // 117 - Tipo
                  'tipo': '类型',
                  // 118 - Status
                  'status': '状态',
                  // 119 - Copiar link da cobrança
                  'copiar link da cobranca': '复制收款单链接线',
                  // 120 - Visualizar cobrança
                  'visualizar cobranca': '查看收款单',
                  // 121 - Cancelar cobrança
                  'cancelar cobranca': '取消收款单',

                  //       // Deposit
                  //       'Deposits Title': 'Depósitos',
                  //       'Button Generate billet': 'Gerar Boleto',

                  //       /Deposit/index
                  // 122 - O valor é obrigatório
                  'o valor e obrigatorio': '金额必须填写',
                  // 123 - Valor mínimo R$ 0,10
                  'valor minimo...': '最小值 BRL 5.00',
                  // 124 - O identificador é obrigatório
                  'o identificador e obrigatorio': '标识符必须填写',
                  // 103 - Pago
                  // 93 - Vencido
                  // 94 - Em Aberto
                  // 125 - Depósitos
                  'depositos': '存款',
                  // 126 - Gerar boleto
                  'gerar boleto': '生成付款单',
                  // 127 - Boleto bancário
                  'boleto bancario': '银行付款单',
                  // 128 - O dinheiro entrará em sua conta em até 02 dias úteis após o pagamento do boleto
                  'o dinheiro entrara em sua conta em ate...': '余额将在支付银行收据后的 2 个工作日内进入您的账户',
                  // 129 - Quanto deseja depositar?
                  'quanto deseja depositar': '您想存多少钱？',
                  // 130 - Identificador no comprovante
                  'identificador no comprovante': '收据上的标识符',
                  // 126 - Gerar boleto
                  // 34 - Dados do boleto
                  // 35 - Linha Digitável
                  // 36 - Linha digitável copiada com sucesso
                  // 37 - TOQUE AQUI PARA COPIAR
                  // 38 - Valor
                  // 39 - Vencimento
                  // 40 - IMPRIMIR BOLETO
                  // 131 - Depósito
                  'deposito': '存款',
                  // 100 – De:
                  // 101 - Até:
                  // 132 - Nenhum boleto para exibir
                  'nenhum boleto para exibir': '无付款单可显示',
                  // 133 - Identificação no comprovante
                  'identificacao no comprovante': '凭证上的收据',
                  // 134 - Data de emissão
                  'data de emissao': '发行日期',
                  // 39 - Vencimento
                  // 38 - Valor
                  // 118 - Status
                  // 89 - Boleto
                  // 135 - Erro ao gerar boleto
                  'erro ao gerar boleto': '生成付款单时出错',

                  //       // Documents
                  //       /Documents/index
                  //       136 - Não foi possível validar o documento enviado
                  'nao foi possivel validar o documento enviado': '无法验证提交的文件',
                  //       137 - Para continuar envie outro documento
                  'para continuar envie outro documento': '继续请发送另一个文件',
                  //       138 - Envie sua selfie
                  'envie sua selfie': '提交您的自拍',
                  //       139 - A foto precisa mostrar seu rosto inteiro e de olhos abertos
                  'a foto precisa mostrar seu rosto inteiro e de olhos abertos': '照片必须显示你的整个脸和眼睛',
                  //       140 - Selecione um documento
                  'selecione um documento': '选择一个文件',
                  //       141 - Próximo
                  'proximo': '继续',
                  //       142 - Envie foto da FRENTE da sua CNH
                  'envie foto da frente da sua cnh': '发送您的CNH正面照片',
                  //       143 - Envie foto da FRENTE do seu RG
                  'envie foto da frente do seu rg': '发送您的身份证正面照片',
                  //       144 - Clique para fazer upload
                  'clique para fazer upload': '点击上传',
                  //       145 - Excluir
                  'excluir': '删除',
                  //       141 - Próximo
                  //       146 - Envie foto do VERSO da sua CNH
                  'envie foto do verso da sua cnh': '发送您的 CNH 背面的照片',
                  //       147 - Envie foto do VERSO do seu RG
                  'envie foto do verso do seu rg': '发送您的 身份证背面的照片',
                  //       144 - Clique para fazer upload
                  //       145 Multa- Envie o documento principal de identificação/constituição da empresa. Ex: Contrato Social
                  'envie o documento principal de identificacao...': '发送公司的主要身份证明/章程文件。例如：公司章程',

                  //       144 - Clique para fazer upload
                  //       145 - Excluir
                  //       149 - Finalizar
                  'finalizar': '完成',
                  //       150 - Parabéns, os arquivos foram salvos com sucesso!
                  'parabens, os arquivos foram salvos com sucesso': '恭喜，文件保存成功！',
                  //       151 - Tipo de documento é obrigatório
                  'tipo de documento e obrigatorio': '文件类型必填',
                  //       152 - Erro ao enviar documentos
                  'erro ao enviar documentos': '发送文件时出错',
                  //       153 - Atualização de documentos
                  'atualizacao de documentos': '文件更新',

                  //       // ForgotPassword
                  //       /ForgotPassword/index
                  //       154 - Erro ao recuperar a senha
                  'erro ao recuperar senha': '恢复密码时出错',
                  // 155 - Recupere sua senha pelo e-mail
                  'recupere sua senha pelo email': '通过电子邮件找回密码',
                  // 156 - Recupere a sua senha de acesso
                  'recupere a sua senha de acesso': '恢复您的登入密码',
                  // 157 - Insira o CPF/CNPJ cadastrado
                  'insira o cpf/cnpj cadastrado': '输入注册的CPF/CNPJ',
                  // 158 - Recuperar senha
                  'recuperar senha': '恢复密码',

                  //       // Invite
                  //       /Invite/index
                  //       159 - Baixe o app
                  'baixe o app': '下载App',
                  // 160 - Disponível no Google Play
                  'disponivel no google play': 'Google Play(Android)下载区',
                  // 161 - Disponível na App Store
                  'disponivel na app store': 'Apple Store下载区',
                  //       // Login
                  //       'title Login': 'Acesse sua conta',
                  //       'Password Login': 'Senha',
                  //       'Button Sign in': 'Entrar',
                  //       'Button Forgot': 'Esqueci minha senha',
                  //       'Label Register': 'Não possui cadastro?',
                  //       'Button Register': 'Cadastrar',

                  //       /Login/index
                  //       162 - Credenciais inválidas
                  'credenciais invalidas': '无效证件',
                  // 163 - Erro ao fazer login
                  'erro ao fazer login': '登录错误',
                  // 164 - Senha
                  'senha': '密码',
                  // 165 - Entrar
                  'entrar': '登录',
                  // 166 - Esqueci minha senha
                  'esqueci minha senha': '忘记密码',
                  // 167 - Não possui cadastro?
                  'nao possui cadastro': '没有注册？',
                  // 168 - Cadastrar
                  'cadastrar': '注册',

                  //       // nmpay
                  //       /nmpay/EducationalPayments/ImportCharges/index
                  //       169 - No máximo 3MB por arquivo
                  'no maximo 3mb por arquivo': '文件最大 3MB',
                  // 170 - Formato de arquivo inválido
                  'formato de arquivo invalido': '无效的文件格式',
                  // 20 - Ocorreu um erro ao importar o arquivo.
                  // 171 - Por favor importe a planilha
                  'por favor importe a planilha': '请导入电子表格',
                  // 172 - Erro na linha (numero?) da planilha
                  'erro na linha (numero?) da planilha': '电子表格的行（编号？）出错',
                  // 173 - Cobranças foram criadas
                  'cobrancas foram criadas': '创建了收款',
                  // 174 - Nenhuma cobrança foi criada
                  'nenhuma cobranca for criada': '没有产生任何费用',
                  // 17 - Ocorreu um erro tente novamente mais tarde
                  // 175 - Envie a planilha para cadastrar as cobranças/mensalidades
                  'envie a planilha para cadastrar as cobrancas/mensalidades': '发送电子表格以登记费用/月费',
                  // 176 - Arquivo anexado:
                  'arquivo anexado': '附件：',
                  // 177 - Clique ou arraste a planilha para esta área para substituir o arquivo
                  'clique ou arraste a planilha para esta area para substituir o arquivo': '单击或拖动工作表到此区域以替换文件',
                  // 178 - Clique ou arraste a planilha para esta área para fazer o upload
                  'clique ou arraste a planilha para esta area para fazer o upload': '单击或拖动电子表格到此区域以上传',
                  // /nmpay/EducationalPayments/NmPage/Partners/EditPartner/index
                  // 179 - Digite o número do documento
                  'digite o numero do documento': '输入文件号码',
                  // 180 - Esse número de documento não é válido
                  'esse numero de documento nao e valido': '此文件号码无效',
                  // 181 - Digite o nome do parceiro!
                  'digite o nome do parceiro': '输入伙伴的名字',
                  // 182 - Digite o nome 2
                  'digite o nome 2': '输入名称2',
                  // 183 - Insira a validade!
                  'insira a validade': '输入有效期！',
                  // 184 - Parceiro criado com sucesso
                  'parceiro criado com sucesso': '成功创建合作伙伴',
                  // 185 - Occorreu um erro, tente novamente mais tarde
                  'ocorreu um erro, tente novamente mais tarde': '发生错误，请稍后再试',
                  // 186 - Taxa para 1 parcela
                  'taxa para 1 parcela': '分期付款的费用',
                  // 187 - Erro ao cadastrar parceiro
                  'erro ao cadastrar parceiro': '注册合作伙伴时出错',
                  // 188 - Parceiro cadastrado!
                  'parceiro cadastrado': '合作伙伴注册！',
                  // 189 - voltar
                  'voltar': '返回',
                  // 190 - Cadastrar Parceiro
                  'cadastrar parceiro': '注册合作伙伴',
                  // 191 - Parceiro
                  'parceiro': '合作伙伴',
                  // 192 - Nome Fantasia
                  'nome fantasia': '幻想名',
                  // 193 - Documento(CNPJ)
                  'documento(cnpj)': '文件（CNPJ）',
                  // 194 - Endereço
                  'endereco': '地址',
                  // 50 - Número
                  // 51 - Complemento
                  // 48 - CEP
                  // 53 - Cidade
                  // 54 - Estado
                  // 195 - Financeiro
                  'financeiro': '金融',
                  // 196 - Número do banco
                  'numero do banco': '银行号码',
                  // 197 - Agência Bancária
                  'agencia bancaria': '银行编号',
                  // 198 - Conta Bancária
                  'conta bancaria': '银行账户',
                  // 30 - Dígito
                  // 199 - Max. dias após venc
                  'max. dias apos venc': '到期后的最长天数',
                  // 200 - Multa
                  // 'multa': '罚款',
                  // 201 - Juros
                  'juros': '利息',
                  // 202 - Desconto
                  'desconto': '折扣',
                  // 203 - Mínimo de parcelas
                  'minimo de parcelas': '最低分期付款',
                  // 204 - Máximo de parcelas
                  'maximo de parcelas': '最大分期付款',
                  // 205 - Horário Limite de Pagamento
                  'horario limite de pagamento': '付款截止时间',
                  // 206 - Esconder Cobranças atrasadas?
                  'esconder cobrancas atrasadas': '隐藏过期收款',
                  // 207 - Taxas
                  'taxas': '费用',
                  // 208 - Enviar
                  'enviar': '提交',

                  // /nmpay/EducationalPayments/NmPage/Partners/CreatePartner/index
                  // 179 - Digite o número do documento.
                  // 180 - Esse número de documento não é válido
                  // 183 - Insira a validade!
                  // 209 - Parceiro atualizado com com sucesso
                  'parceiro atualizado com sucesso': '合作伙伴更新成功',
                  // 185 - Occorreu um erro, tente novamente mais tarde
                  // 186 - Taxa para 1 parcela
                  // 210 - Taxa para 2 parcelas
                  'taxa para 2 parcelas': '2期费用',
                  // 187 - Erro ao cadastrar parceiro
                  // 188 - Parceiro cadastrado!
                  // 189 - voltar
                  // 190 - Cadastrar Parceiro
                  // 211 - Parceiro editado!
                  'parceiro editado': '合作伙伴已编辑！',
                  // 212 - Edição de parceiro
                  'edicao de parceiro': '合作伙伴编辑中',
                  // 213 - Razão Social
                  'razao social': '公司名称',
                  // 192 - Nome Fantasia
                  // 193 - Documento(CNPJ)
                  // 194 - Endereço
                  // 50 - Número
                  // 51 - Complemento
                  // 48 - CEP
                  // 53 - Cidade
                  // 54 - Estado
                  // 196 - Número do banco
                  // 197 - Agência Bancária
                  // 198 - Conta Bancária
                  // 30 - Dígito
                  // 199 - Max. dias após venc

                  // /nmpay/EducationalPayments/NmPage/Partners/index
                  // 214 - Parceiros
                  'parceiros': '合作伙伴',
                  // 215 - Criar novo parceiro
                  'criar novo parceiro': '创建新伙伴',
                  // 216 - Nenhum parceiro foi cadastrado
                  'nenhum parceiro for cadastrado': '没有伙伴注册',
                  // 217 - Boleto avulso
                  'boleto avulso': '单独付款单',
                  // 191 - PARCEIRO
                  // 218 - ESTUDANTE
                  'estudante': '学生',
                  // 219 - EMAIL
                  // 'email': 'EMAIL',
                  // 220 - DATA DO VENCIMENTO
                  'data do vencimento': '截止日期',
                  // 221 - DATA DO PAGAMENTO
                  'data do pagamento': '付款日期',
                  // 222 - VALOR BASE
                  'valor base': '金额',
                  // 223 - VALOR PAGAMENTO
                  'valor pagamento': '付款金额',
                  // 224 - VALOR OPERAÇÃO
                  'valor operacao': '操作金额',
                  // 225 - METODO DE PAGAMENTO
                  'metodo de pagamento': '付款方式',
                  // 226 - PARCELAS
                  'parcelas': '分期付款',
                  // 89 - BOLETO
                  // 227 - Erro ao gerar planilha
                  'erro ao gerar planilha': '生成电子表格时出错',
                  // 228 - Copiar código de barras
                  'copiar codigo de barras': '复制条码',
                  // 35 - Linha Digitável
                  // 36 - Linha digitável copiada com sucesso
                  // 229 - NÃO PAGO
                  'nao pago': '未支付',

                  // nmpay/EducationalPayments/NmPage/index
                  // 230 - Mensalidades
                  'mensalidades': '月费',
                  // 231 - Todos
                  'todos': '全部',
                  // 232 - Pagos
                  'pagos': '已支付',
                  // 233 - Pendentes
                  'pendentes': '待办的',
                  // 234 - Atrasados
                  'atrasados': '延迟',
                  // 235 - Avulsos
                  'avulsos': '单独',
                  // 215 - Criar novo parceiro
                  // 236 - Lista de parceiros
                  'lista de parceiros': '合作伙伴名单',
                  // 214 - Parceiros
                  // 237 - Cadastrar mensalidades a partir de uma planilha
                  'cadastrar mensalidades a partir de uma planilha': '从电子表格中登记月费',
                  // 238 - Cadastrar mensalidades
                  'cadastrar mensalidades': '注册月费',
                  // 239 - Exportar excel dessa tabela
                  'exportar excel dessa tabela': '从此表导出excel',
                  // 240 - Exportar excel
                  'exportar excel': '导出excel',
                  // 131 - Depósito
                  // 241 - Nenhum pagamento para exibir
                  'nenhum pagamento para exibir': '没有支付显示',
                  // 242 - Nome do pagador
                  'nome do pagador': '付款人姓名',
                  // 243 - Documento do pagador
                  'documento do pagador': '付款人的文件',
                  // 244 - Faculdade
                  'faculdade': '大学',
                  // 44 - Data de vencimento
                  // 245 - Data de pagamento
                  'data de pagamento': '付款日期',
                  // 38 - Valor
                  // 226 - Parcelas
                  // 35 - Linha digitável
                  // 39 - Vencimento
                  // 100 – de:
                  // 101 - até:
                  // 246 - Pagamento
                  'pagamento': '支付',
                  // 214 - Parceiros
                  // 241 - Nenhum pagamento para exibir
                  // 218 - Estudante
                  // 244 - Faculdade
                  // 44 - Data de vencimento
                  // 245 - Data de pagamento
                  // 222 - Valor base
                  // 247 - Valor de pagamento
                  'valor de pagamento': '付款金额',
                  // 224 - Valor operação
                  // 248 - Método de pagamento
                  // 'metodo de pagamento': 'Método de pagamento',
                  // 226 - Parcelas

                  // nmpay/EducationalPayments/StudentPage/DetachedPayment/Payment/index
                  // 249 - Digite o número do cartão
                  'digite o numero do cartao': '输入卡号',
                  // 250 - O número do cartão deve ter 16 dígitos
                  'o numero do cartao debe ter 16 digitos': '卡号必须为 16 位数字',
                  // 251 - Digite o nome
                  'digite o nome': '输入名称',
                  // 252 - Digite o cpf
                  'digite o cpf': '输入cpf',
                  // 253 - Digite o mês de vencimento
                  'digite o mes de vencimento': '输入到期月份',
                  // 254 - Digite o ano de vencimento
                  'digite o ano de vencimento': '输入到期年份',
                  // 255 - Digite o código de segurança
                  'digite o codigo de seguranca': '输入安全码',
                  // 256 - Digite a quantidade de parcelas
                  'digite a quantidade de parcelas': '输入几次分期付款',
                  // 257 - Cartão inválido
                  'cartao invalido': '无效卡',
                  // 258 - Cartão vencido
                  'cartao vencido': '过期卡',
                  // 259 - Cobrança paga com sucesso
                  'cobranca paga com sucesso': '收款支付成功',
                  // 260 - Ocorreu um erro, tente novamente mais tarde
                  // 'ocorreu um erro, tente novamente mais tarde': 'Ocorreu um erro, tente novamente mais tarde',
                  // 189 - voltar
                  // 261 - Pagamento por cartão
                  'pagamento por cartao': '刷卡支付',
                  // 262 - Informações da cobrança
                  'informacoes da cobranca': '帐单信息',
                  // 263 - Beneficiário
                  'beneficiario': '受益人',
                  // 264 - Aluno/Responsável
                  'aluno/responsavel': '学生/负责人',
                  // 38 - Valor
                  // 226 - Parcelas
                  // 265 - (numero?) parcelas de (numero?)
                  '(numero?) parcelas de (numero?)': '（次数？）分期付款（次数？）',
                  // 39 - Vencimento
                  // 266 - Número do cartão
                  'numero do cartao': '卡号',
                  // 267 - Nome escrito no cartão
                  'nome escrito no cartao': '写在卡片上的名字',
                  // 268 - CPF do dono do cartão
                  'cpf do dono do cartao': '持卡人的CPF',
                  // 269 - Mês de vencimento
                  'mes de vencimento': '到期月份',
                  // 270 - Ano de vencimento
                  'ano de vencimento': '到期年份',
                  // 226 - Parcelas
                  // 271 - Pagar
                  'pagar': '支付',

                  // nmpay/EducationalPayments/studentPage/DetachedPayment/index
                  // 272 - Código de barras inválido
                  'codigo de barras invalido': '条码无效',
                  // 273 - Esse código de barras é inválido ou não pertence a uma instituição parceira
                  'esse codigo de barras e invalido ou...': '此条码无效或不属于合作机构',
                  // 274 - Pagamento de boleto avulso
                  'pagamento de boleto avulso': '支付单独的付款单',
                  // 275 - Digite o código de barras
                  'digite o codigo de barras': '输入条码',
                  // 276 - Continuar
                  'continuar': '继续',

                  // nmpay/EducationalPayments/studentPage/payment/index
                  // 249 - Digite o número do cartão
                  // 250 - O número do cartão deve ter 16 dígitos
                  // 251 - Digite o nome
                  // 252 - Digite o cpf
                  // 253 - Digite o mês de vencimento
                  // 253 - Digite o mês de vencimento
                  // 254 - Digite o ano de vencimento
                  // 254 - Digite o ano de vencimento
                  // 255 - Digite o código de segurança
                  // 256 - Digite a quantidade de parcelas
                  // 256 - Digite a quantidade de parcelas
                  // 257 - Cartão inválido
                  // 258 - Cartão vencido
                  // 259 - Cobrança paga com sucesso
                  // 277 - Essa cobrança já foi paga
                  'essa cobranca ja foi paga': '此费用已支付',
                  // 185 - Occorreu um erro, tente novamente mais tarde
                  // 261 - Pagamento por cartão
                  // 262 - Informações da cobrança
                  // 278 - Instituição
                  'instituicao': '机构',
                  // 279 - Descrição
                  'descricao': '描述',
                  // 222 - Valor base
                  // 226 - Parcelas
                  // 266 - Número do cartão
                  // 267 - Nome escrito no cartão
                  // 268 - CPF do dono do cartão
                  // 269 - Mês de vencimento
                  // 270 - Ano de vencimento
                  // 226 - Parcelas
                  // 271 - Pagar

                  // nmpay/EducationalPayments/studentPage/index
                  // 280 - Mensalidade paga com sucesso utilizando o saldo da sua conta
                  'mensalidade paga com sucesso utilizando o saldo da sua conta': '使用您的帐户余额支付月费成功',
                  // 281 - Ocorreu um erro ao pagar utilizando o saldo da conta
                  'ocorreu um erro ao pagar utilizando o saldo da conta': '使用帐户余额付款时出错',
                  // 228 - Copiar código de barras
                  // 35 - Linha Digitável
                  // 36 - Linha digitável copiada com sucesso
                  // 282 - Visualizar o recibo do pagamento
                  'visualizar o recibo do pagamento': '查看付款收据',
                  // 283 - Visualizar recibo
                  'visualizar recibo': '查看收据',
                  // 229 - NÃO PAGO
                  // 284 - Realizar pagamento com cartão de crédito
                  'realizar pagamento com cartao de credito': '用信用卡付款',
                  // 285 - Pagar com cartão
                  'pagar com cartao': '刷卡支付',
                  // 286 - Realizar pagamento com saldo da sua conta NMPay
                  'realizar pagamento com saldo da sua conta nmpay': '使用您的 NMPay 帐户余额付款',
                  // 287 - Pagar com saldo
                  'pagar com saldo': '用余额支付',
                  // 288 - Visualizar o boleto para pagamento
                  'visualizar o boleto para pagamento': '查看付款单',
                  // 289 - Visualizar boleto
                  'visualizar boleto': '查看付款单',
                  // 282 - Visualizar o recibo do pagamento
                  // 283 - Visualizar recibo
                  // 230 - Mensalidades
                  // 232 - Pagos
                  // 233 - Pendentes
                  // 234 - Atrasados
                  // 235 - Avulsos
                  // 290 - Realizar pagamento avulso de boleto
                  'realizar pagamento avulso de boleto': '单独支付付款单',
                  // 291 - Pagar boleto avulso
                  'pagar boleto avulso': '单独支付付款单',
                  // 131 - Depósito
                  // 241 - Nenhum pagamento para exibir
                  // 242 - Nome do pagador
                  // 244 - Faculdade
                  // 44 - Data de vencimento
                  // 245 - Data de pagamento
                  // 38 - Valor
                  // 226 - Parcelas
                  // 35 - Linha digitável
                  // 241 - Nenhum pagamento para exibir
                  // 279 - Descrição
                  // 44 - Data de vencimento
                  // 245 - Data de pagamento
                  // 222 - Valor base
                  // 247 - Valor de pagamento
                  // 292 - Valor pago
                  'valor pago': '支付金额',

                  // nmpay/EducationalPayments/index
                  // 293 - Aluno
                  'aluno': '学生',
                  // 244 - Faculdade

                  //       // Payment
                  //       Payment/index
                  //       294 - A linha digitável deve ter no mínimo 44 números
                  'a linha digitavel deve ter no minimo 44 numeros': '输入的行必须至少有 44 个数字',
                  // 295 - Não é possível realizar o pagamento. Titulo inválido ou já pago
                  'nao e possivel realizar o pagamento. titulo invalido ou ja pago': '无法付款。付款单无效或已付过',
                  // 122 - O Valor é obrigatório
                  // 296 - Vencimento é obrigatório
                  'vencimento e obrigatorio': '到期日期必填',
                  // 122 - O Valor é obrigatório
                  // 297 - Pagamento efetuado com sucesso
                  'pagamento efetuado com sucesso': '付款成功',
                  // 298 - Erro no pagamento
                  'erro no pagamento': '付款错误',
                  // 299 - Pagamento efetuado!
                  'pagamento efetuado': '付款成功',
                  // 300 - Pagamentos
                  'pagamentos': '付款',
                  // 275 - Digite o código de barras:
                  // 35 - Linha Digitável
                  // 276 - Continuar
                  // 301 - Confira os dados do pagamento
                  'confira os dados do pagamento': '检查付款细节',
                  // 302 - Benefeciário
                  // 'beneficiario': 'Benefeciário',
                  // 303 - Benefeciário final
                  'beneficiario final': '最终受益人',
                  // 304 - Cobrança para
                  'cobranca para': '计费',
                  // 305 - Vence em
                  'vence em': '到期',
                  // 306 - Valor do documento
                  'valor do documento': '文档金额',
                  // 35 - Linha Digitável
                  // 200 - Multa
                  // 201 - Juros
                  // 307 - Valor final
                  'valor final': '最终金额',
                  // 39 - vencimento
                  // 271 - Pagar
                  // 302 - Benefeciário
                  // 304 - Cobrança para
                  // 305 - Vence em
                  // 35 - Linha Digitável
                  // 307 - Valor final
                  // 271 - Pagar

                  //       // Register
                  //       /Register/PFUser/index.jsx
                  //       308 - Dados de Cadastro
                  'dados de cadastro': '注册信息',
                  // 136 - Não foi possível validar o documento enviado
                  // 137 - Para continuar envie outro documento
                  // 69 - O documento é obrigatório
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  'o nome deve ter no maximo 100 caracteres': '名称不得超过 100 个字符',
                  // 68 - O nome é obrigatório
                  // 310 - A data de nascimento é obrigatória
                  'a data de nascimento e obrigatoria': '出生日期必填',
                  // 311 - Data inválida
                  'data invalida': '无效日期',
                  // 312 - O nome da mãe é obrigatório
                  'o nome da mae e obrigatorio': '母亲的名字必填',
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 313 - A declaração de renda é obrigatória WARNING
                  'a declaracao de renda e obrigatoria': '损益表必填',
                  // 314 - CPF inválido
                  'cpf invalido': '无效的 CPF',
                  // 315 - Já existe uma conta associada a este documento
                  'ja existe uma conta associada a este documento': '已有一个与此资料关联的帐户',
                  // 316 - Para continuar faça Login
                  'para continuar faca login': '继续，请登录',
                  // 317 - A senha é obrigatória
                  'a senha e obrigatoria': '密码必填项',
                  // 318 - A senha deve ter, no mínimo, 8 caracteres
                  'a senha deve ter no minimo 8 caracteres': '密码必须至少有 8 个字符',
                  // 319 - As senhas não conferem
                  'as senhas nao conferem': '密码不匹配',
                  // 320 - A confirmação de senha é obrigatória
                  'A confirmação de senha é obrigatória': '密码确认必填',
                  // 308 - Dados de cadastro
                  // 219 - EMAIL
                  // 321 - O texto deve ter no máximo 50 caracteres
                  'o texto deve ter no maximo 50 caracteres': '文本最多 50 个字符',
                  // 322 - Por favor, preencha esse campo
                  'por favor preencha esse campo': '请填写此处',
                  // 323 - E-mail inválido
                  'email invalido': '无效的电子邮件',
                  // 82 - O e-mail é obrigatório
                  // 323 - E-mail inválido
                  // 324 - Já existe uma conta associada a este e-mail
                  'ja existe uma conta associada a este email': '已有一个与此电子邮件关联的帐户',
                  // 316 - Para continuar faça Login
                  // 325 - PHONE
                  'phone': '电话',
                  // 326 - O celular é obrigatório
                  'o celular e obrigatorio': '手机号必填',
                  // 327 - Insira um telefone válido
                  'insira um telefone valido': '输入有效的电话号码',
                  // 48 - CEP
                  // 71 - CEP é obrigatório
                  // 328 - ADDRESS
                  'address': '地址',
                  // 72 - Logradouro é obrigatório
                  // 77 - Número é obrigatório
                  // 76 - Bairro é obrigatório
                  // 329 - Cidade é obrigatório
                  'cidade e obrigatorio': '城市必填项',
                  // 330 - O Estado precisa ter 2 dígitos
                  'o estado precisa ter 2 digitos': '州必须有 2 位字（SP,MG,ES..）',
                  // 74 - Estado é obrigatório
                  // 331 - N/A
                  'n/a': 'N/A',
                  // 332 - SELFIE
                  'selfie': '自拍',
                  // 151 - Tipo de documento é obrigatório
                  // 333 - FRONT
                  'front': '前面',
                  // 351 - Apenas números
                  'apenas numeros': '仅数字',
                  // 317 - A senha é obrigatória
                  // 'a senha e obrigatoria': 'A senha é obrigatória',
                  // 319 - As senhas não conferem
                  // 'as senhas nao conferem': 'As senhas não conferem',
                  // 320 - A confirmação de senha é obrigatória
                  'a confirmacao de senha e obrigatoria': '密码确认必填',
                  // 360 - CPF
                  'cpf': 'CPF',
                  // 26 - Nome Completo
                  // 334 - Data de Nascimento
                  'data de nascimento': '出生日期',
                  // 335 - Nome completo da mãe
                  'nome completo da mae': '母亲的全名',
                  // 336 - Você se declara Pessoa Exposta Politicamente (PEP)?
                  'voce se declara pessoa exposta politicamente': '您是否宣布自己是政治公众人物 (PEP)？',
                  // 337 - Por favor informe a sua renda mensal em Reais
                  'por favor informe a sua renda mensal em reais': '请输入您的月收入',
                  // 141 - Próximo
                  // 338 - Crie uma senha de acesso
                  'crie uma senha de acesso': '创建密码',
                  // 339 - Dica: use oito caracteres combinando letras, números e símbolos para acessar o banco
                  'dica use oito caracteres combinando letras...': '提示：使用组合字母、数字和符号的八个字符来登入账号',
                  // 340 - Crie uma senha
                  'crie uma senha': '创建密码',
                  // 341 - Confirme a senha
                  'confirme a senha': '确认密码',
                  // 141 - Próximo
                  // 358 - Como você conheceu o (nome)?
                  'como voce conheceu o ': '您是怎么认识的',
                  // 141 - Próximo
                  // 357 - Digite seu e-mail
                  'digite seu email': '输入您的电子邮件',
                  // 55 - E-mail
                  // 141 - Próximo
                  // 359 - Digite o seu celular
                  'digite o seu celular': '输入你的手机',
                  // 56 - Celular
                  // 141 - Próximo
                  // 361 - Digite o seu CEP
                  'digite o seu cep': '输入您的邮政编码',
                  // 48 - CEP
                  // 141 - Próximo
                  // 362 - Complete o seu Endereço
                  'complete o seu endereco': '填写您的地址',
                  // 48 - CEP
                  // 49 - Logradouro
                  // 50 - Número
                  // 51 - Complemento
                  // 52 - Bairro
                  // 53 - Cidade
                  // 54 - Estado
                  // 141 - Próximo
                  // 138 - Envie sua selfie
                  // 139 - A foto precisa mostrar seu rosto inteiro e de olhos abertos
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 140 - Selecione um documento
                  // 363 - Documento
                  'documento': '文件',
                  // 364 - Carteira de Identidade
                  'carteira de identidade': '身份证',
                  // 365 - Carteira de Motorista
                  'carteira de motorista': '驾驶执照',
                  // 141 - Próximo
                  // 142 - Envie foto da FRENTE da sua CNH
                  // 143 - Envie foto da FRENTE do seu RG
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 146 - Envie foto do VERSO da sua CNH
                  // 147 - Envie foto do VERSO do seu RG
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 366 - Crie uma senha de 4 dígitos para sua conta e cartão
                  'crie uma senha de 4 digitos...': '为您的帐户和卡创建一个 4 位数的密码',
                  // 367 - use apenas números
                  'use apenas numeros': '只使用数字',
                  // 340 - Crie uma senha
                  // 341 - Confirme a senha
                  // 342 - Criar conta
                  'criar conta': '创建帐户',
                  // 343 - Parabéns, sua conta foi aberta!
                  'parabens sua conta foi aberta': '恭喜，您的账户已开通！',
                  // 344 - Acessar
                  'acessar': '登录',
                  // 345 - Erro ao criar conta
                  'erro ao criar conta': '创建帐户时出错',
                  // 346 - Usuário criado com sucesso
                  'usuario criado com sucesso': '用户创建成功',

                  // /Register/PJUser/index
                  // 308 - Dados de Cadastro
                  // 136 - Não foi possível validar o documento enviado
                  // 137 - Para continuar envie outro documento
                  // 69 - O documento é obrigatório
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 347 - O nome da empresa é obrigatório
                  'o nome da empresa e obrigatorio': '公司名称必填',
                  // 348 - O número de funcionários é obrigatório
                  'o numero de funcionarios e obrigatorio': '员工人数必填',
                  // 349 - O faturamento é obrigatório
                  'o faturamento e obrigatorio': '请输入公司的月收入',
                  // 350 - CNPJ Inválido
                  'cnpj invalido': 'CNPJ无效',
                  // 315 - Já existe uma conta associada a este documento
                  // 316 - Para continuar faça Login
                  // 69 - O documento é obrigatório
                  // 68 - O nome é obrigatório
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 310 - A data de nascimento é obrigatória
                  // 311 - Data inválida
                  // 312 - O nome da mãe é obrigatório
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 314 - CPF inválido
                  // 317 - A senha é obrigatória
                  // 318 - A senha deve ter, no mínimo, 8 caracteres
                  // 319 - As senhas não conferem
                  // 320 - A confirmação de senha é obrigatória
                  // 308 - Dados de cadastro
                  // 219 - EMAIL
                  // 321 - O texto deve ter no máximo 50 caracteres
                  // 322 - Por favor, preencha esse campo
                  // 323 - E-mail inválido
                  // 82 - O e-mail é obrigatório
                  // 323 - E-mail inválido
                  // 324 - Já existe uma conta associada a este e-mail
                  // 316 - Para continuar faça Login
                  // 326 - O celular é obrigatório
                  // 327 - Insira um telefone válido
                  // 71- CEP é obrigatório
                  // 72 - Logradouro é obrigatório
                  // 77 - Número é obrigatório
                  // 76 - Bairro é obrigatório
                  // 329 - Cidade é obrigatório
                  // 330 - O Estado precisa ter 2 dígitos
                  // 74 - Estado é obrigatório
                  // 151 - Tipo de documento é obrigatório
                  // 351 - Apenas números
                  // 317 - A senha é obrigatória
                  // 319 - As senhas não conferem
                  // 320 - A confirmação de senha é obrigatória
                  // 351 - CNPJ
                  'cnpj': 'CNPJ',
                  // 352 - Nome da Empresa
                  'nome da empresa': '公司名称',
                  // 353 - Número de funcionários
                  'numero de funcionarios': '员工人数',
                  // 354 - Faturamento anual (R$)
                  'faturamento anual': '请输入您的年收入（R$）',
                  // 141 – Próximo
                  // 355 - Insira as informações dos sócios, representantes, mandatários ou prepostos autorizados da empresa
                  'insira as informacoes dos socios, representantes...': '输入公司合伙人、代表、代理人或授权代理人的信息',

                  // 356 - Insira as informações do sócio, representante, mandatário ou preposto autorizado da empresa
                  'insira as iformacoes do socio, representante...': '输入公司合伙人、代表、代理人或授权代理人的信息',

                  // 26 - Nome Completo
                  // 334 - Data de Nascimento
                  // 335 - Nome completo da mãe
                  // 141 - Próximo
                  // 338 - Crie uma senha de acesso
                  // 339 - Dica: use oito caracteres combinando letras, números e símbolos para acessar o banco
                  // 340 - Crie uma senha
                  // 341 - Confirme a senha
                  // 141 - Próximo
                  // 358 - Como você conheceu o (nome)?
                  // 141 - Próximo
                  // 357 - Digite seu e-mail
                  // 55 - E-mail
                  // 141 - Próximo
                  // 359 - Digite o seu celular
                  // 56 - Celular
                  // 141 - Próximo
                  // 361 - Digite o seu CEP
                  // 141 - Próximo
                  // 362 - Complete o seu Endereço
                  // 48 - CEP
                  // 49 - Logradouro
                  // 50 - Número
                  // 51 - Complemento
                  // 52 - Bairro
                  // 53 - Cidade
                  // 54 - Estado
                  // 141 - Próximo
                  // 138 - Envie sua selfie
                  // 139 - A foto precisa mostrar seu rosto inteiro e de olhos abertos
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 140 - Selecione um documento
                  // 363 - Documento
                  // 364 - Carteira de Identidade
                  // 365 - Carteira de Motorista
                  // 141 - Próximo
                  // 142 - Envie foto da FRENTE da sua CNH
                  // 143 - Envie foto da FRENTE do seu RG
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 146 - Envie foto do VERSO da sua CNH
                  // 147 - Envie foto do VERSO do seu RG
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 – Próximo

                  // 148 - Envie o documento principal de identificação/constituição da empresa. Ex: Contrato Social

                  // 363 - Documento
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 366 - Crie uma senha de 4 dígitos para sua conta e cartão
                  // 367 - use apenas números
                  // 340 - Crie uma senha
                  // 341 - Confirme a senha
                  // 342 - Criar conta
                  // 343 - Parabéns, sua conta foi aberta!
                  // 344 - Acessar
                  // 345 - Erro ao criar conta
                  // 346 - Usuário criado com sucesso

                  // /Register/index
                  // 368 - O documento não é válido
                  'o documento nao e valido': '文件无效',
                  // 369 - Digite seu CPF/CNPJ para se cadastrar
                  'digite seu cpf/cnpj para se cadastrar': '输入您的 CPF/CNPJ 进行注册',
                  // 25 – CPF / CNPJ
                  // 141 - Próximo

                  //       // Reset4DigitPassword
                  //       /Reset4DigitPassword/index
                  //       370 - O código é obrigatório
                  'o codigo e obrigatorio': '代码必填',
                  // 371 - A senha é obrigatória e deve conter 4 números
                  'a senha e obrigatoria e deve conter 4 numeros': '密码必填，必须包含 4 个数字',
                  // 372 - A senha deve conter apenas números e quatro dígitos!
                  'a senha deve conter apenas numeros e quatro digitos': '密码只能包含数字和四位数字',
                  // 373 - As senhas devem ser idênticas!
                  'as senhas devem ser identicas': '密码必须相同！',
                  // 374 - Erro ao resetar senha
                  'erro ao resetar senha': '重置密码时出错',
                  // 375 - Operação concluída
                  'operacao concluida': '操作完成',
                  // 376 - Resete a sua senha de quatro dígitos
                  'resete a sua senha de quatro digitos': '重置您的四位数密码',
                  // 377 - Enviar SMS
                  'enviar sms': '发送短信',
                  // 378 - Um código foi enviado via SMS para o celular vinculado a esta conta
                  'um codigo foi enviado via sms para o celular...': '认证码已通过短信发送到与此帐户关联的手机',
                  // 379 - Insira o código enviado por SMS
                  'insira o codigo enviado por sms': '输入短信发送的认证码',
                  // 380 - Insira sua nova senha
                  'insira sua nova senha': '输入您的新密码',
                  // 381 - Confirme a nova senha
                  'confirme a nova senha': '确认新密码',
                  // 390 - Resetar senha
                  'resetar senha': '重置密码',

                  //       // ResetUserPassword
                  //       /ResetUserPassword/index
                  //       391 - A senha deve conter no mínimo 8 caracteres
                  'a senha deve conter no minimo 8 caracteres': '密码必须至少包含 8 个字符',
                  // 320 - A confirmação de senha é obrigatória
                  // 392 - A senha e a confirmação não conferem
                  'a senha e a confirmacao nao conferem': '密码和确认不匹配',
                  // 393 - A sua conta foi desbloqueada com sucesso e sua senha alterada!
                  'a sua conta foi desbloqueada com sucesso e sua senha alterada': '您的帐户已成功解锁而且密码已更改！',
                  // 394 - Sua senha foi alterada com sucesso
                  'sua senha foi alterada com sucesso': '已成功更改密码',
                  // 395 - Não foi possível alterar sua senha. Tente novamente
                  'nao foi possivel alterar sua senha. tente novamente': '无法更改您的密码。再试一次',
                  // 396 - Erro na validação
                  'erro na validacao': '验证错误',
                  // 397 - Sucesso!
                  'sucesso': '成功！',
                  // 398 - Olá, (nome)!
                  'ola': '你好， ',
                  // 399 - Altere sua senha abaixo para desbloquear sua conta
                  'altere sua senha abaixo para desbloquear sua conta': '在下面更改您的密码以解锁您的帐户',
                  // 398 - Olá, (nome)!
                  // 400 - Altere sua senha abaixo
                  'altere sua senha abaixo': '以下更改您的密码',
                  // 401 - Sua nova senha
                  'sua nova senha': '您的新密码',
                  // 381 - Confirme a nova senha
                  // 9 - Confirmar

                  //       // Settings
                  //       /Settings/AdjustLimitRequestModal/index
                  //       402 - Valor mínimo de R$?0,00
                  'valor minimo de...': '最小金额为R$？0.00',
                  // 403 - Valor máximo de R$?0,00
                  'valor maximo de...': '最大金额为R$？0.00',
                  // 404 - Limite obrigatório
                  'limite obrigatorio': '账户限制必填',
                  // 405 - Por favor preencha o campo de justificativa
                  'por favor preencha o campo de justificativa': '请填写理由',
                  // 406 - Documento obrigatório
                  'documento obrigatorio': '强制性文件',
                  // 407 - Por favor anexe um documento de justificativa
                  'por favor anexe um documento de justificativa': '请附上证明文件',
                  // 408 - Termo de autorização obrigatório
                  'termo de autorizacao obrigatorio': '必填授权书',
                  // 409 - Por favor anexe o termo de autorização
                  'por favor anexe o termo de autorizacao': '请附上授权条款',
                  // 410 - Solicitação realizada com sucesso
                  'solicitacao realizada com sucesso': '请求执行成功',
                  // 406 - Documento obrigatório
                  // 407 - Por favor anexe um documento de justificativa
                  // 408 - Termo de autorização obrigatório
                  // 409 - Por favor anexe o termo de autorização
                  // 260 - Ocorreu um erro, tente novamente mais tarde
                  // 411 - Valor desejado
                  'valor desejado': '期望金额',
                  // 412 - Seu limite atual é de R$10,00
                  'seu limite atual e de...': '您当前的额度为',
                  // 413 - Digite sua resposta
                  'digite sua resposta': '输入您的答案',
                  // 414 - Justificativa
                  'justificativa': '理由',
                  // 415 - Anexar documento
                  'anexar documento': '附加文件',

                  // 416 - Por exemplo: Contracheque, comprovante de renda, declaração de IR ou outro documento que justifique a transação
                  'por exemplo: contracheque, comprovante de renda...': '例如：损益表、所得税申报表或其他证明交易合理的文件',
                  // 417 - Por exemplo: Relação de faturamento, declaração de IR ou outro documento que justifique a transação
                  'por exemplo: relacao de faturamento, declaracao...': '例如：账单清单、所得税申报表或其他文件 证明交易的合理性',

                  // 418 - Anexar o termo de autorização de aumento de limite
                  'anexar o termo de autorizacao de aumento de limite': '附上限额增加授权条款',

                  // 419 - Baixe o modelo do documento de termo de autorização clicando no link abaixo, e depois de preenche-lo anexe aqui
                  'baixe o modelo do documento de termo de autorizacao clicando no...': '点击下方链接下载授权书文件模板，填写后附在此处',
                  // 420 - Clique aqui para baixar o documento
                  'clique aqui para baixar o documento': '单击此处下载文档',
                  // 421 - Enviar pedido
                  'enviar pedido': '提交订单',

                  // /Settings/AnalysisRequestModal/index
                  // 422 - Aguarde, sua solicitação está em análise
                  'aguarde, sua solicitacao esta em analise': '请稍候，您的请求正在分析中',
                  // 423 - Notificaremos por e-mail quando o status do pedido for alterado
                  'notificaremos por email quando o status do pedido for alterado': '当订单状态发生变化时，我们会通过电子邮件通知您',
                  // 424 - Entendi
                  'entendi': '明白',

                  // /Settings/ApprovedRequestModal/index
                  // 425 - Seu ajuste de limite foi aprovado!
                  'seu ajuste de limite foi aprovado': '您的限额调整已批准！',
                  // 424 - Entendi

                  // /Settings/RejectedRequestModal/index
                  // 426 - Infelizmente, ainda não podemos aumentar seu limite
                  'infelizmente, ainda nao podemos aumentar seu limite': '很遗憾，我们暂时不能提高您的限额',
                  // 427 - Em breve você poderá tentar novamente
                  'em breve voce podera tentar novamente': '很快你就可以再试一次',

                  // /Settings/index
                  // 428 - Ajustar limite
                  'ajustar limite': '设置限制',
                  // 429 - Em análise
                  'em analise': '正在审核中',
                  // 430 - Pedido recusado
                  'pedido recusado': '请求被拒绝',
                  // 431 - Pedido aprovado
                  'pedido aprovado': '请求已批准',
                  // 432 - Tarifa por emissão de boleto
                  'tarifa pro emissao de boleto': '发行付款单的费用',
                  // 433 - R$?0,00 por boleto
                  '...por boleto': '每张付款单为',
                  // 434 - Ativo
                  'ativo': '已启用',
                  // 435 - Tarifa por TED/DOC
                  'tarifa por ted/doc': 'TED/DOC的 费用',
                  // 436 - R$?0,00 por transação
                  '...por transacao': '每笔交易',
                  // 437 - Tarifa por TEF
                  'tarifa por tef': 'TEF费用',
                  // 436 - R$?0,00 por transação
                  // 434 - Ativo
                  // 438 - Limite disponível
                  'limite disponivel': '可用额度',
                  // 436 - R$?0,00 por transação
                  // 439 - Por favor reenvie os documentos
                  'por favor reenvie os documentos': '请重新发送文件',
                  // 440 - Configurações
                  'configuracoes': '设置',
                  // 1 - Dados Cadastrais
                  // 114 - Nome
                  // 55 - E-mail
                  // 441 - Segurança
                  'seguranca': '安全',
                  // 442 - Alterar senha de 4 dígitos
                  'alterar senha de 4 digitos': '更改 4 位密码',
                  // 2 - Atualizar documentos
                  // 443 - Valores e tarifas
                  'valores e tarifas': '价值和费用',
                  // 117 - Tipo
                  // 38 - Valor
                  // 118 - Status

                  //       // Store
                  //       /Store/index
                  //       444 - O nome da sua loja é obrigatório
                  'o nome da sua loja e obrigatorio': '您的商店名称是',
                  // 444 - O nome da sua loja é obrigatório
                  // 445 - O nome da loja deve possuir no máximo 40 caracteres
                  'o nome da loja deve possuir no maximo 40 caracteres': '商店名称不得超过 40 个字符',
                  // 446 - A senha deve ter pelo menos 8 dígitos
                  'a senha deve ter pelo menos 8 digitos': '密码长度必须至少为 8 位',
                  // 447 - A senha é obrigatória e deve ter pelo menos 8 dígitos
                  'a senha e obrigatoria e deve ter pelo menos 8 digitos': '密码是必填项，而且至少为 8 位字符',
                  // 448 - Confirme sua senha!
                  'confirme sua senha': '确认您的密码！',
                  // 373 - As senhas devem ser idênticas!
                  // 84 - O telefone é obrigatório
                  // 449 - O telefone deve ser no formato (XX) 9XXXX-XXXX!
                  'o telefone deve ser no formato...': '电话必须以 (XX) 9XXXX-XXXX 格式！',
                  // 450 - A descrição da loja é obrigatória e em forma de texto
                  'a descricao da loja e obrigatoria e em forma de texto': '商店描述是必填的，以文字形式',
                  // 451 - A descrição da loja é obrigatória
                  'a descricao da loja e obrigatoria': '商店描述是必填',
                  // 452 - A descrição da loja deve possuir no máximo 64 caracteres!
                  'a descricao da loja debe possuir no maximo 64 caracteres': '商店描述最多可有 64 个字符！',
                  // 453 - Insira um pagamento mínimo
                  'insira um pagamento minimo': '输入最低付款额度',
                  // 454 - A rua do estabelecimento é obrigatória
                  'a rua do estabelecimento e obrigatoria': '公司地址必填',
                  // 455 - O bairro do estabelecimento é obrigatório
                  'o bairro do estabelecimento e obrigatorio': '公司地区必填',
                  // 456 - O número do estabelecimento é obrigatório
                  'o numero do estabelecimento e obrigatorio': '公司号码必填',
                  // 457 - Numero possui formato errado
                  'numero possui formato errado': '数字格式错误',
                  // 458 - Numero possui pelo menos um dígito, apenas números!
                  'numero possui pelo menos um digito, apenas numeros': '数字至少有一位，只数允许数字！',
                  // 459 - O complemento deve ser um texto
                  'o complemento deve ser um texto': '附加组件必须以文字形式',
                  // 460 - O ceMultap deve ser um texto
                  'o cep deve ser um texto': '邮政编码必须以文字形式',
                  // 461 - O CEP do estabelecimento é obrigatório
                  'o cep do estabelecimento e obrigatorio': '公司CEP必填',
                  // 462 - O cep deve estar no formato XXXXX-XXX
                  'o cep deve estar no formato...': '邮政编码必须以 XXXXX-XXX 格式',
                  // 75 - A cidade é obrigatória
                  // 463 - A cidade do estabelecimento é obrigatória
                  'a cidade do estabelecimento e obrigatoria': '公司城市必填',
                  // 464 - A cidade deve ser descrita apenas por texto
                  'a cidade deve ser descrita apenas por texto': '城市只能用文字描述',
                  // 74 - O estado é obrigatório
                  // 465 - O estado do estabelecimento é obrigatório
                  'o estado do estabelecimento e obrigatorio': '公司州必填',
                  // 466 - O estado deve ser descrito apenas por texto
                  'o estado deve ser descrito apenas por texto': '州须仅由文本描述',
                  // 467 - Brasil
                  'brasil': '巴西',
                  // 468 - Loja cadastrada com sucesso!
                  'loja cadastrada com sucesso': '店铺注册成功！',
                  // 469 - Cadastro inválido. Verifique se todos os campos foram preenchidos corretamente
                  'cadastro invalido. verifique se todos os campos...': '无效注册。检查所有字段是否已正确填写',
                  // 470 - Erro ao cadastrar a loja
                  'erro ao cadastrar a loja': '注册商店时出错',
                  // 468 - Loja cadastrada com sucesso!
                  // 471 - Loja online
                  'loja online': '网上商店',
                  // 472 - Acessar a sua loja
                  'acessar a sua loja': '登入您的商店',
                  // 473 - Crie a sua loja virtual
                  'crie a sua loja virtual': '创建您的网上商店',
                  // 474 - Escolha o nome da sua loja
                  'escolha o nome da sua loja': '选择您的商店名称',
                  // 475 - Telefone para contato
                  'telefone para contato': '联系电话',
                  // 476 - Descrição da loja
                  'descricao da loja': '店铺描述',
                  // 477 - Compra mínima(R$)
                  'compra minima...': '最低购买量 (R$)',
                  // 338 - Crie uma senha de acesso
                  // 341 - Confirme a senha
                  // 48 - CEP
                  // 478 - Rua
                  'rua': '路',
                  // 52 - Bairro
                  // 50 - Número
                  // 51 - Complemento
                  // 53 - Cidade
                  // 54 - Estado
                  // 479 - Confirmar cadastro
                  'confirmar cadastro': '确认注册',

                  //       // Support
                  //       /Support/index
                  //       480 - atendimento
                  'atendimento': '服务',
                  // 481 - Logo
                  'logo': '标志',
                  // 482 - Para entrar em contato, utilize os meios de comunicação abaixo
                  'para entrar em contato, utilize...': '联系，请使用一下通讯方式',
                  // 55 - E-mail
                  // 483 - Telefone
                  'telefone': '电话',
                  // 484 - Whatsapp
                  'whatsapp': 'whatsaap号码',
                  // 485 - Clique aqui para enviar uma mensagem
                  'clique aqui para enviar uma mensagem': '点击这里发送消息',

                  //       // Ted
                  //       /Ted/index
                  //       122 - O valor é obrigatório
                  // 69 - O documento é obrigatório
                  // 486 - O documento deve ter 11 ou 14 dígitos
                  'o documento deve ter 11 ou 14 digitos': '文件必须有 11 位或 14 位字符',
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 68 - O nome é obrigatório
                  // 487 - O banco é obrigatório
                  'o banco e obrigatorio': '银行是必填项',
                  // 488 - A agência precisa ter 4 digitos
                  'a agencia precisa ter 4 digitos': '银行编号必须有4 位数字',
                  // 489 - Apenas números são aceitos
                  'apenas numeros sao aceitos': '只接受数字',
                  // 490 - A agência é obrigatória
                  'a agencia e obrigatoria': '银行编号是必填项',
                  // 489 - Apenas números são aceitos
                  // 491 - A conta é obrigatória
                  'a conta e obrigatoria': '帐户是必填项',
                  // 489 - Apenas números são aceitos
                  // 67 - O dígito é obrigatório
                  // 492 - Sua transferência foi agendada com sucesso
                  'sua transferencia foi agendada com sucesso': '您的转账已安排成功',
                  // 493 - Transferência realizada com sucesso
                  'transferencia realizada com sucesso': '转账成功执行',
                  // 494 - Operação permitida
                  'operacao permitida': '允许操作',
                  // 495 - Excluir Favorito
                  'excluir favorito': '删除收藏夹',
                  // 496 - Tem certeza que deseja excluir esse favorito?
                  'tem certeza que deseja excluir esse favorito': '您确定要删除此收藏夹 吗？',
                  // 497 - Sim
                  'sim': '是',
                  // 498 - Não
                  'nao': '否',
                  // 499 - Favorito removido!
                  'favorito removido': '收藏夹已删除！',
                  // 500 - Favorito removido com sucesso
                  'favorito removido com sucesso': '已成功删除收藏夹',
                  // 501 - Transferências
                  'transferencias': '转账',
                  // 502 - Erro na transferência
                  'erro na transferencia': '传输错误',
                  // 503 - Transferência efetuada
                  'transferencia efetuada': '传输成功',
                  // 504 - Agendar transferência
                  'agendar transferencia': '安排转账',
                  // 505 - Deseja agendar essa transferência para o próximo dia útil?
                  'deseja agendar essa transferencia para o proximo dia util': '您想在下一个工作日安排此转账吗？',
                  // 506 - Agendar
                  'agendar': '安排',
                  // 507 - Não agendar
                  'nao agendar': '不安排',
                  // 508 - Nova transferência
                  'nova transferencia': '新转账',
                  // 509 - Minhas contas
                  'minhas contas': '我的账户',
                  // 145 - Excluir
                  // 510 - Favoritos
                  'favoritos': '收藏夹',
                  // 511 - Nenhum favorito para exibir
                  'nenhum favorito para exibir': '没有可显示的收藏',
                  // 114 - Nome
                  // 27 - Banco
                  // 28 - Agência
                  // 29 - Conta
                  // 512 - Ações
                  'acoes': '执行',
                  // 513 - Transferir para este contato
                  'transferir para este contato': '转账到这个联系人',
                  // 495 - Excluir favorito
                  // 26 - Nome completo
                  // 27 - Banco
                  // 28 - Agência
                  // 29 - Conta
                  // 30 - Dígito
                  // 38 - Valor
                  // 514 - Conta corrente
                  'conta corrente': '运营账户',
                  // 515 - Conta poupança
                  'conta poupanca': ' 储蓄账户',
                  // 516 - Adicionar aos favoritos
                  'adicional aos favoritos': '添加到收藏夹',
                  // 517 - Transferir
                  'transferir': '转帐',

                  //       // TokenLogin

                  //       // Transaction
                  //       /Transaction/Receipt/types/avulsePaymentNmPay
                  //       518 - Comprovante de pagamento de boleto avulso
                  'comprovante de pagamento de boleto avulso': '单独付款单证明',
                  // 519 - Informações do pagamento
                  'informacoes do pagamento': '付款信息',
                  // 35 - Linha Digitável
                  // 520 - Pagamento para
                  'pagamento para': '付款至',
                  // 521 - Pago por
                  'pago por': '支付由。。。',
                  // 522 - Data da transação
                  'data da transacao': '交易日期',
                  // 523 - Código de autenticação
                  'codigo de autenticacao': '认证码',

                  // /Transaction/Receipt/types/billet
                  // 585 - Comprovante de pagamento recebido
                  'comprovante de pagamento recebido': '收到付款证明',
                  // 519 - Informações do pagamento
                  // 586 - Nosso Número
                  'nosso numero': '我们的号码',
                  // 35 - Linha Digitável
                  // 39 - Vencimento
                  // 279 - Descrição
                  // 587 - Pago em
                  'pago em': '支付为',
                  // 588 - Crédito em/
                  'credito em': '信用',
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Receipt/types/chargePaymentNmPay
                  // 524 - Comprovante de pagamento de mensalidade
                  'comprovante de pagamento de mensalidade': '每月付款证明',
                  // 519 - Informações do pagamento
                  // 35 - Linha Digitável
                  // 520 - Pagamento para
                  // 521 - Pago por
                  // 522 - Data da transação
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Receipt/types/payment
                  // 525 - Comprovante de pagamento
                  'comprovante de pagamento': '付款证明',
                  // 520 - Pagamento para
                  // 521 - Pago por
                  // 114 - NOME
                  // 27 - BANCO
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Receipt/types/tedIn
                  // 526 - Comprovante de transferência recebida
                  'comprovante de transferencia recebida': '收到转账证明',
                  // 527 - Origem
                  'origem': '来源',
                  // 114 - NOME
                  // 27 - BANCO
                  // 28 - AGÊNCIA
                  // 29 - CONTA
                  // 528 - Destino
                  'destino': '目的地',
                  // 114 - NOME
                  // 363 - DOCUMENTO
                  // 27 - BANCO
                  // 28 - AGÊNCIA
                  // 29 - CONTA
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Receipt/types/tedOut
                  // 589 - Comprovante de transferência
                  'comprovante de transferencia': '转账证明',
                  // 527 - Origem
                  // 114 - NOME
                  // 27 - BANCO
                  // 28 - AGÊNCIA
                  // 29 - CONTA
                  // 528 - Destino
                  // 114 - NOME
                  // 363 - DOCUMENTO
                  // 27 - BANCO
                  // 28 - AGÊNCIA
                  // 29 - CONTA
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Scheduled/index
                  // 529 - Agendamento cancelado com sucesso
                  'agendamento cancelado com sucesso': '预约成功取消',
                  // 530 - Não foi possível cancelar o agendamento. Tente novamente mais tarde
                  'nao foi possivel cancelar o agendamento. tente novamente mais tarde': '无法取消预约。请稍后再试',
                  // 105 - PENDENTE
                  // 531 - ERRO AO EXECUTAR
                  'erro ao executar': '运行错误',
                  // 532 - EXECUTADO
                  'executado': '已执行',
                  // 533 - As transações agendadas são realizadas no próximo dia útil
                  'as transacoes agendadas sao realizadas no proximo dia util': '预定交易在下一个工作日执行',
                  // 100 - De:
                  // 101 - Até:
                  // 534 - Nenhum agendamento para exibir
                  'nenhum agendamento para exibir': '没有时间表可显示',
                  // 535 - Nome do beneficiário
                  'nome do beneficiario': '受益人姓名',
                  // 536 - Documento do beneficiário
                  'documento do beneficiario': '受益人的文件',
                  // 27 - Banco
                  // 28 - Agência
                  // 29 - Conta
                  // 38 - Valor
                  // 118 - Status
                  // 537 - Data de execução
                  'data de execucao': '执行日期',
                  // 538 - Cancelar agendamento
                  'cancelar agendamento': '取消预约',
                  // 539 - Deseja mesmo cancelar esse agendamento?
                  'deseja mesmo cancelar esse agendamento': '确认要取消这个预约吗？',
                  // 497 - Sim
                  // 498 - Não
                  // 540 - carregar mais
                  'carregar mais': '更多',

                  // /Transaction/extract
                  // 100 - De:
                  // 101 - Até:
                  // 541 - Exportar para
                  'exportar para': '导出到',
                  // 542 - Extrato
                  'extrato': '银行对帐单',
                  // 543 - Nenhum lançamento para exibir
                  'nenhum lancamento para exibir': '没有可显示的条目',
                  // 540 - carregar mais

                  // /Transaction/index
                  // 542 - Extrato
                  // 544 - Agendamentos
                  'agendamentos': '预约',

                  //       // Vibe
                  //       /vibe/Evibe/index
                  //       231 - Todos
                  // 545 - Crédito
                  'credito': '信用卡',
                  // 546 - Débito
                  'debito': '现金卡',
                  // 89 - Boleto
                  // 547 - Não foi possível buscar os dados de venda
                  'nao foi possivel buscar os dados de venda': '无法获取销售数据',
                  // 105 - Pendente
                  // 231 - Todos
                  // 548 - Repassados
                  'repassados': '传递',
                  // 233 - Pendentes
                  // 549 - Tipo de pagamento
                  'tipo de pagamento': '付款类型',
                  // 550 - Data da venda
                  'data de venda': '销售日期',
                  // 100 - De:
                  // 101 - Até:
                  // 551 - Data do repasse
                  'data do repasse': '转会日期',
                  // 100 - De:
                  // 101 - Até:
                  // 552 - Nenhuma venda para exibir
                  'nenhuma venda para exibir': '没有销售额可显示',
                  // 553 - Nome do cliente
                  'nome do cliente': '客户全名',
                  // 38 - Valor
                  // 554 - Valor Repassado
                  'valor repassado': '转入金额',
                  // 555 - Natureza da Venda
                  'natureza da venda': '销售性质',
                  // 556 - Situação
                  'situacao': '状态',
                  // 226 - Parcelas
                  // 550 - Data da venda
                  // 551 - Data do repasse

                  //       // Withdraw
                  //       /Withdraw/index
                  //       557 - Desabilitar ponto de saque
                  'desabilitar ponto de saque': '禁用提款点',
                  // 558 - Deseja prosseguir com a desativação do ponto de saque?
                  'deseja prosseguir com a desativacao do ponto de saque': '您要继续禁用提款点吗？',
                  // 559 - Desativar ponto de saque
                  'desativar ponto de saque': '禁用提款点',
                  // 560 - Cancelar
                  'cancelar': '取消',
                  // 65 - O valor deve ser maior ou igual a R$0,10
                  // 561 - O nome do ponto de saque é obrigatório
                  'o nome do ponto de saque e obrigatorio': '提款点的名称是必填项',
                  // 562 - O logradouro do ponto de saque é obrigatório
                  'o logradouro do ponto de saque e obrigatorio': '提款地址是必填项',
                  // 563 - O nº é obrigatório
                  'o n e obrigatorio': '号码必填',
                  // 564 - O bairro do ponto de saque é obrigatório
                  'o bairro do ponto de saque e obrigatorio': '提款区是必填项',
                  // 565 - O valor máximo de saque é obrigatório
                  'o valor maximo de saque e obrigatorio': '最大提款额度必填',
                  // 566 - O horário de início é obrigatório
                  'o horario de inicio e obrigatorio': '开始时间必填',
                  // 567 - O horário final é obrigatório
                  'o horario final e obrigatorio': '结束时间必填',
                  // 568 - Hora digitada inválida
                  'hora digitada invalida': '输入的时间无效',
                  // 569 - Minuto digitado inválido
                  'minuto digitado invalido': '输入的分钟无效',
                  // 570 - Hora inicial digitada é maior que a hora final
                  'hora inicial digitada e maior que a hora final': '输入的开始时间大于结束时间',
                  // 65 - O valor deve ser maior ou igual a R$0,10
                  // 571 - Saques
                  'saques': '取款',
                  // 572 - Habilitar-se como ponto de saque
                  'habilitar-se como ponto de saque': '启用作为取款点',
                  // 573 - Nome de identificação do ponto de saque
                  'nome de identificacao do ponto de saque': '取款点标识名称',
                  // 574 - Logradouro ponto de saque
                  'logradouro ponto de saque': '取款点地址',
                  // 575 - Nº
                  // 'numero': 'Nº',
                  // 52 - Bairro
                  // 576 - Valor máximo para saques
                  'valor maximo para saques': '提款最大额度',
                  // 577 - Taxa cobrada por saque
                  'taxa cobrada por saque': '提款收取的费用',
                  // 578 - Horários para saques
                  'horarios para saques': '提款时间表',
                  // 579 - Horário início para saque
                  'horario inicio para saque': '提款开始时间',
                  // 580 - Horário final para saque
                  'horario final para saque': '提款结束时间',
                  // 581 - Habilitar ponto de saque
                  'habilitar ponto de saque': '启用提款点',
                  // 582 - Saque
                  'saque': '提款',
                  // 583 - Digite o valor abaixo. O código pode ser lido no aplicativo, no menu &quot;Saque&quot
                  'digite o valor abaixo. o codigo pode ser lido no aplicativo...': '请输入金额。可在应用程序中读取代码。',
                  // 38 - Valor
                  // 584 - Gerar QRCode
                  'gerar qrcode': '生成二维码',
                  // 557 - Desabilitar ponto de saque

                  // adicionando componentes
                  // src/components/DynamicList/index.jsx
'nenhuma informacao': '无信息',

// src/components/Header/index.jsx
'sem novas notificacoes': '没有新通知',
// 'banco': 'Banco',
// 'agencia': 'Agência',
// 'conta corrente': 'Conta Corrente',
'por favor atualize os documentos': '请更新文件',

// src/components/Menu/index.jsx
// 'extrato': 'Extrato',
'depositar': '存款',
// 'transferir': 'Transferir',
// 'pagar': 'pagar',
'cobrar': '收款',
// 'configuracoes': 'Configurações',
'loja': '商店',
// 'loja online': 'Loja online',
// 'saque': 'Saque',
'sair': '退出',

// src/components/Modals/SendDocuments/index.jsx
'e necessario atualizar seus documentos': '必须更新您的文件',
'enviar documentos': '发送文件',

// src/components/Notification/index.jsx
'ok': 'OK',

// src/components/Password/index.jsx
'digite a senha do seu cartao': '输入您的卡密码',
'para confirmar a transacao...': '确认交易，请输入卡的 4 位数密码',
'concluir': '完成',

// src/components/PopOverPrevious/index.jsx
'pesquisar cobrancas anteriores': '搜索以前的收款',
'nao foi encontrado nenhum resultado': '未找到结果',

// src/components/RegisterHeader/index.jsx
'dados de contato': '联系方式',
'envio de documentos': '发送文件',
// 'dados de contato': 'Dados de Contato',
// 'envio de documentos': 'Envio de documentos',
// 'dados de cadastro': 'Dados de Cadastro',

// src/components/Textarea/index.jsx
'caracteres restantes': '剩余字符数',
            },
      },
      ptbr: {
            translation: {
                  // pages

                   // google tradutor pois nao tinha sido traduzido:
                  'envie foto sorrindo': 'Envie uma foto do seu rosto sorrindo',
                  'sorrindo descricao': 'Para garantir que somente você terá acesso a sua conta, tire uma foto sorrindo. Vamos lá?',
                  'tirar foto': 'Tirar foto',
                  'pode continuar pelo telefone': 'Você pode continuar pelo celular!',
                  'aponte a camera': 'Aponte a câmera do seu celular para o código QR abaixo e continue a abertura da sua conta pelo app.',
                  'capture imagem sorrindo': 'Por favor capture uma imagem sorrindo.',
                  'foto ficou boa?': 'A foto ficou boa e nítida para envio?',
                  'ficou boa': 'Ficou boa',
                  'tirar outra': 'Tirar outra',
                  'tire serio': 'Envie uma foto como expressão séria.',
                  'tire serio descricao': 'Para garantir que somente você terá acesso a sua conta, tire uma foto sério (a). Vamos lá?',

                  // adicionado posteriormente
                  'acesse sua conta': 'Acesse sua conta',
                  'alterar idioma': 'Alterar idioma',
                  'todas as transacoes': 'Todas as movimentações',
                  'recebimento de boletos': 'Recebimentos de boleto',
                  'homepage': 'Homepage',
                  'ofereca diferentes formas': 'Ofereça diferentes formas de pagamentos aos seus clientes, incluindo parcelamento em 12x.',
                  'conhecer solucoes': 'CONHECER SOLUÇÕES',
                  'vendas': 'Vendas',
                  'receita': 'Receita',
                  'despesas': 'Despesas',
                  'voce no controle': 'Você no controle!',
                  'wibbank e uma plataforma completa': 'O Wibbank é uma plataforma completa para captura de transações com cartões de crédito e débito.',
                  'cartao pre pago': 'Cartão Pré-pago',
                  'quer ter cartao': 'Quer ter cartão na função crédito sem comprovação de renda?',
                  'pedir agora': 'PEDIR AGORA',

                  //       // Account
                  //       Account/index
                  //       1 - Dados cadastrais
                  'dados cadastrais': 'Dados cadastrais',
                  // 2 - Atualizar documentos
                  'atualizar documentos': 'Atualizar documentos',
                  // 3 - Resetar senha de 4 dígitos
                  'resetar senha de 4 digitos': 'Resetar senha de 4 dígitos',

                  //       // AccountSelection
                  //       /AccountSelection/index
                  //       4 - Código inválido
                  'codigo invalido': 'Código inválido',
                  //       5 - Código enviado
                  'codigo enviado': 'Código enviado',
                  //       6 - Bem-vindo
                  'bem-vindo': 'Bem-vindo',
                  //       7 - Escolha a conta que deseja acessar
                  'escolha a conta que deseja acessar': 'Escolha a conta que deseja acessar',
                  //       8 - Insira o código recebido por SMS
                  'insira o codigo recebido por sms': 'Insira o código recebido por SMS',
                  //       9 - Confirmar
                  'confirmar': 'Confirmar',

                  //       // Clank
                  //       /Blank/index
                  //       10 - Digite os dados de cobrança
                  'digite os dados de cobrança': 'Digite os dados de cobrança',

                  //       // Charge
                  //       /Charge/ImportCharges/index
                  //       11 - Por favor anexe um documento
                  'por favor anexe um documento': 'Por favor anexe um documento',
                  // 12 - Esse arquivo não nenhuma linha com os dados corretos
                  'esse arquivo não nenhuma linha com os dados corretos': 'Esse arquivo não nenhuma linha com os dados corretos',
                  // 13 - Todas as linhas do arquivo foram importadas com sucesso
                  'todas as linhas do arquivo foram importadas com sucesso': 'Todas as linhas do arquivo foram importadas com sucesso',
                  // 14 - Erro na linha
                  'erro na linha': 'Erro na linha',
                  // 15 - Demais linhas foram criadas com sucesso
                  'demais linhas foram criadas com sucesso': 'Demais linhas foram criadas com sucesso',
                  // 16 - Erro na importação de todas as linhas da planilha
                  'erro na importação de todas as linhas da planilha': 'Erro na importação de todas as linhas da planilha',
                  // 17 - Ocorreu um erro tente novamente mais tarde
                  'ocorreu um erro tente novamente mais tarde': 'Ocorreu um erro tente novamente mais tarde',
                  // 18 - Importar cobranças em lote
                  'importar cobranças em lote': 'Importar cobranças em lote',
                  // 19 - Clique aqui para fazer o download da planilha de exemplo
                  'clique aqui para fazer o download da planilha de exemplo': 'Clique aqui para fazer o download da planilha de exemplo',
                  // 20 - Ocorreu um erro ao importar o arquivo
                  'ocorreu um erro ao importar o arquivo': 'Ocorreu um erro ao importar o arquivo',
                  // 21 - Anexar arquivo
                  'anexar arquivo': 'Anexar arquivo',
                  // 22 - Arraste para esse retângulo, ou clique aqui e selecione um arquivo (xlsx / xls / csv)
                  'arraste para esse retangulo...': 'Arraste para esse retângulo, ou clique aqui e selecione um arquivo (xlsx / xls / csv)',
                  // 23 - Enviar arquivo
                  'enviar arquivo': 'Enviar arquivo',

                  // /Charge/NewCharge/Fields/Billet/index
                  // 24 - Insira os dados da pessoa/empresa recebedora
                  'insira os dados da pessoa/empresa recebedora': 'Insira os dados da pessoa/empresa recebedora',
                  // 25 - CPF / CNPJ
                  'cpf / cnpj': 'CPF / CNPJ',
                  // 26 - Nome completo
                  'nome completo': 'Nome completo',
                  // 27 - Banco
                  'banco': 'Banco',
                  // 28 - Agência
                  'agencia': 'Agência',
                  // 29 - Conta
                  'conta': 'Conta',
                  // 30 - Dígito
                  'digito': 'Dígito',
                  // 31 - Porcentagem (%) de recebimento
                  'porcentagem de recebimento': 'Porcentagem (%) de recebimento',
                  // 32 - Remover esse split
                  'remover esse split': 'Remover esse split',
                  // 33 - Adicionar split de pagamento
                  'adicionar split de pagamento': 'Adicionar split de pagamento',
                  // 34 - Dados do boleto
                  'dados do boleto': 'Dados do boleto',
                  // 35 - Linha Digitável
                  'linha digitavel': 'Linha Digitável',
                  // 36 - Linha digitável copiada com sucesso
                  'linha digitavel copiada com sucesso': 'Linha digitável copiada com sucesso',
                  // 37 - TOQUE AQUI PARA COPIAR
                  'toque aqui para copiar': 'TOQUE AQUI PARA COPIAR',
                  // 38 - Valor
                  'valor': 'Valor',
                  // 39 - Vencimento
                  'vencimento': 'Vencimento',
                  // 40 - IMPRIMIR BOLETO
                  'imprimir boleto': 'IMPRIMIR BOLETO',
                  // 41 - Valor da cobrança
                  'valor da cobrança': 'Valor da cobrança',
                  // 42 - Juros (%) ao dia
                  'juros ao dia': 'Juros (%) ao dia',
                  // 43 - Multa (%)
                  'multa': 'Multa (%)',
                  // 44 - Data de vencimento
                  'data de vencimento': 'Data de vencimento',
                  // 45 - Descrição da cobrança
                  'descricao da cobranca': 'Descrição da cobrança',
                  // 46 - Quem será cobrado?
                  'quem sera cobrado': 'Quem será cobrado?',
                  // 47 - Insira os dados da pessoa que será cobrada
                  'insira os dados da pessoa que sera cobrada': 'Insira os dados da pessoa que será cobrada',
                  // 25 - CPF / CNPJ
                  // 26 - Nome completo
                  // 48 - CEP
                  'cep': 'CEP',
                  // 49 - Logradouro
                  'logradouro': 'Logradouro',
                  // 50 - Número
                  'numero': 'Número',
                  // 51 - Complemento
                  'complemento': 'Complemento',
                  // 52 - Bairro
                  'bairro': 'Bairro',
                  // 53 - Cidade
                  'cidade': 'Cidade',
                  // 54 - Estado
                  'estado': 'Estado',
                  // 55 - E-mail
                  'email': 'E-mail',
                  // 56 - Celular
                  'celular': 'Celular',
                  // 57 - Além de você, alguém mais embolsará o pagamento dessa cobrança?
                  'alem de voce...': 'Além de você, alguém mais embolsará o pagamento dessa cobrança?',
                  // 58 - Gerar cobrança
                  'gerar cobranca': 'Gerar cobrança',
                  // 59 - Carregando
                  'carregando': 'Carregando',

                  // /Charge/NewCharge/Fields/Card/index
                  // 60 - Dados da cobrança por cartão de crédito
                  'dados da cobranca por cartao de credito': 'Dados da cobrança por cartão de crédito',
                  // 61 - Cobrança gerada com sucesso!
                  'cobranca gerada com sucesso': 'Cobrança gerada com sucesso!',
                  // 62 - Link pagamento Cartão de Crédito
                  'link pagamento cartao de credito': 'Link pagamento Cartão de Crédito',
                  // 63 - Link para pagamento em cartão de crédito copiado com sucesso
                  'link para pagamento em cartao...': 'Link para pagamento em cartão de crédito copiado com sucesso',
                  // 37 - TOQUE AQUI PARA COPIAR
                  // 38 - Valor
                  // 39 - Vencimento
                  // 41 - Valor da cobrança
                  // 44 - Data de vencimento
                  // 64 - Número máximo de parcelas
                  'numero maximo de parcelas': 'Número máximo de parcelas',
                  // 45 - Descrição da cobrança
                  // 46 - Quem será cobrado?
                  // 47 - Insira os dados da pessoa que será cobrada
                  // 25 - CPF / CNPJ
                  // 26 - Nome completo
                  // 55 - E-mail
                  // 56 - Celular
                  // 58 - Gerar cobrança
                  // 59 - Carregando

                  // /Charge/NewCharge/index
                  // 65 - O valor deve ser maior ou igual a R$0,10
                  'o valor deve ser maior ou igual a...': 'O valor deve ser maior ou igual a R$5,00',
                  // 66 - As porcentagens devem ser números inteiros
                  'as porcentagens devem ser numeros inteiros': 'As porcentagens devem ser números inteiros',
                  // 67 - O dígito é obrigatório
                  'o digito e obrigatorio': 'O dígito é obrigatório',
                  // 68 - O nome é obrigatório
                  'o nome e obrigatorio': 'O nome é obrigatório',
                  // 69 - O documento é obrigatório
                  'o documento e obrigatorio': 'O documento é obrigatório',
                  // 70 - A soma das porcentagens não pode ultrapassar 100%
                  'a soma das porcentagens nao pode ultrapassar...': 'A soma das porcentagens não pode ultrapassar 100%',
                  // 69 - O documento é obrigatório
                  // 68 - O nome é obrigatório
                  // 71 - O CEP é obrigatório
                  'o cep e obrigatorio': 'O CEP é obrigatório',
                  // 72 - O logradouro é obrigatório
                  'o logradouro e obrigatorio': 'O logradouro é obrigatório',
                  // 73 - Deve ter 2 dígitos
                  'deve ter 2 digitos': 'Deve ter 2 dígitos',
                  // 74 - O Estado é obrigatório
                  'o estado e obrigatorio': 'O Estado é obrigatório',
                  // 75 - A cidade é obrigatória
                  'a cidade e obrigatoria': 'A cidade é obrigatória',
                  // 76 - O bairro é obrigatório
                  'o bairro e obrigatorio': 'O bairro é obrigatório',
                  // 77 - O número é obrigatório
                  'o numero e obrigatorio': 'O número é obrigatório',
                  // 78 - A data é obrigatória
                  'a data e obrigatoria': 'A data é obrigatória',
                  // 79 - A descrição é obrigatória
                  'a descricao e obrigatoria': 'A descrição é obrigatória',
                  // 80 - O formato do telefone é inválido
                  'O formato do telefone é inválido': 'O formato do telefone é inválido',
                  // 81 - O email é inválido
                  'o email e invalido': 'O email é inválido',
                  // 82 - O e-mail é obrigatório
                  'o email e obrigatorio': 'O e-mail é obrigatório',
                  // 65 - O valor deve ser maior ou igual a R$0,10
                  // 83 - A data não pode ser menor que hoje
                  'a data nao pode ser menor que hoje': 'A data não pode ser menor que hoje',
                  // 84 - O telefone é obrigatório
                  'o telefone e obrigatorio': 'O telefone é obrigatório',
                  // 85 - Erro ao gerar cobrança
                  'erro ao gerar cobranca': 'Erro ao gerar cobrança',
                  // 86 - Cobranças
                  'cobrancas': 'Cobranças',
                  // 87 - Nova cobrança
                  'nova cobranca': 'Nova cobrança',
                  // 88 - Escolha o tipo de cobrança
                  'escolha o tipo de cobranca': 'Escolha o tipo de cobrança',
                  // 89 - Boleto
                  'boleto': 'Boleto',
                  // 90 - Cartão:
                  'cartao': 'Cartão',

                  // /Charge/index
                  // 91 - Recebido
                  'recebido': 'Recebido',
                  // 92 - Pago (Em processamento)
                  'pago (em processamento)': 'Pago (Em processamento)',
                  // 93 - Vencido
                  'vencido': 'Vencido',
                  // 94 - Em Aberto
                  'em aberto': 'Em Aberto',
                  // 89 - Boleto
                  // 95 - Cartão de crédito
                  'cartao de credito': 'Cartão de crédito',
                  // 96 - Pix
                  'pix': 'Pix',
                  // 97 - Link copiado com sucesso
                  'link copiado com sucesso': 'Link copiado com sucesso',
                  // 98 - Deseja mesmo cancelar essa cobrança? Essa ação não pode ser desfeita
                  'deseja mesmo cancelar...': 'Deseja mesmo cancelar essa cobrança? Essa ação não pode ser desfeita',
                  // 86 - Cobranças
                  // 87 - Nova cobrança
                  // 99 - Importar cobranças
                  'importar cobrancas': 'Importar cobranças',
                  // 100 - De:
                  'de': 'De:',
                  // 101 - Até:
                  'ate': 'Até:',
                  // 86- Cobranças
                  // 102 - Relatório de cobranças
                  'relatorio de cobrancas': 'Relatório de cobranças',
                  // 103 - Pago
                  'pago': 'Pago',
                  // 86- cobranças
                  // 104 - cobrança
                  'cobranca': 'cobrança',
                  // 105 - Pendente
                  'pendente': 'Pendente',
                  // 93 - Vencido
                  // 106 - Total de cobranças nesse período
                  'total de cobrancas nesse periodo': 'Total de cobranças nesse período',
                  // 86- cobranças
                  // 107 - Tipos de cobrança
                  'tipos de cobranca': 'Tipos de cobrança',
                  // 108 - Boletos
                  'boletos': 'Boletos',
                  // 89 - Boleto
                  // 90 - Cartão
                  // 109 - Cobranças em atraso
                  'cobrancas em atraso': 'Cobranças em atraso',
                  // 110 - Lista de cobranças
                  'lista de cobrancas': 'Lista de cobranças',
                  // 111 - Pesquisar por nome, documento...
                  'pesquisar por nome, documento...': 'Pesquisar por nome, documento...',
                  // 112 - Baixar extrato
                  'baixar extrato': 'Baixar extrato',
                  // 113 - Nenhuma cobrança para exibir
                  'nenhuma cobranca para exibir': 'Nenhuma cobrança para exibir',
                  // 114 - Nome
                  'nome': 'Nome',
                  // 115 - Emissão
                  'emissao': 'Emissão',
                  // 39 - Vencimento
                  // 38 - Valor
                  // 116 - Valor recebido
                  'valor recebido': 'Valor recebido',
                  // 117 - Tipo
                  'tipo': 'Tipo',
                  // 118 - Status
                  'status': 'Status',
                  // 119 - Copiar link da cobrança
                  'copiar link da cobranca': 'Copiar link da cobrança',
                  // 120 - Visualizar cobrança
                  'visualizar cobranca': 'Visualizar cobrança',
                  // 121 - Cancelar cobrança
                  'cancelar cobranca': 'Cancelar cobrança',

                  //       // Deposit
                  //       'Deposits Title': 'Depósitos',
                  //       'Button Generate billet': 'Gerar Boleto',

                  //       /Deposit/index
                  // 122 - O valor é obrigatório
                  'o valor e obrigatorio': 'O valor é obrigatório',
                  // 123 - Valor mínimo R$ 0,10
                  'valor minimo...': 'Valor mínimo R$ 5,00',
                  // 124 - O identificador é obrigatório
                  'o identificador e obrigatorio': 'O identificador é obrigatório',
                  // 103 - Pago
                  // 93 - Vencido
                  // 94 - Em Aberto
                  // 125 - Depósitos
                  'depositos': 'Depósitos',
                  // 126 - Gerar boleto
                  'gerar boleto': 'Gerar boleto',
                  // 127 - Boleto bancário
                  'boleto bancario': 'Boleto bancário',
                  // 128 - O dinheiro entrará em sua conta em até 02 dias úteis após o pagamento do boleto
                  'o dinheiro entrara em sua conta em ate...': 'O dinheiro entrará em sua conta em até 02 dias úteis após o pagamento do boleto',
                  // 129 - Quanto deseja depositar?
                  'quanto deseja depositar': 'Quanto deseja depositar?',
                  // 130 - Identificador no comprovante
                  'identificador no comprovante': 'Identificador no comprovante',
                  // 126 - Gerar boleto
                  // 34 - Dados do boleto
                  // 35 - Linha Digitável
                  // 36 - Linha digitável copiada com sucesso
                  // 37 - TOQUE AQUI PARA COPIAR
                  // 38 - Valor
                  // 39 - Vencimento
                  // 40 - IMPRIMIR BOLETO
                  // 131 - Depósito
                  'deposito': 'Depósito',
                  // 100 – De:
                  // 101 - Até:
                  // 132 - Nenhum boleto para exibir
                  'nenhum boleto para exibir': 'Nenhum boleto para exibir',
                  // 133 - Identificação no comprovante
                  'identificacao no comprovante': 'Identificação no comprovante',
                  // 134 - Data de emissão
                  'data de emissao': 'Data de emissão',
                  // 39 - Vencimento
                  // 38 - Valor
                  // 118 - Status
                  // 89 - Boleto
                  // 135 - Erro ao gerar boleto
                  'erro ao gerar boleto': 'Erro ao gerar boleto',

                  //       // Documents
                  //       /Documents/index
                  //       136 - Não foi possível validar o documento enviado
                  'nao foi possivel validar o documento enviado': 'Não foi possível validar o documento enviado',
                  //       137 - Para continuar envie outro documento
                  'para continuar envie outro documento': 'Para continuar envie outro documento',
                  //       138 - Envie sua selfie
                  'envie sua selfie': 'Envie sua selfie',
                  //       139 - A foto precisa mostrar seu rosto inteiro e de olhos abertos
                  'a foto precisa mostrar seu rosto inteiro e de olhos abertos': 'A foto precisa mostrar seu rosto inteiro e de olhos abertos',
                  //       140 - Selecione um documento
                  'selecione um documento': 'Selecione um documento',
                  //       141 - Próximo
                  'proximo': 'Próximo',
                  //       142 - Envie foto da FRENTE da sua CNH
                  'envie foto da frente da sua cnh': 'Envie foto da FRENTE da sua CNH',
                  //       143 - Envie foto da FRENTE do seu RG
                  'envie foto da frente do seu rg': 'Envie foto da FRENTE do seu RG',
                  //       144 - Clique para fazer upload
                  'clique para fazer upload': 'Clique para fazer upload',
                  //       145 - Excluir
                  'excluir': 'Excluir',
                  //       141 - Próximo
                  //       146 - Envie foto do VERSO da sua CNH
                  'envie foto do verso da sua cnh': 'Envie foto do VERSO da sua CNH',
                  //       147 - Envie foto do VERSO do seu RG
                  'envie foto do verso do seu rg': 'Envie foto do VERSO do seu RG',
                  //       144 - Clique para fazer upload
                  //       145 - Excluir
                  //       141 – Próximo

                  //       148 - Envie o documento principal de identificação/constituição da empresa. Ex: Contrato Social
                  'envie o documento principal de identificacao...': 'Envie o documento principal de identificação/constituição da empresa. Ex: Contrato Social',

                  //       144 - Clique para fazer upload
                  //       145 - Excluir
                  //       149 - Finalizar
                  'finalizar': 'Finalizar',
                  //       150 - Parabéns, os arquivos foram salvos com sucesso!
                  'parabens, os arquivos foram salvos com sucesso': 'Parabéns, os arquivos foram salvos com sucesso!',
                  //       151 - Tipo de documento é obrigatório
                  'tipo de documento e obrigatorio': 'Tipo de documento é obrigatório',
                  //       152 - Erro ao enviar documentos
                  'erro ao enviar documentos': 'Erro ao enviar documentos',
                  //       153 - Atualização de documentos
                  'atualizacao de documentos': 'Atualização de documentos',

                  //       // ForgotPassword
                  //       /ForgotPassword/index
                  //       154 - Erro ao recuperar a senha
                  'erro ao recuperar senha': 'Erro ao recuperar a senha',
                  // 155 - Recupere sua senha pelo e-mail
                  'recupere sua senha pelo email': 'Recupere sua senha pelo e-mail',
                  // 156 - Recupere a sua senha de acesso
                  'recupere a sua senha de acesso': 'Recupere a sua senha de acesso',
                  // 157 - Insira o CPF/CNPJ cadastrado
                  'insira o cpf/cnpj cadastrado': 'Insira o CPF/CNPJ cadastrado',
                  // 158 - Recuperar senha
                  'recuperar senha': 'Recuperar senha',

                  //       // Invite
                  //       /Invite/index
                  //       159 - Baixe o app
                  'baixe o app': 'Baixe o app',
                  // 160 - Disponível no Google Play
                  'disponivel no google play': 'Disponível no Google Play',
                  // 161 - Disponível na App Store
                  'disponivel na app store': 'Disponível na App Store',
                  //       // Login
                  //       'title Login': 'Acesse sua conta',
                  //       'Password Login': 'Senha',
                  //       'Button Sign in': 'Entrar',
                  //       'Button Forgot': 'Esqueci minha senha',
                  //       'Label Register': 'Não possui cadastro?',
                  //       'Button Register': 'Cadastrar',

                  //       /Login/index
                  //       162 - Credenciais inválidas
                  'credenciais invalidas': 'Credenciais inválidas',
                  // 163 - Erro ao fazer login
                  'erro ao fazer login': 'Erro ao fazer login',
                  // 164 - Senha
                  'senha': 'Senha',
                  // 165 - Entrar
                  'entrar': 'Entrar',
                  // 166 - Esqueci minha senha
                  'esqueci minha senha': 'Esqueci minha senha',
                  // 167 - Não possui cadastro?
                  'nao possui cadastro': 'Não possui cadastro?',
                  // 168 - Cadastrar
                  'cadastrar': 'Cadastrar',

                  //       // nmpay
                  //       /nmpay/EducationalPayments/ImportCharges/index
                  //       169 - No máximo 3MB por arquivo
                  'no maximo 3mb por arquivo': 'No máximo 3MB por arquivo',
                  // 170 - Formato de arquivo inválido
                  'formato de arquivo invalido': 'Formato de arquivo inválido',
                  // 20 - Ocorreu um erro ao importar o arquivo.
                  // 171 - Por favor importe a planilha
                  'por favor importe a planilha': 'Por favor importe a planilha',
                  // 172 - Erro na linha (numero?) da planilha
                  // 'erro na linha (numero?) da planilha': 'Erro na linha (numero?) da planilha',
                  // 173 - Cobranças foram criadas
                  'cobrancas foram criadas': 'Cobranças foram criadas',
                  // 174 - Nenhuma cobrança foi criada
                  'nenhuma cobranca for criada': 'Nenhuma cobrança foi criada',
                  // 17 - Ocorreu um erro tente novamente mais tarde
                  // 175 - Envie a planilha para cadastrar as cobranças/mensalidades
                  'envie a planilha para cadastrar as cobrancas/mensalidades': 'Envie a planilha para cadastrar as cobranças/mensalidades',
                  // 176 - Arquivo anexado:
                  'arquivo anexado': 'Arquivo anexado:',
                  // 177 - Clique ou arraste a planilha para esta área para substituir o arquivo
                  'clique ou arraste a planilha para esta area para substituir o arquivo': 'Clique ou arraste a planilha para esta área para substituir o arquivo',
                  // 178 - Clique ou arraste a planilha para esta área para fazer o upload
                  'clique ou arraste a planilha para esta area para fazer o upload': 'Clique ou arraste a planilha para esta área para fazer o upload',
                  // /nmpay/EducationalPayments/NmPage/Partners/EditPartner/index
                  // 179 - Digite o número do documento
                  'digite o numero do documento': 'Digite o número do documento',
                  // 180 - Esse número de documento não é válido
                  'esse numero de documento nao e valido': 'Esse número de documento não é válido',
                  // 181 - Digite o nome do parceiro!
                  'digite o nome do parceiro': 'Digite o nome do parceiro!',
                  // 182 - Digite o nome 2
                  'digite o nome 2': 'Digite o nome 2',
                  // 183 - Insira a validade!
                  'insira a validade': 'Insira a validade!',
                  // 184 - Parceiro criado com sucesso
                  'parceiro criado com sucesso': 'Parceiro criado com sucesso',
                  // 185 - Occorreu um erro, tente novamente mais tarde
                  'ocorreu um erro, tente novamente mais tarde': 'Occorreu um erro, tente novamente mais tarde',
                  // 186 - Taxa para 1 parcela
                  'taxa para 1 parcela': 'Taxa para 1 parcela',
                  // 187 - Erro ao cadastrar parceiro
                  'erro ao cadastrar parceiro': 'Erro ao cadastrar parceiro',
                  // 188 - Parceiro cadastrado!
                  'parceiro cadastrado': 'Parceiro cadastrado!',
                  // 189 - voltar
                  'voltar': 'voltar',
                  // 190 - Cadastrar Parceiro
                  'cadastrar parceiro': 'Cadastrar Parceiro',
                  // 191 - Parceiro
                  'parceiro': 'Parceiro',
                  // 192 - Nome Fantasia
                  'nome fantasia': 'Nome Fantasia',
                  // 193 - Documento(CNPJ)
                  'documento(cnpj)': 'Documento(CNPJ)',
                  // 194 - Endereço
                  'endereco': 'Endereço',
                  // 50 - Número
                  // 51 - Complemento
                  // 48 - CEP
                  // 53 - Cidade
                  // 54 - Estado
                  // 195 - Financeiro
                  'financeiro': 'Financeiro',
                  // 196 - Número do banco
                  'numero do banco': 'Número do banco',
                  // 197 - Agência Bancária
                  'agencia bancaria': 'Agência Bancária',
                  // 198 - Conta Bancária
                  'conta bancaria': 'Conta Bancária',
                  // 30 - Dígito
                  // 199 - Max. dias após venc
                  'max. dias apos venc': 'Max. dias após venc',
                  // 200 - Multa
                  // 'multa': 'Multa',
                  // 201 - Juros
                  'juros': 'Juros',
                  // 202 - Desconto
                  'desconto': 'Desconto',
                  // 203 - Mínimo de parcelas
                  'minimo de parcelas': 'Mínimo de parcelas',
                  // 204 - Máximo de parcelas
                  'maximo de parcelas': 'Máximo de parcelas',
                  // 205 - Horário Limite de Pagamento
                  'horario limite de pagamento': 'Horário Limite de Pagamento',
                  // 206 - Esconder Cobranças atrasadas?
                  'esconder cobrancas atrasadas': 'Esconder Cobranças atrasadas?',
                  // 207 - Taxas
                  'taxas': 'Taxas',
                  // 208 - Enviar
                  'enviar': 'Enviar',

                  // /nmpay/EducationalPayments/NmPage/Partners/CreatePartner/index
                  // 179 - Digite o número do documento.
                  // 180 - Esse número de documento não é válido
                  // 183 - Insira a validade!
                  // 209 - Parceiro atualizado com com sucesso
                  'parceiro atualizado com sucesso': 'Parceiro atualizado com com sucesso',
                  // 185 - Occorreu um erro, tente novamente mais tarde
                  // 186 - Taxa para 1 parcela
                  // 210 - Taxa para 2 parcelas
                  'taxa para 2 parcelas': 'Taxa para 2 parcelas',
                  // 187 - Erro ao cadastrar parceiro
                  // 188 - Parceiro cadastrado!
                  // 189 - voltar
                  // 190 - Cadastrar Parceiro
                  // 211 - Parceiro editado!
                  'parceiro editado': 'Parceiro editado!',
                  // 212 - Edição de parceiro
                  'edicao de parceiro': 'Edição de parceiro',
                  // 213 - Razão Social
                  'razao social': 'Razão Social',
                  // 192 - Nome Fantasia
                  // 193 - Documento(CNPJ)
                  // 194 - Endereço
                  // 50 - Número
                  // 51 - Complemento
                  // 48 - CEP
                  // 53 - Cidade
                  // 54 - Estado
                  // 196 - Número do banco
                  // 197 - Agência Bancária
                  // 198 - Conta Bancária
                  // 30 - Dígito
                  // 199 - Max. dias após venc

                  // /nmpay/EducationalPayments/NmPage/Partners/index
                  // 214 - Parceiros
                  'parceiros': 'Parceiros',
                  // 215 - Criar novo parceiro
                  'criar novo parceiro': 'Criar novo parceiro',
                  // 216 - Nenhum parceiro foi cadastrado
                  'nenhum parceiro for cadastrado': 'Nenhum parceiro foi cadastrado',
                  // 217 - Boleto avulso
                  'boleto avulso': 'Boleto avulso',
                  // 191 - PARCEIRO
                  // 218 - ESTUDANTE
                  'estudante': 'ESTUDANTE',
                  // 219 - EMAIL
                  // 'email': 'EMAIL',
                  // 220 - DATA DO VENCIMENTO
                  'data do vencimento': 'DATA DO VENCIMENTO',
                  // 221 - DATA DO PAGAMENTO
                  'data do pagamento': 'DATA DO PAGAMENTO',
                  // 222 - VALOR BASE
                  'valor base': 'VALOR BASE',
                  // 223 - VALOR PAGAMENTO
                  'valor pagamento': 'VALOR PAGAMENTO',
                  // 224 - VALOR OPERAÇÃO
                  'valor operacao': 'VALOR OPERAÇÃO',
                  // 225 - METODO DE PAGAMENTO
                  'metodo de pagamento': 'METODO DE PAGAMENTO',
                  // 226 - PARCELAS
                  'parcelas': 'PARCELAS',
                  // 89 - BOLETO
                  // 227 - Erro ao gerar planilha
                  'erro ao gerar planilha': 'Erro ao gerar planilha',
                  // 228 - Copiar código de barras
                  'copiar codigo de barras': 'Copiar código de barras',
                  // 35 - Linha Digitável
                  // 36 - Linha digitável copiada com sucesso
                  // 229 - NÃO PAGO
                  'nao pago': 'NÃO PAGO',

                  // nmpay/EducationalPayments/NmPage/index
                  // 230 - Mensalidades
                  'mensalidades': 'Mensalidades',
                  // 231 - Todos
                  'todos': 'Todos',
                  // 232 - Pagos
                  'pagos': 'Pagos',
                  // 233 - Pendentes
                  'pendentes': 'Pendentes',
                  // 234 - Atrasados
                  'atrasados': 'Atrasados',
                  // 235 - Avulsos
                  'avulsos': 'Avulsos',
                  // 215 - Criar novo parceiro
                  // 236 - Lista de parceiros
                  'lista de parceiros': 'Lista de parceiros',
                  // 214 - Parceiros
                  // 237 - Cadastrar mensalidades a partir de uma planilha
                  'cadastrar mensalidades a partir de uma planilha': 'Cadastrar mensalidades a partir de uma planilha',
                  // 238 - Cadastrar mensalidades
                  'cadastrar mensalidades': 'Cadastrar mensalidades',
                  // 239 - Exportar excel dessa tabela
                  'exportar excel dessa tabela': 'Exportar excel dessa tabela',
                  // 240 - Exportar excel
                  'exportar excel': 'Exportar excel',
                  // 131 - Depósito
                  // 241 - Nenhum pagamento para exibir
                  'nenhum pagamento para exibir': 'Nenhum pagamento para exibir',
                  // 242 - Nome do pagador
                  'nome do pagador': 'Nome do pagador',
                  // 243 - Documento do pagador
                  'documento do pagador': 'Documento do pagador',
                  // 244 - Faculdade
                  'faculdade': 'Faculdade',
                  // 44 - Data de vencimento
                  // 245 - Data de pagamento
                  'data de pagamento': 'Data de pagamento',
                  // 38 - Valor
                  // 226 - Parcelas
                  // 35 - Linha digitável
                  // 39 - Vencimento
                  // 100 – de:
                  // 101 - até:
                  // 246 - Pagamento
                  'pagamento': 'Pagamento',
                  // 214 - Parceiros
                  // 241 - Nenhum pagamento para exibir
                  // 218 - Estudante
                  // 244 - Faculdade
                  // 44 - Data de vencimento
                  // 245 - Data de pagamento
                  // 222 - Valor base
                  // 247 - Valor de pagamento
                  'valor de pagamento': 'Valor de pagamento',
                  // 224 - Valor operação
                  // 248 - Método de pagamento
                  // 'metodo de pagamento': 'Método de pagamento',
                  // 226 - Parcelas

                  // nmpay/EducationalPayments/StudentPage/DetachedPayment/Payment/index
                  // 249 - Digite o número do cartão
                  'digite o numero do cartao': 'Digite o número do cartão',
                  // 250 - O número do cartão deve ter 16 dígitos
                  'o numero do cartao debe ter 16 digitos': 'O número do cartão deve ter 16 dígitos',
                  // 251 - Digite o nome
                  'digite o nome': 'Digite o nome',
                  // 252 - Digite o cpf
                  'digite o cpf': 'Digite o cpf',
                  // 253 - Digite o mês de vencimento
                  'digite o mes de vencimento': 'Digite o mês de vencimento',
                  // 254 - Digite o ano de vencimento
                  'digite o ano de vencimento': 'Digite o ano de vencimento',
                  // 255 - Digite o código de segurança
                  'digite o codigo de seguranca': 'Digite o código de segurança',
                  // 256 - Digite a quantidade de parcelas
                  'digite a quantidade de parcelas': 'Digite a quantidade de parcelas',
                  // 257 - Cartão inválido
                  'cartao invalido': 'Cartão inválido',
                  // 258 - Cartão vencido
                  'cartao vencido': 'Cartão vencido',
                  // 259 - Cobrança paga com sucesso
                  'cobranca paga com sucesso': 'Cobrança paga com sucesso',
                  // 260 - Ocorreu um erro, tente novamente mais tarde
                  // 'ocorreu um erro, tente novamente mais tarde': 'Ocorreu um erro, tente novamente mais tarde',
                  // 189 - voltar
                  // 261 - Pagamento por cartão
                  'pagamento por cartao': 'Pagamento por cartão',
                  // 262 - Informações da cobrança
                  'informacoes da cobranca': 'Informações da cobrança',
                  // 263 - Beneficiário
                  'beneficiario': 'Beneficiário',
                  // 264 - Aluno/Responsável
                  'aluno/responsavel': 'Aluno/Responsável',
                  // 38 - Valor
                  // 226 - Parcelas
                  // 265 - (numero?) parcelas de (numero?)
                  // '(numero?) parcelas de (numero?)': '(numero?) parcelas de (numero?)',
                  // 39 - Vencimento
                  // 266 - Número do cartão
                  'numero do cartao': 'Número do cartão',
                  // 267 - Nome escrito no cartão
                  'nome escrito no cartao': 'Nome escrito no cartão',
                  // 268 - CPF do dono do cartão
                  'cpf do dono do cartao': 'CPF do dono do cartão',
                  // 269 - Mês de vencimento
                  'mes de vencimento': 'Mês de vencimento',
                  // 270 - Ano de vencimento
                  'ano de vencimento': 'Ano de vencimento',
                  // 226 - Parcelas
                  // 271 - Pagar
                  'pagar': 'Pagar',

                  // nmpay/EducationalPayments/studentPage/DetachedPayment/index
                  // 272 - Código de barras inválido
                  'codigo de barras invalido': 'Código de barras inválido',
                  // 273 - Esse código de barras é inválido ou não pertence a uma instituição parceira
                  'esse codigo de barras e invalido ou...': 'Esse código de barras é inválido ou não pertence a uma instituição parceira',
                  // 274 - Pagamento de boleto avulso
                  'pagamento de boleto avulso': 'Pagamento de boleto avulso',
                  // 275 - Digite o código de barras
                  'digite o codigo de barras': 'Digite o código de barras',
                  // 276 - Continuar
                  'continuar': 'Continuar',

                  // nmpay/EducationalPayments/studentPage/payment/index
                  // 249 - Digite o número do cartão
                  // 250 - O número do cartão deve ter 16 dígitos
                  // 251 - Digite o nome
                  // 252 - Digite o cpf
                  // 253 - Digite o mês de vencimento
                  // 253 - Digite o mês de vencimento
                  // 254 - Digite o ano de vencimento
                  // 254 - Digite o ano de vencimento
                  // 255 - Digite o código de segurança
                  // 256 - Digite a quantidade de parcelas
                  // 256 - Digite a quantidade de parcelas
                  // 257 - Cartão inválido
                  // 258 - Cartão vencido
                  // 259 - Cobrança paga com sucesso
                  // 277 - Essa cobrança já foi paga
                  'essa cobranca ja foi paga': 'Essa cobrança já foi paga',
                  // 185 - Occorreu um erro, tente novamente mais tarde
                  // 261 - Pagamento por cartão
                  // 262 - Informações da cobrança
                  // 278 - Instituição
                  'instituicao': 'Instituição',
                  // 279 - Descrição
                  'descricao': 'Descrição',
                  // 222 - Valor base
                  // 226 - Parcelas
                  // 266 - Número do cartão
                  // 267 - Nome escrito no cartão
                  // 268 - CPF do dono do cartão
                  // 269 - Mês de vencimento
                  // 270 - Ano de vencimento
                  // 226 - Parcelas
                  // 271 - Pagar

                  // nmpay/EducationalPayments/studentPage/index
                  // 280 - Mensalidade paga com sucesso utilizando o saldo da sua conta
                  'mensalidade paga com sucesso utilizando o saldo da sua conta': 'Mensalidade paga com sucesso utilizando o saldo da sua conta',
                  // 281 - Ocorreu um erro ao pagar utilizando o saldo da conta
                  'ocorreu um erro ao pagar utilizando o saldo da conta': 'Ocorreu um erro ao pagar utilizando o saldo da conta',
                  // 228 - Copiar código de barras
                  // 35 - Linha Digitável
                  // 36 - Linha digitável copiada com sucesso
                  // 282 - Visualizar o recibo do pagamento
                  'visualizar o recibo do pagamento': 'Visualizar o recibo do pagamento',
                  // 283 - Visualizar recibo
                  'visualizar recibo': 'Visualizar recibo',
                  // 229 - NÃO PAGO
                  // 284 - Realizar pagamento com cartão de crédito
                  'realizar pagamento com cartao de credito': 'Realizar pagamento com cartão de crédito',
                  // 285 - Pagar com cartão
                  'pagar com cartao': 'Pagar com cartão',
                  // 286 - Realizar pagamento com saldo da sua conta NMPay
                  'realizar pagamento com saldo da sua conta nmpay': 'Realizar pagamento com saldo da sua conta NMPay',
                  // 287 - Pagar com saldo
                  'pagar com saldo': 'Pagar com saldo',
                  // 288 - Visualizar o boleto para pagamento
                  'visualizar o boleto para pagamento': 'Visualizar o boleto para pagamento',
                  // 289 - Visualizar boleto
                  'visualizar boleto': 'Visualizar boleto',
                  // 282 - Visualizar o recibo do pagamento
                  // 283 - Visualizar recibo
                  // 230 - Mensalidades
                  // 232 - Pagos
                  // 233 - Pendentes
                  // 234 - Atrasados
                  // 235 - Avulsos
                  // 290 - Realizar pagamento avulso de boleto
                  'realizar pagamento avulso de boleto': 'Realizar pagamento avulso de boleto',
                  // 291 - Pagar boleto avulso
                  'pagar boleto avulso': 'Pagar boleto avulso',
                  // 131 - Depósito
                  // 241 - Nenhum pagamento para exibir
                  // 242 - Nome do pagador
                  // 244 - Faculdade
                  // 44 - Data de vencimento
                  // 245 - Data de pagamento
                  // 38 - Valor
                  // 226 - Parcelas
                  // 35 - Linha digitável
                  // 241 - Nenhum pagamento para exibir
                  // 279 - Descrição
                  // 44 - Data de vencimento
                  // 245 - Data de pagamento
                  // 222 - Valor base
                  // 247 - Valor de pagamento
                  // 292 - Valor pago
                  'valor pago': 'Valor pago',

                  // nmpay/EducationalPayments/index
                  // 293 - Aluno
                  'aluno': 'Aluno',
                  // 244 - Faculdade

                  //       // Payment
                  //       Payment/index
                  //       294 - A linha digitável deve ter no mínimo 44 números
                  'a linha digitavel deve ter no minimo 44 numeros': 'A linha digitável deve ter no mínimo 44 números',
                  // 295 - Não é possível realizar o pagamento. Titulo inválido ou já pago
                  'nao e possivel realizar o pagamento. titulo invalido ou ja pago': 'Não é possível realizar o pagamento. Titulo inválido ou já pago',
                  // 122 - O Valor é obrigatório
                  // 296 - Vencimento é obrigatório
                  'vencimento e obrigatorio': 'Vencimento é obrigatório',
                  // 122 - O Valor é obrigatório
                  // 297 - Pagamento efetuado com sucesso
                  'pagamento efetuado com sucesso': 'Pagamento efetuado com sucesso',
                  // 298 - Erro no pagamento
                  'erro no pagamento': 'Erro no pagamento',
                  // 299 - Pagamento efetuado!
                  'pagamento efetuado': 'Pagamento efetuado!',
                  // 300 - Pagamentos
                  'pagamentos': 'Pagamentos',
                  // 275 - Digite o código de barras:
                  // 35 - Linha Digitável
                  // 276 - Continuar
                  // 301 - Confira os dados do pagamento
                  'confira os dados do pagamento': 'Confira os dados do pagamento',
                  // 302 - Benefeciário
                  // 'beneficiario': 'Benefeciário',
                  // 303 - Benefeciário final
                  'beneficiario final': 'Benefeciário final',
                  // 304 - Cobrança para
                  'cobranca para': 'Cobrança para',
                  // 305 - Vence em
                  'vence em': 'Vence em',
                  // 306 - Valor do documento
                  'valor do documento': 'Valor do documento',
                  // 35 - Linha Digitável
                  // 200 - Multa
                  // 201 - Juros
                  // 307 - Valor final
                  'valor final': 'Valor final',
                  // 39 - vencimento
                  // 271 - Pagar
                  // 302 - Benefeciário
                  // 304 - Cobrança para
                  // 305 - Vence em
                  // 35 - Linha Digitável
                  // 307 - Valor final
                  // 271 - Pagar

                  //       // Register
                  //       /Register/PFUser/index.jsx
                  //       308 - Dados de Cadastro
                  'dados de cadastro': 'Dados de Cadastro',
                  // 136 - Não foi possível validar o documento enviado
                  // 137 - Para continuar envie outro documento
                  // 69 - O documento é obrigatório
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  'o nome deve ter no maximo 100 caracteres': 'O nome deve ter, no máximo, 100 caracteres',
                  // 68 - O nome é obrigatório
                  // 310 - A data de nascimento é obrigatória
                  'a data de nascimento e obrigatoria': 'A data de nascimento é obrigatória',
                  // 311 - Data inválida
                  'data invalida': 'Data inválida',
                  // 312 - O nome da mãe é obrigatório
                  'o nome da mae e obrigatorio': 'O nome da mãe é obrigatório',
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 313 - A declaração de renda é obrigatória
                  'a declaracao de renda e obrigatoria': 'A declaração de renda é obrigatória',
                  // 314 - CPF inválido
                  'cpf invalido': 'CPF inválido',
                  // 315 - Já existe uma conta associada a este documento
                  'ja existe uma conta associada a este documento': 'Já existe uma conta associada a este documento',
                  // 316 - Para continuar faça Login
                  'para continuar faca login': 'Para continuar faça Login',
                  // 317 - A senha é obrigatória
                  'a senha e obrigatoria': 'A senha é obrigatória',
                  // 318 - A senha deve ter, no mínimo, 8 caracteres
                  'a senha deve ter no minimo 8 caracteres': 'A senha deve ter, no mínimo, 8 caracteres',
                  // 319 - As senhas não conferem
                  'as senhas nao conferem': 'As senhas não conferem',
                  // 320 - A confirmação de senha é obrigatória
                  'a confirmacao de senha e obrigatoria': 'A confirmação de senha é obrigatória',
                  // 308 - Dados de cadastro
                  // 219 - EMAIL
                  // 321 - O texto deve ter no máximo 50 caracteres
                  'o texto deve ter no maximo 50 caracteres': 'O texto deve ter no máximo 50 caracteres',
                  // 322 - Por favor, preencha esse campo
                  'por favor preencha esse campo': 'Por favor, preencha esse campo',
                  // 323 - E-mail inválido
                  'email invalido': 'E-mail inválido',
                  // 82 - O e-mail é obrigatório
                  // 323 - E-mail inválido
                  // 324 - Já existe uma conta associada a este e-mail
                  'ja existe uma conta associada a este email': 'Já existe uma conta associada a este e-mail',
                  // 316 - Para continuar faça Login
                  // 325 - PHONE
                  'phone': 'PHONE',
                  // 326 - O celular é obrigatório
                  'o celular e obrigatorio': 'O celular é obrigatório',
                  // 327 - Insira um telefone válido
                  'insira um telefone valido': 'Insira um telefone válido',
                  // 48 - CEP
                  // 71 - CEP é obrigatório
                  // 328 - ADDRESS
                  'address': 'ADDRESS',
                  // 72 - Logradouro é obrigatório
                  // 77 - Número é obrigatório
                  // 76 - Bairro é obrigatório
                  // 329 - Cidade é obrigatório
                  'cidade e obrigatorio': 'Cidade é obrigatório',
                  // 330 - O Estado precisa ter 2 dígitos
                  'o estado precisa ter 2 digitos': 'O Estado precisa ter 2 dígitos',
                  // 74 - Estado é obrigatório
                  // 331 - N/A
                  'n/a': 'N/A',
                  // 332 - SELFIE
                  'selfie': 'SELFIE',
                  // 151 - Tipo de documento é obrigatório
                  // 333 - FRONT
                  'front': 'FRONT',
                  // 351 - Apenas números
                  'apenas numeros': 'Apenas números',
                  // 317 - A senha é obrigatória
                  // 'a senha e obrigatoria': 'A senha é obrigatória',
                  // 319 - As senhas não conferem
                  // 'as senhas nao conferem': 'As senhas não conferem',
                  // 320 - A confirmação de senha é obrigatória
                  // 'a confirmacao de senha e obrigatoria': 'A confirmação de senha é obrigatória',
                  // 360 - CPF
                  'cpf': 'CPF',
                  // 26 - Nome Completo
                  // 334 - Data de Nascimento
                  'data de nascimento': 'Data de Nascimento',
                  // 335 - Nome completo da mãe
                  'nome completo da mae': 'Nome completo da mãe',
                  // 336 - Você se declara Pessoa Exposta Politicamente (PEP)?
                  'voce se declara pessoa exposta politicamente': 'Você se declara Pessoa Exposta Politicamente (PEP)?',
                  // 337 - Por favor informe a sua renda mensal em Reais
                  'por favor informe a sua renda mensal em reais': 'Por favor informe a sua renda mensal em Reais',
                  // 141 - Próximo
                  // 338 - Crie uma senha de acesso
                  'crie uma senha de acesso': 'Crie uma senha de acesso',
                  // 339 - Dica: use oito caracteres combinando letras, números e símbolos para acessar o banco
                  'dica use oito caracteres combinando letras...': 'Dica: use oito caracteres combinando letras, números e símbolos para acessar o banco',
                  // 340 - Crie uma senha
                  'crie uma senha': 'Crie uma senha',
                  // 341 - Confirme a senha
                  'confirme a senha': 'Confirme a senha',
                  // 141 - Próximo
                  // 358 - Como você conheceu o (nome)?
                  'como voce conheceu o ': 'Como você conheceu o ',
                  // 141 - Próximo
                  // 357 - Digite seu e-mail
                  'digite seu email': 'Digite seu e-mail',
                  // 55 - E-mail
                  // 141 - Próximo
                  // 359 - Digite o seu celular
                  'digite o seu celular': 'Digite o seu celular',
                  // 56 - Celular
                  // 141 - Próximo
                  // 361 - Digite o seu CEP
                  'digite o seu cep': 'Digite o seu CEP',
                  // 48 - CEP
                  // 141 - Próximo
                  // 362 - Complete o seu Endereço
                  'complete o seu endereco': 'Complete o seu Endereço',
                  // 48 - CEP
                  // 49 - Logradouro
                  // 50 - Número
                  // 51 - Complemento
                  // 52 - Bairro
                  // 53 - Cidade
                  // 54 - Estado
                  // 141 - Próximo
                  // 138 - Envie sua selfie
                  // 139 - A foto precisa mostrar seu rosto inteiro e de olhos abertos
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 140 - Selecione um documento
                  // 363 - Documento
                  'documento': 'Documento',
                  // 364 - Carteira de Identidade
                  'carteira de identidade': 'Carteira de Identidade',
                  // 365 - Carteira de Motorista
                  'carteira de motorista': 'Carteira de Motorista',
                  // 141 - Próximo
                  // 142 - Envie foto da FRENTE da sua CNH
                  // 143 - Envie foto da FRENTE do seu RG
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 146 - Envie foto do VERSO da sua CNH
                  // 147 - Envie foto do VERSO do seu RG
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 366 - Crie uma senha de 4 dígitos para sua conta e cartão
                  'crie uma senha de 4 digitos...': 'Crie uma senha de 4 dígitos para sua conta e cartão',
                  // 367 - use apenas números
                  'use apenas numeros': 'use apenas números',
                  // 340 - Crie uma senha
                  // 341 - Confirme a senha
                  // 342 - Criar conta
                  'criar conta': 'Criar conta',
                  // 343 - Parabéns, sua conta foi aberta!
                  'parabens sua conta foi aberta': 'Parabéns, sua conta foi aberta!',
                  // 344 - Acessar
                  'acessar': 'Acessar',
                  // 345 - Erro ao criar conta
                  'erro ao criar conta': 'Erro ao criar conta',
                  // 346 - Usuário criado com sucesso
                  'usuario criado com sucesso': 'Usuário criado com sucesso',

                  // /Register/PJUser/index
                  // 308 - Dados de Cadastro
                  // 136 - Não foi possível validar o documento enviado
                  // 137 - Para continuar envie outro documento
                  // 69 - O documento é obrigatório
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 347 - O nome da empresa é obrigatório
                  'o nome da empresa e obrigatorio': 'O nome da empresa é obrigatório',
                  // 348 - O número de funcionários é obrigatório
                  'o numero de funcionarios e obrigatorio': 'O número de funcionários é obrigatório',
                  // 349 - O faturamento é obrigatório
                  'o faturamento e obrigatorio': 'O faturamento é obrigatório',
                  // 350 - CNPJ Inválido
                  'cnpj invalido': 'CNPJ Inválido',
                  // 315 - Já existe uma conta associada a este documento
                  // 316 - Para continuar faça Login
                  // 69 - O documento é obrigatório
                  // 68 - O nome é obrigatório
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 310 - A data de nascimento é obrigatória
                  // 311 - Data inválida
                  // 312 - O nome da mãe é obrigatório
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 314 - CPF inválido
                  // 317 - A senha é obrigatória
                  // 318 - A senha deve ter, no mínimo, 8 caracteres
                  // 319 - As senhas não conferem
                  // 320 - A confirmação de senha é obrigatória
                  // 308 - Dados de cadastro
                  // 219 - EMAIL
                  // 321 - O texto deve ter no máximo 50 caracteres
                  // 322 - Por favor, preencha esse campo
                  // 323 - E-mail inválido
                  // 82 - O e-mail é obrigatório
                  // 323 - E-mail inválido
                  // 324 - Já existe uma conta associada a este e-mail
                  // 316 - Para continuar faça Login
                  // 326 - O celular é obrigatório
                  // 327 - Insira um telefone válido
                  // 71- CEP é obrigatório
                  // 72 - Logradouro é obrigatório
                  // 77 - Número é obrigatório
                  // 76 - Bairro é obrigatório
                  // 329 - Cidade é obrigatório
                  // 330 - O Estado precisa ter 2 dígitos
                  // 74 - Estado é obrigatório
                  // 151 - Tipo de documento é obrigatório
                  // 351 - Apenas números
                  // 317 - A senha é obrigatória
                  // 319 - As senhas não conferem
                  // 320 - A confirmação de senha é obrigatória
                  // 351 - CNPJ
                  'cnpj': 'CNPJ',
                  // 352 - Nome da Empresa
                  'nome da empresa': 'Nome da Empresa',
                  // 353 - Número de funcionários
                  'numero de funcionarios': 'Número de funcionários',
                  // 354 - Faturamento anual (R$)
                  'faturamento anual': 'Faturamento anual (R$)',
                  // 141 – Próximo
                  // 355 - Insira as informações dos sócios, representantes, mandatários ou prepostos autorizados da empresa
                  'insira as informacoes dos socios, representantes...': 'Insira as informações dos sócios, representantes, mandatários ou prepostos autorizados da empresa',

                  // 356 - Insira as informações do sócio, representante, mandatário ou preposto autorizado da empresa
                  'insira as iformacoes do socio, representante...': 'Insira as informações do sócio, representante, mandatário ou preposto autorizado da empresa',

                  // 26 - Nome Completo
                  // 334 - Data de Nascimento
                  // 335 - Nome completo da mãe
                  // 141 - Próximo
                  // 338 - Crie uma senha de acesso
                  // 339 - Dica: use oito caracteres combinando letras, números e símbolos para acessar o banco
                  // 340 - Crie uma senha
                  // 341 - Confirme a senha
                  // 141 - Próximo
                  // 358 - Como você conheceu o (nome)?
                  // 141 - Próximo
                  // 357 - Digite seu e-mail
                  // 55 - E-mail
                  // 141 - Próximo
                  // 359 - Digite o seu celular
                  // 56 - Celular
                  // 141 - Próximo
                  // 361 - Digite o seu CEP
                  // 141 - Próximo
                  // 362 - Complete o seu Endereço
                  // 48 - CEP
                  // 49 - Logradouro
                  // 50 - Número
                  // 51 - Complemento
                  // 52 - Bairro
                  // 53 - Cidade
                  // 54 - Estado
                  // 141 - Próximo
                  // 138 - Envie sua selfie
                  // 139 - A foto precisa mostrar seu rosto inteiro e de olhos abertos
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 140 - Selecione um documento
                  // 363 - Documento
                  // 364 - Carteira de Identidade
                  // 365 - Carteira de Motorista
                  // 141 - Próximo
                  // 142 - Envie foto da FRENTE da sua CNH
                  // 143 - Envie foto da FRENTE do seu RG
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 146 - Envie foto do VERSO da sua CNH
                  // 147 - Envie foto do VERSO do seu RG
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 – Próximo

                  // 148 - Envie o documento principal de identificação/constituição da empresa. Ex: Contrato Social

                  // 363 - Documento
                  // 144 - Clique para fazer upload
                  // 145 - Excluir
                  // 141 - Próximo
                  // 366 - Crie uma senha de 4 dígitos para sua conta e cartão
                  // 367 - use apenas números
                  // 340 - Crie uma senha
                  // 341 - Confirme a senha
                  // 342 - Criar conta
                  // 343 - Parabéns, sua conta foi aberta!
                  // 344 - Acessar
                  // 345 - Erro ao criar conta
                  // 346 - Usuário criado com sucesso

                  // /Register/index
                  // 368 - O documento não é válido
                  'o documento nao e valido': 'O documento não é válido',
                  // 369 - Digite seu CPF/CNPJ para se cadastrar
                  'digite seu cpf/cnpj para se cadastrar': 'Digite seu CPF/CNPJ para se cadastrar',
                  // 25 – CPF / CNPJ
                  // 141 - Próximo

                  //       // Reset4DigitPassword
                  //       /Reset4DigitPassword/index
                  //       370 - O código é obrigatório
                  'o codigo e obrigatorio': 'O código é obrigatório',
                  // 371 - A senha é obrigatória e deve conter 4 números
                  'a senha e obrigatoria e deve conter 4 numeros': 'A senha é obrigatória e deve conter 4 números',
                  // 372 - A senha deve conter apenas números e quatro dígitos!
                  'a senha deve conter apenas numeros e quatro digitos': 'A senha deve conter apenas números e quatro dígitos!',
                  // 373 - As senhas devem ser idênticas!
                  'as senhas devem ser identicas': 'As senhas devem ser idênticas!',
                  // 374 - Erro ao resetar senha
                  'erro ao resetar senha': 'Erro ao resetar senha',
                  // 375 - Operação concluída
                  'operacao concluida': 'Operação concluída',
                  // 376 - Resete a sua senha de quatro dígitos
                  'resete a sua senha de quatro digitos': 'Resete a sua senha de quatro dígitos',
                  // 377 - Enviar SMS
                  'enviar sms': 'Enviar SMS',
                  // 378 - Um código foi enviado via SMS para o celular vinculado a esta conta
                  'um codigo foi enviado via sms para o celular...': 'Um código foi enviado via SMS para o celular vinculado a esta conta',
                  // 379 - Insira o código enviado por SMS
                  'insira o codigo enviado por sms': 'Insira o código enviado por SMS',
                  // 380 - Insira sua nova senha
                  'insira sua nova senha': 'Insira sua nova senha',
                  // 381 - Confirme a nova senha
                  'confirme a nova senha': 'Confirme a nova senha',
                  // 390 - Resetar senha
                  'resetar senha': 'Resetar senha',

                  //       // ResetUserPassword
                  //       /ResetUserPassword/index
                  //       391 - A senha deve conter no mínimo 8 caracteres
                  'a senha deve conter no minimo 8 caracteres': 'A senha deve conter no mínimo 8 caracteres',
                  // 320 - A confirmação de senha é obrigatória
                  // 392 - A senha e a confirmação não conferem
                  'a senha e a confirmacao nao conferem': 'A senha e a confirmação não conferem',
                  // 393 - A sua conta foi desbloqueada com sucesso e sua senha alterada!
                  'a sua conta foi desbloqueada com sucesso e sua senha alterada': 'A sua conta foi desbloqueada com sucesso e sua senha alterada!',
                  // 394 - Sua senha foi alterada com sucesso
                  'sua senha foi alterada com sucesso': 'Sua senha foi alterada com sucesso',
                  // 395 - Não foi possível alterar sua senha. Tente novamente
                  'nao foi possivel alterar sua senha. tente novamente': 'Não foi possível alterar sua senha. Tente novamente',
                  // 396 - Erro na validação
                  'erro na validacao': 'Erro na validação',
                  // 397 - Sucesso!
                  'sucesso': 'Sucesso!',
                  // 398 - Olá, (nome)!
                  'ola': 'Olá, ',
                  // 399 - Altere sua senha abaixo para desbloquear sua conta
                  'altere sua senha abaixo para desbloquear sua conta': 'Altere sua senha abaixo para desbloquear sua conta',
                  // 398 - Olá, (nome)!
                  // 400 - Altere sua senha abaixo
                  'altere sua senha abaixo': 'Altere sua senha abaixo',
                  // 401 - Sua nova senha
                  'sua nova senha': 'Sua nova senha',
                  // 381 - Confirme a nova senha
                  // 9 - Confirmar

                  //       // Settings
                  //       /Settings/AdjustLimitRequestModal/index
                  //       402 - Valor mínimo de R$?0,00
                  'valor minimo de...': 'Valor mínimo de R$?0,00',
                  // 403 - Valor máximo de R$?0,00
                  'valor maximo de...': 'Valor máximo de R$?0,00',
                  // 404 - Limite obrigatório
                  'limite obrigatorio': 'Limite obrigatório',
                  // 405 - Por favor preencha o campo de justificativa
                  'por favor preencha o campo de justificativa': 'Por favor preencha o campo de justificativa',
                  // 406 - Documento obrigatório
                  'documento obrigatorio': 'Documento obrigatório',
                  // 407 - Por favor anexe um documento de justificativa
                  'por favor anexe um documento de justificativa': 'Por favor anexe um documento de justificativa',
                  // 408 - Termo de autorização obrigatório
                  'termo de autorizacao obrigatorio': 'Termo de autorização obrigatório',
                  // 409 - Por favor anexe o termo de autorização
                  'por favor anexe o termo de autorizacao': 'Por favor anexe o termo de autorização',
                  // 410 - Solicitação realizada com sucesso
                  'solicitacao realizada com sucesso': 'Solicitação realizada com sucesso',
                  // 406 - Documento obrigatório
                  // 407 - Por favor anexe um documento de justificativa
                  // 408 - Termo de autorização obrigatório
                  // 409 - Por favor anexe o termo de autorização
                  // 260 - Ocorreu um erro, tente novamente mais tarde
                  // 411 - Valor desejado
                  'valor desejado': 'Valor desejado',
                  // 412 - Seu limite atual é de R$10,00
                  'seu limite atual e de...': 'Seu limite atual é de: ',
                  // 413 - Digite sua resposta
                  'digite sua resposta': 'Digite sua resposta',
                  // 414 - Justificativa
                  'justificativa': 'Justificativa',
                  // 415 - Anexar documento
                  'anexar documento': 'Anexar documento',

                  // 416 - Por exemplo: Contracheque, comprovante de renda, declaração de IR ou outro documento que justifique a transação
                  'por exemplo: contracheque, comprovante de renda...': 'Por exemplo: Contracheque, comprovante de renda, declaração de IR ou outro documento que justifique a transação',
                  // 417 - Por exemplo: Relação de faturamento, declaração de IR ou outro documento que justifique a transação
                  'por exemplo: relacao de faturamento, declaracao...': 'Por exemplo: Relação de faturamento, declaração de IR ou outro documento que justifique a transação',

                  // 418 - Anexar o termo de autorização de aumento de limite
                  'anexar o termo de autorizacao de aumento de limite': 'Anexar o termo de autorização de aumento de limite',

                  // 419 - Baixe o modelo do documento de termo de autorização clicando no link abaixo, e depois de preenche-lo anexe aqui
                  'baixe o modelo do documento de termo de autorizacao clicando no...': 'Baixe o modelo do documento de termo de autorização clicando no link abaixo, e depois de preenche-lo anexe aqui',
                  // 420 - Clique aqui para baixar o documento
                  'clique aqui para baixar o documento': 'Clique aqui para baixar o documento',
                  // 421 - Enviar pedido
                  'enviar pedido': 'Enviar pedido',

                  // /Settings/AnalysisRequestModal/index
                  // 422 - Aguarde, sua solicitação está em análise
                  'aguarde, sua solicitacao esta em analise': 'Aguarde, sua solicitação está em análise',
                  // 423 - Notificaremos por e-mail quando o status do pedido for alterado
                  'notificaremos por email quando o status do pedido for alterado': 'Notificaremos por e-mail quando o status do pedido for alterado',
                  // 424 - Entendi
                  'entendi': 'Entendi',

                  // /Settings/ApprovedRequestModal/index
                  // 425 - Seu ajuste de limite foi aprovado!
                  'seu ajuste de limite foi aprovado': 'Seu ajuste de limite foi aprovado!',
                  // 424 - Entendi

                  // /Settings/RejectedRequestModal/index
                  // 426 - Infelizmente, ainda não podemos aumentar seu limite
                  'infelizmente, ainda nao podemos aumentar seu limite': 'Infelizmente, ainda não podemos aumentar seu limite',
                  // 427 - Em breve você poderá tentar novamente
                  'em breve voce podera tentar novamente': 'Em breve você poderá tentar novamente',

                  // /Settings/index
                  // 428 - Ajustar limite
                  'ajustar limite': 'Ajustar limite',
                  // 429 - Em análise
                  'em analise': 'Em análise',
                  // 430 - Pedido recusado
                  'pedido recusado': 'Pedido recusado',
                  // 431 - Pedido aprovado
                  'pedido aprovado': 'Pedido aprovado',
                  // 432 - Tarifa por emissão de boleto
                  'tarifa pro emissao de boleto': 'Tarifa por emissão de boleto',
                  // 433 - R$?0,00 por boleto
                  '...por boleto': 'por boleto',
                  // 434 - Ativo
                  'ativo': 'Ativo',
                  // 435 - Tarifa por TED/DOC
                  'tarifa por ted/doc': 'Tarifa por TED/DOC',
                  // 436 - R$?0,00 por transação
                  '...por transacao': 'por transação',
                  // 437 - Tarifa por TEF
                  'tarifa por tef': 'Tarifa por TEF',
                  // 436 - R$?0,00 por transação
                  // 434 - Ativo
                  // 438 - Limite disponível
                  'limite disponivel': 'Limite disponível',
                  // 436 - R$?0,00 por transação
                  // 439 - Por favor reenvie os documentos
                  'por favor reenvie os documentos': 'Por favor reenvie os documentos',
                  // 440 - Configurações
                  'configuracoes': 'Configurações',
                  // 1 - Dados Cadastrais
                  // 114 - Nome
                  // 55 - E-mail
                  // 441 - Segurança
                  'seguranca': 'Segurança',
                  // 442 - Alterar senha de 4 dígitos
                  'alterar senha de 4 digitos': 'Alterar senha de 4 dígitos',
                  // 2 - Atualizar documentos
                  // 443 - Valores e tarifas
                  'valores e tarifas': 'Valores e tarifas',
                  // 117 - Tipo
                  // 38 - Valor
                  // 118 - Status

                  //       // Store
                  //       /Store/index
                  //       444 - O nome da sua loja é obrigatório
                  'o nome da sua loja e obrigatorio': 'O nome da sua loja é obrigatório',
                  // 444 - O nome da sua loja é obrigatório
                  // 445 - O nome da loja deve possuir no máximo 40 caracteres
                  'o nome da loja deve possuir no maximo 40 caracteres': 'O nome da loja deve possuir no máximo 40 caracteres',
                  // 446 - A senha deve ter pelo menos 8 dígitos
                  'a senha deve ter pelo menos 8 digitos': 'A senha deve ter pelo menos 8 dígitos',
                  // 447 - A senha é obrigatória e deve ter pelo menos 8 dígitos
                  'a senha e obrigatoria e deve ter pelo menos 8 digitos': 'A senha é obrigatória e deve ter pelo menos 8 dígitos',
                  // 448 - Confirme sua senha!
                  'confirme sua senha': 'Confirme sua senha!',
                  // 373 - As senhas devem ser idênticas!
                  // 84 - O telefone é obrigatório
                  // 449 - O telefone deve ser no formato (XX) 9XXXX-XXXX!
                  'o telefone deve ser no formato...': 'O telefone deve ser no formato (XX) 9XXXX-XXXX!',
                  // 450 - A descrição da loja é obrigatória e em forma de texto
                  'a descricao da loja e obrigatoria e em forma de texto': 'A descrição da loja é obrigatória e em forma de texto',
                  // 451 - A descrição da loja é obrigatória
                  'a descricao da loja e obrigatoria': 'A descrição da loja é obrigatória',
                  // 452 - A descrição da loja deve possuir no máximo 64 caracteres!
                  'a descricao da loja debe possuir no maximo 64 caracteres': 'A descrição da loja deve possuir no máximo 64 caracteres!',
                  // 453 - Insira um pagamento mínimo
                  'insira um pagamento minimo': 'Insira um pagamento mínimo',
                  // 454 - A rua do estabelecimento é obrigatória
                  'a rua do estabelecimento e obrigatoria': 'A rua do estabelecimento é obrigatória',
                  // 455 - O bairro do estabelecimento é obrigatório
                  'o bairro do estabelecimento e obrigatorio': 'O bairro do estabelecimento é obrigatório',
                  // 456 - O número do estabelecimento é obrigatório
                  'o numero do estabelecimento e obrigatorio': 'O número do estabelecimento é obrigatório',
                  // 457 - Numero possui formato errado
                  'numero possui formato errado': 'Numero possui formato errado',
                  // 458 - Numero possui pelo menos um dígito, apenas números!
                  'numero possui pelo menos um digito, apenas numeros': 'Numero possui pelo menos um dígito, apenas números!',
                  // 459 - O complemento deve ser um texto
                  'o complemento deve ser um texto': 'O complemento deve ser um texto',
                  // 460 - O cep deve ser um texto
                  'o cep deve ser um texto': 'O CEP deve ser um texto',
                  // 461 - O CEP do estabelecimento é obrigatório
                  'o cep do estabelecimento e obrigatorio': 'O CEP do estabelecimento é obrigatório',
                  // 462 - O cep deve estar no formato XXXXX-XXX
                  'o cep deve estar no formato...': 'O cep deve estar no formato XXXXX-XXX',
                  // 75 - A cidade é obrigatória
                  // 463 - A cidade do estabelecimento é obrigatória
                  'a cidade do estabelecimento e obrigatoria': 'A cidade do estabelecimento é obrigatória',
                  // 464 - A cidade deve ser descrita apenas por texto
                  'a cidade deve ser descrita apenas por texto': 'A cidade deve ser descrita apenas por texto',
                  // 74 - O estado é obrigatório
                  // 465 - O estado do estabelecimento é obrigatório
                  'o estado do estabelecimento e obrigatorio': 'O estado do estabelecimento é obrigatório',
                  // 466 - O estado deve ser descrito apenas por texto
                  'o estado deve ser descrito apenas por texto': 'O estado deve ser descrito apenas por texto',
                  // 467 - Brasil
                  'brasil': 'Brasil',
                  // 468 - Loja cadastrada com sucesso!
                  'loja cadastrada com sucesso': 'Loja cadastrada com sucesso!',
                  // 469 - Cadastro inválido. Verifique se todos os campos foram preenchidos corretamente
                  'cadastro invalido. verifique se todos os campos...': 'Cadastro inválido. Verifique se todos os campos foram preenchidos corretamente',
                  // 470 - Erro ao cadastrar a loja
                  'erro ao cadastrar a loja': 'Erro ao cadastrar a loja',
                  // 468 - Loja cadastrada com sucesso!
                  // 471 - Loja online
                  'loja online': 'Loja online',
                  // 472 - Acessar a sua loja
                  'acessar a sua loja': 'Acessar a sua loja',
                  // 473 - Crie a sua loja virtual
                  'crie a sua loja virtual': 'Crie a sua loja virtual',
                  // 474 - Escolha o nome da sua loja
                  'escolha o nome da sua loja': 'Escolha o nome da sua loja',
                  // 475 - Telefone para contato
                  'telefone para contato': 'Telefone para contato',
                  // 476 - Descrição da loja
                  'descricao da loja': 'Descrição da loja',
                  // 477 - Compra mínima(R$)
                  'compra minima...': 'Compra mínima(R$)',
                  // 338 - Crie uma senha de acesso
                  // 341 - Confirme a senha
                  // 48 - CEP
                  // 478 - Rua
                  'rua': 'Rua',
                  // 52 - Bairro
                  // 50 - Número
                  // 51 - Complemento
                  // 53 - Cidade
                  // 54 - Estado
                  // 479 - Confirmar cadastro
                  'confirmar cadastro': 'Confirmar cadastro',

                  //       // Support
                  //       /Support/index
                  //       480 - atendimento
                  'atendimento': 'atendimento',
                  // 481 - Logo
                  'logo': 'Logo',
                  // 482 - Para entrar em contato, utilize os meios de comunicação abaixo
                  'para entrar em contato, utilize...': 'Para entrar em contato, utilize os meios de comunicação abaixo',
                  // 55 - E-mail
                  // 483 - Telefone
                  'telefone': 'Telefone',
                  // 484 - Whatsapp
                  'whatsapp': 'Whatsapp',
                  // 485 - Clique aqui para enviar uma mensagem
                  'clique aqui para enviar uma mensagem': 'Clique aqui para enviar uma mensagem',

                  //       // Ted
                  //       /Ted/index
                  //       122 - O valor é obrigatório
                  // 69 - O documento é obrigatório
                  // 486 - O documento deve ter 11 ou 14 dígitos
                  'o documento deve ter 11 ou 14 digitos': 'O documento deve ter 11 ou 14 dígitos',
                  // 309 - O nome deve ter, no máximo, 100 caracteres
                  // 68 - O nome é obrigatório
                  // 487 - O banco é obrigatório
                  'o banco e obrigatorio': 'O banco é obrigatório',
                  // 488 - A agência precisa ter 4 digitos
                  'a agencia precisa ter 4 digitos': 'A agência precisa ter 4 digitos',
                  // 489 - Apenas números são aceitos
                  'apenas numeros sao aceitos': 'Apenas números são aceitos',
                  // 490 - A agência é obrigatória
                  'a agencia e obrigatoria': 'A agência é obrigatória',
                  // 489 - Apenas números são aceitos
                  // 491 - A conta é obrigatória
                  'a conta e obrigatoria': 'A conta é obrigatória',
                  // 489 - Apenas números são aceitos
                  // 67 - O dígito é obrigatório
                  // 492 - Sua transferência foi agendada com sucesso
                  'sua transferencia foi agendada com sucesso': 'Sua transferência foi agendada com sucesso',
                  // 493 - Transferência realizada com sucesso
                  'transferencia realizada com sucesso': 'Transferência realizada com sucesso',
                  // 494 - Operação permitida
                  'operacao permitida': 'Operação permitida',
                  // 495 - Excluir Favorito
                  'excluir favorito': 'Excluir Favorito',
                  // 496 - Tem certeza que deseja excluir esse favorito?
                  'tem certeza que deseja excluir esse favorito': 'Tem certeza que deseja excluir esse favorito?',
                  // 497 - Sim
                  'sim': 'Sim',
                  // 498 - Não
                  'nao': 'Não',
                  // 499 - Favorito removido!
                  'favorito removido': 'Favorito removido!',
                  // 500 - Favorito removido com sucesso
                  'favorito removido com sucesso': 'Favorito removido com sucesso',
                  // 501 - Transferências
                  'transferencias': 'Transferências',
                  // 502 - Erro na transferência
                  'erro na transferencia': 'Erro na transferência',
                  // 503 - Transferência efetuada
                  'transferencia efetuada': 'Transferência efetuada',
                  // 504 - Agendar transferência
                  'agendar transferencia': 'Agendar transferência',
                  // 505 - Deseja agendar essa transferência para o próximo dia útil?
                  'deseja agendar essa transferencia para o proximo dia util': 'Deseja agendar essa transferência para o próximo dia útil?',
                  // 506 - Agendar
                  'agendar': 'Agendar',
                  // 507 - Não agendar
                  'nao agendar': 'Não agendar',
                  // 508 - Nova transferência
                  'nova transferencia': 'Nova transferência',
                  // 509 - Minhas contas
                  'minhas contas': 'Minhas contas',
                  // 145 - Excluir
                  // 510 - Favoritos
                  'favoritos': 'Favoritos',
                  // 511 - Nenhum favorito para exibir
                  'nenhum favorito para exibir': 'Nenhum favorito para exibir',
                  // 114 - Nome
                  // 27 - Banco
                  // 28 - Agência
                  // 29 - Conta
                  // 512 - Ações
                  'acoes': 'Ações',
                  // 513 - Transferir para este contato
                  'transferir para este contato': 'Transferir para este contato',
                  // 495 - Excluir favorito
                  // 26 - Nome completo
                  // 27 - Banco
                  // 28 - Agência
                  // 29 - Conta
                  // 30 - Dígito
                  // 38 - Valor
                  // 514 - Conta corrente
                  'conta corrente': 'Conta corrente',
                  // 515 - Conta poupança
                  'conta poupanca': 'Conta poupança',
                  // 516 - Adicionar aos favoritos
                  'adicional aos favoritos': 'Adicionar aos favoritos',
                  // 517 - Transferir
                  'transferir': 'Transferir',

                  //       // TokenLogin

                  //       // Transaction
                  //       /Transaction/Receipt/types/avulsePaymentNmPay
                  //       518 - Comprovante de pagamento de boleto avulso
                  'comprovante de pagamento de boleto avulso': 'Comprovante de pagamento de boleto avulso',
                  // 519 - Informações do pagamento
                  'informacoes do pagamento': 'Informações do pagamento',
                  // 35 - Linha Digitável
                  // 520 - Pagamento para
                  'pagamento para': 'Pagamento para',
                  // 521 - Pago por
                  'pago por': 'Pago por',
                  // 522 - Data da transação
                  'data da transacao': 'Data da transação',
                  // 523 - Código de autenticação
                  'codigo de autenticacao': 'Código de autenticação',

                  // /Transaction/Receipt/types/billet
                  // 585 - Comprovante de pagamento recebido
                  'comprovante de pagamento recebido': 'Comprovante de pagamento recebido',
                  // 519 - Informações do pagamento
                  // 586 - Nosso Número
                  'nosso numero': 'Nosso Número',
                  // 35 - Linha Digitável
                  // 39 - Vencimento
                  // 279 - Descrição
                  // 587 - Pago em
                  'pago em': 'Pago em',
                  // 588 - Crédito em/
                  'credito em': 'Crédito em',
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Receipt/types/chargePaymentNmPay
                  // 524 - Comprovante de pagamento de mensalidade
                  'comprovante de pagamento de mensalidade': 'Comprovante de pagamento de mensalidade',
                  // 519 - Informações do pagamento
                  // 35 - Linha Digitável
                  // 520 - Pagamento para
                  // 521 - Pago por
                  // 522 - Data da transação
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Receipt/types/payment
                  // 525 - Comprovante de pagamento
                  'comprovante de pagamento': 'Comprovante de pagamento',
                  // 520 - Pagamento para
                  // 521 - Pago por
                  // 114 - NOME
                  // 27 - BANCO
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Receipt/types/tedIn
                  // 526 - Comprovante de transferência recebida
                  'comprovante de transferencia recebida': 'Comprovante de transferência recebida',
                  // 527 - Origem
                  'origem': 'Origem',
                  // 114 - NOME
                  // 27 - BANCO
                  // 28 - AGÊNCIA
                  // 29 - CONTA
                  // 528 - Destino
                  'destino': 'Destino',
                  // 114 - NOME
                  // 363 - DOCUMENTO
                  // 27 - BANCO
                  // 28 - AGÊNCIA
                  // 29 - CONTA
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Receipt/types/tedOut
                  // 589 - Comprovante de transferência
                  'comprovante de transferencia': 'Comprovante de transferência',
                  // 527 - Origem
                  // 114 - NOME
                  // 27 - BANCO
                  // 28 - AGÊNCIA
                  // 29 - CONTA
                  // 528 - Destino
                  // 114 - NOME
                  // 363 - DOCUMENTO
                  // 27 - BANCO
                  // 28 - AGÊNCIA
                  // 29 - CONTA
                  // 38 - Valor
                  // 523 - Código de autenticação

                  // /Transaction/Scheduled/index
                  // 529 - Agendamento cancelado com sucesso
                  'agendamento cancelado com sucesso': 'Agendamento cancelado com sucesso',
                  // 530 - Não foi possível cancelar o agendamento. Tente novamente mais tarde
                  'nao foi possivel cancelar o agendamento. tente novamente mais tarde': 'Não foi possível cancelar o agendamento. Tente novamente mais tarde',
                  // 105 - PENDENTE
                  // 531 - ERRO AO EXECUTAR
                  'erro ao executar': 'ERRO AO EXECUTAR',
                  // 532 - EXECUTADO
                  'executado': 'EXECUTADO',
                  // 533 - As transações agendadas são realizadas no próximo dia útil
                  'as transacoes agendadas sao realizadas no proximo dia util': 'As transações agendadas são realizadas no próximo dia útil',
                  // 100 - De:
                  // 101 - Até:
                  // 534 - Nenhum agendamento para exibir
                  'nenhum agendamento para exibir': 'Nenhum agendamento para exibir',
                  // 535 - Nome do beneficiário
                  'nome do beneficiario': 'Nome do beneficiário',
                  // 536 - Documento do beneficiário
                  'documento do beneficiario': 'Documento do beneficiário',
                  // 27 - Banco
                  // 28 - Agência
                  // 29 - Conta
                  // 38 - Valor
                  // 118 - Status
                  // 537 - Data de execução
                  'data de execucao': 'Data de execução',
                  // 538 - Cancelar agendamento
                  'cancelar agendamento': 'Cancelar agendamento',
                  // 539 - Deseja mesmo cancelar esse agendamento?
                  'deseja mesmo cancelar esse agendamento': 'Deseja mesmo cancelar esse agendamento?',
                  // 497 - Sim
                  // 498 - Não
                  // 540 - carregar mais
                  'carregar mais': 'carregar mais',

                  // /Transaction/extract
                  // 100 - De:
                  // 101 - Até:
                  // 541 - Exportar para
                  'exportar para': 'Exportar para',
                  // 542 - Extrato
                  'extrato': 'Extrato',
                  // 543 - Nenhum lançamento para exibir
                  'nenhum lancamento para exibir': 'Nenhum lançamento para exibir',
                  // 540 - carregar mais

                  // /Transaction/index
                  // 542 - Extrato
                  // 544 - Agendamentos
                  'agendamentos': 'Agendamentos',

                  //       // Vibe
                  //       /vibe/Evibe/index
                  //       231 - Todos
                  // 545 - Crédito
                  'credito': 'Crédito',
                  // 546 - Débito
                  'debito': 'Débito',
                  // 89 - Boleto
                  // 547 - Não foi possível buscar os dados de venda
                  'nao foi possivel buscar os dados de venda': 'Não foi possível buscar os dados de venda',
                  // 105 - Pendente
                  // 231 - Todos
                  // 548 - Repassados
                  'repassados': 'Repassados',
                  // 233 - Pendentes
                  // 549 - Tipo de pagamento
                  'tipo de pagamento': 'Tipo de pagamento',
                  // 550 - Data da venda
                  'data de venda': 'Data da venda',
                  // 100 - De:
                  // 101 - Até:
                  // 551 - Data do repasse
                  'data do repasse': 'Data do repasse',
                  // 100 - De:
                  // 101 - Até:
                  // 552 - Nenhuma venda para exibir
                  'nenhuma venda para exibir': 'Nenhuma venda para exibir',
                  // 553 - Nome do cliente
                  'nome do cliente': 'Nome do cliente',
                  // 38 - Valor
                  // 554 - Valor Repassado
                  'valor repassado': 'Valor Repassado',
                  // 555 - Natureza da Venda
                  'natureza da venda': 'Natureza da Venda',
                  // 556 - Situação
                  'situacao': 'Situação',
                  // 226 - Parcelas
                  // 550 - Data da venda
                  // 551 - Data do repasse

                  //       // Withdraw
                  //       /Withdraw/index
                  //       557 - Desabilitar ponto de saque
                  'desabilitar ponto de saque': 'Desabilitar ponto de saque',
                  // 558 - Deseja prosseguir com a desativação do ponto de saque?
                  'deseja prosseguir com a desativacao do ponto de saque': 'Deseja prosseguir com a desativação do ponto de saque?',
                  // 559 - Desativar ponto de saque
                  'desativar ponto de saque': 'Desativar ponto de saque',
                  // 560 - Cancelar
                  'cancelar': 'Cancelar',
                  // 65 - O valor deve ser maior ou igual a R$0,10
                  // 561 - O nome do ponto de saque é obrigatório
                  'o nome do ponto de saque e obrigatorio': 'O nome do ponto de saque é obrigatório',
                  // 562 - O logradouro do ponto de saque é obrigatório
                  'o logradouro do ponto de saque e obrigatorio': 'O logradouro do ponto de saque é obrigatório',
                  // 563 - O nº é obrigatório
                  'o n e obrigatorio': 'O nº é obrigatório',
                  // 564 - O bairro do ponto de saque é obrigatório
                  'o bairro do ponto de saque e obrigatorio': 'O bairro do ponto de saque é obrigatório',
                  // 565 - O valor máximo de saque é obrigatório
                  'o valor maximo de saque e obrigatorio': 'O valor máximo de saque é obrigatório',
                  // 566 - O horário de início é obrigatório
                  'o horario de inicio e obrigatorio': 'O horário de início é obrigatório',
                  // 567 - O horário final é obrigatório
                  'o horario final e obrigatorio': 'O horário final é obrigatório',
                  // 568 - Hora digitada inválida
                  'hora digitada invalida': 'Hora digitada inválida',
                  // 569 - Minuto digitado inválido
                  'minuto digitado invalido': 'Minuto digitado inválido',
                  // 570 - Hora inicial digitada é maior que a hora final
                  'hora inicial digitada e maior que a hora final': 'Hora inicial digitada é maior que a hora final',
                  // 65 - O valor deve ser maior ou igual a R$0,10
                  // 571 - Saques
                  'saques': 'Saques',
                  // 572 - Habilitar-se como ponto de saque
                  'habilitar-se como ponto de saque': 'Habilitar-se como ponto de saque',
                  // 573 - Nome de identificação do ponto de saque
                  'nome de identificacao do ponto de saque': 'Nome de identificação do ponto de saque',
                  // 574 - Logradouro ponto de saque
                  'logradouro ponto de saque': 'Logradouro ponto de saque',
                  // 575 - Nº
                  // 'numero': 'Nº',
                  // 52 - Bairro
                  // 576 - Valor máximo para saques
                  'valor maximo para saques': 'Valor máximo para saques',
                  // 577 - Taxa cobrada por saque
                  'taxa cobrada por saque': 'Taxa cobrada por saque',
                  // 578 - Horários para saques
                  'horarios para saques': 'Horários para saques',
                  // 579 - Horário início para saque
                  'horario inicio para saque': 'Horário início para saque',
                  // 580 - Horário final para saque
                  'horario final para saque': 'Horário final para saque',
                  // 581 - Habilitar ponto de saque
                  'habilitar ponto de saque': 'Habilitar ponto de saque',
                  // 582 - Saque
                  'saque': 'Saque',
                  // 583 - Digite o valor abaixo. O código pode ser lido no aplicativo, no menu &quot;Saque&quot
                  'digite o valor abaixo. o codigo pode ser lido no aplicativo...': 'Digite o valor abaixo. O código pode ser lido no aplicativo, no menu &quot;Saque&quot',
                  // 38 - Valor
                  // 584 - Gerar QRCode
                  'gerar qrcode': 'Gerar QRCode',
                  // 557 - Desabilitar ponto de saque

                                    // adicionando componentes
                  // src/components/DynamicList/index.jsx
'nenhuma informacao': 'Nenhuma informação',

// src/components/Header/index.jsx
'sem novas notificacoes': 'Sem novas notificações',
// 'banco': 'Banco',
// 'agencia': 'Agência',
// 'conta corrente': 'Conta Corrente',
'por favor atualize os documentos': 'Por favor atualize os documentos',

// src/components/Menu/index.jsx
// 'extrato': 'Extrato',
'depositar': 'Depositar',
// 'transferir': 'Transferir',
// 'pagar': 'pagar',
'cobrar': 'Cobrar',
// 'configuracoes': 'Configurações',
'loja': 'Loja',
// 'loja online': 'Loja online',
// 'saque': 'Saque',
'sair': 'Sair',

// src/components/Modals/SendDocuments/index.jsx
'e necessario atualizar seus documentos': 'É necessário atualizar seus documentos',
'enviar documentos': 'Enviar documentos',

// src/components/Notification/index.jsx
'ok': 'OK',

// src/components/Password/index.jsx
'digite a senha do seu cartao': 'DIGITE A SENHA DO SEU CARTÃO',
'para confirmar a transacao...': 'Para confirmar a transação digite sua senha de 4 dígitos utilizada no cartão',
'concluir': 'Concluir',

// src/components/PopOverPrevious/index.jsx
'pesquisar cobrancas anteriores': 'Pesquisar cobranças anteriores',
'nao foi encontrado nenhum resultado': 'Não foi encontrado nenhum resultado',

// src/components/RegisterHeader/index.jsx
'dados de contato': 'Dados de Contato',
'envio de documentos': 'Envio de documentos',
// 'dados de contato': 'Dados de Contato',
// 'envio de documentos': 'Envio de documentos',
// 'dados de cadastro': 'Dados de Cadastro',

// src/components/Textarea/index.jsx
'caracteres restantes': 'Caracteres restantes',

                  // components
                  // Balance

                  // BreadCumb

                  // Button

                  // ButtonText

                  // Card

                  // CustomFileIcon

                  // DynamicList

                  // Header
                  // 'Notification': 'Sem novas notificações.',

                  // Input

                  // Loading

                  // Menu
                  // 'transferir': 'Extrato',
                  // 'depositar': 'Depositar',
                  // 'transferir': 'Transferir',
                  // 'pagar': 'Pagar',
                  // 'cobrar': 'Cobrar',
                  // 'configuracoes': 'Configurações',
                  // 'sair': 'Sair',

                  // Modals

                  // Notification

                  // Password

                  // PopOverPrevious

                  // Radio

                  // RegisterHeader

                  // Stepper

                  // Textarea

            },
      },
};

i18n
      .use(initReactI18next)
      .init({
            resources,
            lng: 'ptbr',
            interpolation: {
                  escapeValue: false,
            },
      });

export default i18n;
