import React from 'react';
import { SettingsProvider } from './settings';
import { AuthProvider } from './auth';
import { StepsProvider } from './steps';
import { CheckpointProvider } from './checkpoint';
import { RefreshProvider } from './refresh';

// eslint-disable-next-line react/prop-types
function AppProvider({ children, settings }) {
  return (
    <RefreshProvider>
      <SettingsProvider>
        <AuthProvider settings={settings}>
          <StepsProvider>
            <CheckpointProvider>{children}</CheckpointProvider>
          </StepsProvider>
        </AuthProvider>
      </SettingsProvider>
    </RefreshProvider>
  );
}

export default AppProvider;
