/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';

import { FiChevronRight } from 'react-icons/fi';
import { Table } from 'antd';

import {
  Content, DividerContainer, Title, InputsContainer, SecurityItemsContainer,
  AdjustLimitsButton, StatusLink,
} from './styles';

import Input from '../../components/Input';
import api from '../../services/api';

import SendDocumentsModal from '../../components/Modals/SendDocuments';
import { useAuth } from '../../hooks/auth';
import AdjustLimitRequest from './AdjustLimitRequestModal';
import RejectedRequestModal from './RejectedRequestModal';
import ApprovedRequestModal from './ApprovedRequestModal';
import AnalysisRequestModal from './AnalysisRequestModal';

function Settings() {
  const history = useHistory();
  const { Column } = Table;
  const { user, account } = useAuth();
  const { t } = useTranslation();

  const [openSendDocumentsModal, setOpenSendDocumentsModal] = useState(false);
  const [openAdjustLimitModal, setOpenAdjustLimitModal] = useState(false);
  const [openRejectedRequestModal, setOpenRejectedRequestModal] = useState(false);
  const [openApprovedRequestModal, setOpenApprovedRequestModal] = useState(false);
  const [openAnalysisRequestModal, setOpenAnalysisRequestModal] = useState(false);

  const [requestByAccount, setRequestByAccount] = useState([]);

  const getData = useCallback(() => {
    api.get(`/limit-change-requests/${account.id}`,
      {
        headers: {
          account: account.id,
          user: user.id,
          authorization: `Bearer ${localStorage.getItem('@qesh:token')}`,
        },
      }).then((response) => {
      setRequestByAccount(response.data);
    });
  }, [account.id, user.id]);

  useEffect(() => {
    getData();
  }, [account, account.id, getData, user.id]);

  const formattedValuesAndFeesData = useMemo(() => {
    let statusRequest;

    if (requestByAccount.length === 0) {
      statusRequest = (
        <div style={{
          flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <AdjustLimitsButton type="button" onClick={() => setOpenAdjustLimitModal(true)}>
            <span>{t('ajustar limite')}</span>
          </AdjustLimitsButton>
        </div>
      );
    } else if (requestByAccount[0].status === 'IN_ANALYSIS') {
      statusRequest = (
        <div style={{
          flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <StatusLink onClick={() => setOpenAnalysisRequestModal(true)}>
            {t('em analise')}
          </StatusLink>
        </div>
      );
    } else if (requestByAccount[0].status === 'REJECTED') {
      statusRequest = (
        <div style={{
          flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <StatusLink onClick={() => setOpenRejectedRequestModal(true)}>
            {t('pedido recusado')}
          </StatusLink>
        </div>
      );
    } else {
      statusRequest = (
        <div style={{
          flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <StatusLink onClick={() => setOpenApprovedRequestModal(true)}>
            {t('pedido aprovado')}
          </StatusLink>
        </div>
      );
    }

    return [
      {
        type: t('tarifa pro emissao de boleto'),
        value: `${Number(account.bill_fee).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })} ${t('...por boleto')}`,
        status: (
          <div style={{
            flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          > <span>{t('ativo')}</span>
          </div>
        ),
      },
      {
        type: t('tarifa por ted/doc'),
        value: `${Number(account.ted_fee).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })} ${t('...por transacao')}`,
        status: (
          <div style={{
            flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          > <span>{t('ativo')}</span>
          </div>
        ),
      },
      {
        type: t('tarifa por tef'),
        value: `${Number(account.tef_fee).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })} ${t('...por transacao')}`,
        status: (
          <div style={{
            flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          > <span>{t('ativo')}</span>
          </div>
        ),
      },
      {
        type: t('Tarifa de recebimento PIX'),
        value: `${Number(account.pix_receivement_fee).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })} ${t('...por transacao')}`,
        status: (
          <div style={{
            flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          > <span>{t('ativo')}</span>
          </div>
        ),
      },
      {
        type: t('Tarifa de envio PIX'),
        value: `${Number(account.pix_fee).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })} ${t('...por transacao')}`,
        status: (
          <div style={{
            flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          > <span>{t('ativo')}</span>
          </div>
        ),
      },
      {
        type: t('Tarifa de QRcode PIX'),
        value: `${Number(account.pix_qrcode_fee).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })} ${t('...por transacao')}`,
        status: (
          <div style={{
            flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          > <span>{t('ativo')}</span>
          </div>
        ),
      },
      {
        type: t('limite disponivel'),
        value: `${account.limit_ted.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })} ${t('...por transacao')}`,
        status: statusRequest,
      },
    ];
  }, [account.pix_fee, account.pix_qrcode_fee, account.pix_receivement_fee,
    account.bill_fee, account.limit_ted, account.ted_fee, account.tef_fee, requestByAccount, t]);

  return (
    <>
      <Content />
      <SendDocumentsModal
        visible={!!openSendDocumentsModal}
        hidden={() => setOpenSendDocumentsModal(false)}
        title={t('por favor reenvie os documentos')}
      />

      <AdjustLimitRequest
        visible={!!openAdjustLimitModal}
        hidden={() => {
          setOpenAdjustLimitModal(false);
          getData();
        }}
        currentLimit={account.limit_ted}
      />

      <RejectedRequestModal
        visible={!!openRejectedRequestModal}
        hidden={() => {
          setOpenRejectedRequestModal(false);
          getData();
        }}
        currentLimit={account.limit_ted}
      />

      <ApprovedRequestModal
        visible={!!openApprovedRequestModal}
        hidden={() => {
          setOpenApprovedRequestModal(false);
          getData();
        }}
        currentLimit={account.limit_ted}
      />

      <AnalysisRequestModal
        visible={!!openAnalysisRequestModal}
        hidden={() => {
          setOpenAnalysisRequestModal(false);
          getData();
        }}
        currentLimit={account.limit_ted}
      />

      <Title>{t('configuracoes')}</Title>

      <Form initialData={user} style={{ maxWidth: '80%', paddingBottom: 50 }}>
        <div style={{ marginBottom: '36px', marginTop: '26px' }}>
          <DividerContainer>
            <div />
            <span>{t('dados cadastrais')}</span>
          </DividerContainer>

          <InputsContainer>
            <Input
              disabled
              name="name"
              id="name"
              label={t('nome')}
            />
            <Input
              disabled
              name="email"
              id="email"
              label={t('email')}
            />
          </InputsContainer>
        </div>

        <div style={{ marginBottom: '42px' }}>
          <DividerContainer>
            <div />
            <span>{t('seguranca')}</span>
          </DividerContainer>

          <SecurityItemsContainer onClick={() => history.push('/reset-4digit')}>
            <span>{t('alterar senha de 4 digitos')}</span>
            <FiChevronRight size={28} onClick={() => history.push('/reset-4digit')} />
          </SecurityItemsContainer>

          <SecurityItemsContainer onClick={() => history.push('documents')}>
            <span>{t('atualizar documentos')}</span>
            <FiChevronRight size={28} onClick={() => { history.push('documents'); }} />
          </SecurityItemsContainer>
        </div>

        <div style={{ marginBottom: '42px' }}>
          <DividerContainer>
            <div />
            <span>{t('valores e tarifas')}</span>
          </DividerContainer>
          <Table pagination={false} rowKey="type" dataSource={formattedValuesAndFeesData}>
            <Column
              style={{ fontWeight: '700' }}
              title={t('tipo')}
              dataIndex="type"
              key="type"
              responsive={['xs', 'sm', 'md', 'xl']}
            />

            <Column
              style={{ fontWeight: '700' }}
              title={t('valor')}
              dataIndex="value"
              key="value"
              responsive={['xs', 'sm', 'md', 'xl']}
            />

            <Column
              style={{ fontWeight: '700' }}
              title={(
                <div style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'center',
                }}
                >
                  {t('status')}
                </div>
              )}
              dataIndex="status"
              key="status"
              responsive={['xs', 'sm', 'md', 'xl']}
            />

          </Table>
        </div>

      </Form>
    </>
  );
}

export default Settings;
