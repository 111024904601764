/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Modal as ModalAntd, Radio, Input, Space, Divider,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { Container, StyledInput } from './styles';
import { AntProps, DefaultAntProps } from './Types';

const Modal = (props) => {
  const {
    className, list, label, id, placeholder, value, onChange, title, subTitle, needSearchInput, disabled,
  } = props;
  const [searchInput, setSearchInput] = useState('');
  const [visible, setVisible] = useState(false);
  const [choosedOption, setChoosedOption] = useState(null);
  const { Search } = Input;
  const content = list
    .filter((option) => (searchInput === '' ? true : option.name.toLowerCase().includes(searchInput.toLowerCase())))
    .map((option) => (
      <Radio key={option.id} autoFocus={option.id === choosedOption} color="--primary" value={option.id}>
        {option.name}
      </Radio>
    ));
  const handleChoice = (id) => {
    const optionString = list.filter((option) => {
      if (option.id === id) {
        return `${option.id} - ${option.name ? option.name : ''}`;
      }
    });
    onChange(optionString);
    setChoosedOption(id);
    setSearchInput('');
    setVisible(false);
  };
  return (
    <>
      <Container>
        {label && <label htmlFor={id}>{label}</label>}
        <StyledInput
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (!disabled) {
              setVisible(true);
            }
          }}
          className={`ant-input ${className}`}
          disabled={disabled}
        >
          <input
            style={{ pointerEvents: 'none' }}
            value={value}
            placeholder={placeholder}
            onChange={(e) => e.preventDefault()}
            disabled
          />
          <FontAwesomeIcon icon={faAngleDown} />
        </StyledInput>
      </Container>
      <ModalAntd
        key={`${title}`}
        visible={visible}
        footer={null}
        closable={false}
        destroyOnClose
        focusTriggerAfterClose={false}
        onCancel={() => {
          setVisible(false);
        }}
      >
        {title}
        {subTitle}
        {needSearchInput && (
        <Search
          placeholder={placeholder}
          onChange={(event) => setSearchInput(event.target.value)}
          style={{
            width: '100%',
            height: '30%',
            marginBottom: '1.5rem',
            padding: '0 3rem',
          }}
        />
        )}
        <div
          style={{
            height: 'min(90%, 31.25rem)',
            maxHeight: 'min(31.25rem, 90vh)',
            overflow: 'auto',
            padding: '3rem',
          }}
        >
          <Radio.Group
            onChange={(event) => handleChoice(event.target.value)}
            value={choosedOption}
            style={{ width: '100%' }}
          >
            <Space
              style={{
                gap: '0px', display: 'flex', flexFlow: 'column nowrap', width: '100%',
              }}
              split={<Divider orientation="center" type="horizontal" style={{ minWidth: '100%' }} />}
              wrap
              align="left"
            >
              {content}
            </Space>
          </Radio.Group>
        </div>
      </ModalAntd>
    </>
  );
};
Modal.propTypes = {
  ...AntProps,
  content: PropTypes.node,
};

Modal.defaultProps = {
  ...DefaultAntProps,
};

export default Modal;
