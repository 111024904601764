import React from 'react';
import QRCode from 'react-qr-code';
import { Row } from '../../../../styles/components';
import { useSettings } from '../../../../hooks/settings';

const NoWebcam = () => {
  const { settings } = useSettings();

  return (
    <>
      <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 0 }}>
        <h1>Você pode continuar pelo celular!</h1>
      </Row>
      <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 20 }}>
        <h2 style={{ fontSize: '16px' }}>
          Aponte a câmera do seu celular para o código QR abaixo e continue a abertura da sua conta pelo app.
        </h2>
      </Row>
      <QRCode size={200} value={`${settings.ib_url}/invite`} />
    </>
  );
};

export default NoWebcam;
