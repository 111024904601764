/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Container, Title, Text, ButtonDisposition, TextContainer,
} from './styles';
import ButtonN from '../../../../../../components/ButtonN';
import { useSteps } from '../../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../../hooks/checkpoint';
import LowerLeftMessage from '../../../../../../components/LowerLeftMessage';
import { useSettings } from '../../../../../../hooks/settings';

const ContaAprovadaPj = () => {
  const { resetStep } = useSteps();
  const { resetCheckpoint, checkpoint } = useCheckpoint({
    userName: '',
  });
  const { settings } = useSettings();

  return (
    <>
      <Container>
        <TextContainer>
          <FontAwesomeIcon icon={faCheck} className="fa-lg" />
          <Title>
            <span style={{ color: 'var(--primary)' }}>
              {checkpoint.cnpjData.corporateName || 'Empresa'}
            </span>
            , sua conta foi aprovada!
          </Title>
          <Text>
            {`Boas-vindas ao banco ${
              settings.name ? settings.name : 'Banco Cliente'
            }. Aqui você tem toda praticidade e comodidade na palma da sua mão.`}
          </Text>
        </TextContainer>
        <ButtonDisposition>
          <ButtonN
            onClick={() => {
              resetStep();
              resetCheckpoint();
            }}
            className="botao"
          >
            <Link to="/"> Acessar meu banco</Link>
          </ButtonN>
          <ButtonN
            onClick={() => {
              resetStep();
              resetCheckpoint();
            }}
            type="primary"
            className="botao"
          >
            <Link to="/"> Baixar o aplicativo</Link>
          </ButtonN>
        </ButtonDisposition>
        <LowerLeftMessage />
      </Container>
    </>
  );
};

export default ContaAprovadaPj;
