import styled from 'styled-components';

import { Layout, Dropdown } from 'antd';

const { Header } = Layout;

export const Container = styled(Header)`
  padding: 35px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;

  @media(max-width: 800px) {
    flex-direction: column;
    justify-content: start;
    padding: 10px;
    height: auto;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Notification = styled.button`
 border: none;
 position: relative;
 width: 2.813rem;
 height: 2.813rem;
 background: var(--primary);
 box-shadow: 0rem 0.188rem 0.375rem #abe6e94d;
 cursor: pointer;
 box-shadow: 5px 6px 11px -4px rgba(0, 0, 0, 0.55);
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 50%;
 color: #fff;

 @media (max-width: 50rem) {
 }
`;

export const Account = styled.div`
  display: flex;
  align-items: center;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 45px;
  height: 45px;
  border-radius: 50%;

  background: #121212;
  margin-right: 15px;
`;

export const BankAccount = styled.div`
  text-align: center;
  font-weight: 600;

  span {
    display: block;
    margin-bottom: 4px;
  }
`;

export const NotificationTitle = styled.div`
 font-weight: bold;
 font-size: 90%;
 max-width: 20rem;
`;
export const Title = styled.div`
 display: flex;
 font-weight: bold;
 font-size: 1.3rem;
 width: 100%;
 height: 3rem;
 align-items: center;
 justify-content: center;
`;
export const NotificationDate = styled.div`
 display: flex;
 gap: 0.3rem;
 font-size: 80%;
`;
export const Divider = styled.div`
 width: 100%;
 margin: 0.3rem 0;
 align-self: center;
 height: 0.01rem;
 background: #f4f4f4;
`;
