// import * as faceapi from 'face-api.js';
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useRef, useCallback, useEffect,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';
import { cpf } from 'cpf-cnpj-validator';
import * as Yup from 'yup';
import axios from 'axios';

import { FaFilePdf } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import WebcamDetectsSmile from './WebcamDetectsSmile';
import WebcamDetectsNeutral from './WebcamDetectsNeutral';
import api from '../../../services/api';
import Stepper from '../../../components/Stepper';
import '@tensorflow/tfjs-backend-webgl';
import { Content, FormContainer } from '../styles';
import Input from '../../../components/Input';
import RegisterHeader from '../../../components/RegisterHeader';
import { Row, Col } from '../../../styles/components';
import Button from '../../../components/Button';
import Notification from '../../../components/Notification';
import Loading from '../../../components/Loading';
import formErrors from '../../../services/formErrors';

import getErrors from '../../../services/errors';
// NEW MODEL
import { dateToISO, findGetParameter, generateHash } from '../../../services/utils';

import animationData from '../../../assets/open_account.json';
import { useSettings } from '../../../hooks/settings';
import NoWebcam from './NoWebcam';

function PFUser() {
  const { t } = useTranslation();
  const texto = t('dados de cadastro');
  const { settings } = useSettings();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [enablePreview, setEnablePreview] = useState(true);
  const [selfie, setSelfie] = useState(false);
  const [loadingSelfie, setLoadingSelfie] = useState(false);
  const formRef = useRef(null);
  const params = useParams();
  // const steps = ['BASIC_DATA', 'PASSWORD', 'EMAIL', 'PHONE', 'CEP', 'ADDRESS'];
  const [currentStep, setCurrentStep] = useState('BASIC_DATA');
  const [numPag, setNumPag] = useState(1);
  const [title, setTitle] = useState(texto);
  const [createUserData, setCreateUserData] = useState({});
  const [selfieStep, setSelfieStep] = useState('smile_pic');
  const [smileFace, setSmileFace] = useState();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getNameByDocument = useCallback(async () => {
    const splittedPathname = location.pathname.split('/');

    const urlDocumentValue = splittedPathname[splittedPathname.length - 1] === '/'
      ? splittedPathname[splittedPathname.length - 2]
      : splittedPathname[splittedPathname.length - 1];

    const value = formRef?.current?.getFieldValue('document') || urlDocumentValue;

    const valueInArray = value.split('');

    if (!valueInArray.includes('_')) {
      const formattedDocument = value.replace('.', '').replace('/', '').replace('-', '').replace('.', '');

      try {
        const b = `${settings.client_id}_${formattedDocument}_${format(new Date(), 'yyyy-MM-dd hh:mm')}`;
        const { data: userData } = await api.get(`api-id/${formattedDocument}`, {
          headers: {
            client: settings.client_id,
            hash: generateHash(b),
          },
        });
        formRef.current.setFieldValue('name', userData.name);
        formRef.current.setFieldValue('birth_date', format(parseISO(userData.birth_date), 'dd/MM/yyyy'));
        formRef.current.setFieldValue('mother_name', userData.mother_name);
      } catch (_err) {
        console.log(_err);
      }
    }
  }, [settings, formRef]);

  useEffect(() => {
    const stepQuery = findGetParameter('step');

    if (location.search && stepQuery) {
      setCurrentStep(stepQuery);
      setTitle(stepQuery);

      if (stepQuery === 'CEP') {
        setNumPag(2);
      } else if (stepQuery === 'SELFIE') {
        setNumPag(3);
      }
    }

    if (settings.client_id) {
      getNameByDocument();
    }
  }, [settings]);

  const beforeUpload = useCallback((file) => {
    setEnablePreview(true);
    if (file.type === 'application/pdf') {
      setEnablePreview(false);

      if (file.size > 33096064) {
        // message.error('No máximo 3MB por arquivo.');
        return false;
      }
    }

    return true;
  }, []);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = useCallback((info, _type) => {
    if (info.file.status === 'uploading') {
      setLoadingSelfie(true);
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setSelfie(imageUrl);
      });

      // setLoadingSelfie(false);
    } else if (info.file.status === 'error') {
      setError([
        t('nao foi possivel validar o documento enviado'),
        t('para continuar envie outro documento'),
      ]);
      setLoadingSelfie(false);
    }
  }, []);

  const handleNext = useCallback(async (data) => {
    switch (currentStep) {
      case 'BASIC_DATA':

        if (!termsAccepted) {
          return;
        }
        formRef.current.setErrors({});
        try {
          const birthdayRegex = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
          const schema = Yup.object().shape({
            document: Yup.string().required(t('o documento e obrigatorio')),
            name: Yup.string()
              .max(100, t('o nome deve ter no maximo 100 caracteres'))
              .required(t('o nome e obrigatorio')),
            birth_date: Yup.string()
              .required(t('a data de nascimento e obrigatoria'))
              .matches(birthdayRegex, t('data invalida')),
            mother_name: Yup.string()
              .required(t('o nome da mae e obrigatorio'))
              .max(100, t('o nome deve ter no maximo 100 caracteres')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const documentFormatted = String(data.document).replace(/[-,.]/g, '');

          setCreateUserData({
            ...createUserData,
            ...data,
            document: documentFormatted,
            birth_date: dateToISO(data.birth_date),
          });

          if (!cpf.isValid(documentFormatted)) {
            formRef.current.setErrors({
              document: t('cpf invalido'),
            });
            setError([t('cpf invalido')]);
            return;
          }

          setLoading(true);
          const { data: documentExists } = await api.get(
            `users/check?document=${documentFormatted}&client=${settings.name}`,
          );
          setLoading(false);
          if (documentExists && documentExists.response === true) {
            setError([
              t('ja existe uma conta associada a este documento'),
              t('para continuar faca login'),
            ]);
            return;
          }

          formRef.current.reset();
          setCurrentStep('PASSWORD');
          setTitle('PASSWORD');
        } catch (err) {
          console.log(err);
          setLoading(false);
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
            return;
          }
        }
        break;

      case 'PASSWORD':
        formRef.current.setErrors({});
        try {
          const schema = Yup.object().shape({
            password: Yup.string()
              .required(t('a senha e obrigatoria'))
              .min(8, t('a senha deve ter no minimo 8 caracteres')),
            password_confirmation: Yup.string().equals([data.password], t('as senhas nao conferem'))
              .required(t('A confirmação de senha é obrigatória')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setCreateUserData({ ...createUserData, ...data, password_confirmation: undefined });
          formRef.current.reset();
          if (settings.indication_analytics) {
            setCurrentStep('INDICATION');
            setTitle(t('dados de cadastro'));
          } else {
            setCurrentStep('EMAIL');
            setTitle('EMAIL');
          }
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
          }
        }
        break;

      case 'INDICATION':
        formRef.current.setErrors({});
        try {
          const schema = Yup.object().shape({
            indication: Yup.string().max(50, t('o texto deve ter no maximo 50 caracteres'))
              .required(t('por favor preencha esse campo')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setLoading(true);

          setCreateUserData({ ...createUserData, indication: data.indication });
          formRef.current.reset();
          setCurrentStep('EMAIL');
          setTitle('EMAIL');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
          }
        }
        break;

      case 'EMAIL':
        formRef.current.setErrors({});
        try {
          // eslint-disable-next-line max-len
          const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          const schema = Yup.object().shape({
            email: Yup.string()
              .email(t('email invalido'))
              .required(t('o email e obrigatorio'))
              .matches(emailRegex, t('email invalido')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setLoading(true);
          const { data: documentExists } = await api.get(
            `users/check?email=${data.email}&client=${settings.name}`,
          );
          setLoading(false);
          if (documentExists && documentExists.response === true) {
            setError([
              t('ja existe uma conta associada a este email'),
              t('para continuar faca login'),
            ]);
            return;
          }

          setCreateUserData({ ...createUserData, ...data });
          formRef.current.reset();
          setCurrentStep('PHONE');
          setTitle('PHONE');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
          }
        }
        break;

      case 'PHONE':
        formRef.current.setErrors({});
        try {
          const phoneRegex = /^(?:\+55)?\s?\(?0?[1-9][1-9]\)?\s?(?:9)?\s?\d{4}-?\d{4}$/;
          const schema = Yup.object().shape({
            phone: Yup.string()
              .required(t('o celular e obrigatorio'))
              .matches(phoneRegex, t('insira um telefone valido')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setLoading(true);
          const { data: user } = await api.post('/users', {
            ...createUserData, ...data, client_name: settings.name, origin: 'ib',
          });
          const { data: login } = await api.post('/login', {
            origin: 'ib',
            client_name: settings.name,
            document: createUserData.document,
            password: createUserData.password,
          });

          localStorage.setItem('@ib:register', login.token);

          setCreateUserData({
            ...createUserData, ...data, ...user, ...login,
          });
          setLoading(false);
          formRef.current.reset();
          setNumPag(2);
          setCurrentStep('CEP');
          setTitle('CEP');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
            return;
          }

          setError(getErrors(err));
        }
        break;

      case 'CEP':
        formRef.current.setErrors({});
        try {
          const schema = Yup.object().shape({
            zip_code: Yup.string().required(t('o cep e obrigatorio')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setLoading(true);
          const { data: address } = await axios.get(
            `https://viacep.com.br/ws/${data.zip_code}/json/unicode`,
          );
          setCurrentStep('ADDRESS');
          setTitle('ADDRESS');
          setTimeout(() => {
            formRef.current.setData({
              zip_code: data.zip_code,
              street: address.logradouro,
              neighborhood: address.bairro,
              city: address.localidade,
              state: address.uf,
            });
            setLoading(false);
          }, 100);

          setCreateUserData({ ...createUserData, ...data });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
            return;
          }

          setError(getErrors(err));
        }
        break;

      case 'ADDRESS':
        formRef.current.setErrors({});
        try {
          const schema = Yup.object().shape({
            zip_code: Yup.string().required(t('o cep e obrigatorio')),
            street: Yup.string().required(t('o logradouro e obrigatorio')),
            number: Yup.string().required(t('o numero e obrigatorio')),
            neighborhood: Yup.string().required(t('o bairro e obrigatorio')),
            city: Yup.string().required(t('cidade e obrigatorio')),
            state: Yup.string().length(2, t('o estado precisa ter 2 digitos'))
              .required(t('o estado e obrigatorio')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setLoading(true);
          const token = localStorage.getItem('@ib:register');
          await api.post(
            'users/address',
            {
              ...data,
              zip_code: String(data.zip_code).replace('-', ''),
              complement: data.complement ? data.complement : 'N/A',
            },
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            },
          );

          setCreateUserData({ ...createUserData, ...data });
          setLoading(false);
          setNumPag(3);
          setCurrentStep('SELFIE');
          setTitle('SELFIE');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
            return;
          }

          setError(getErrors(err));
        }
        break;

      case 'DOC_TYPE':
        formRef.current.setErrors({});
        try {
          const schema = Yup.object().shape({
            document_type: Yup.string().required(t('tipo de documento e obrigatorio')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setCreateUserData({ ...createUserData, ...data });
          setCurrentStep('FRONT');
          setTitle('FRONT');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
            return;
          }

          setError(getErrors(err));
        }
        break;

      case 'PASSW':
        formRef.current.setErrors({});
        try {
          const schema = Yup.object().shape({
            password_card: Yup.string().length(4).matches(/^[0-9]+$/, t('apenas numeros'))
              .required(t('a senha e obrigatoria')),
            password_card_confirmation: Yup.string().equals([data.password_card], t('as senhas nao conferem'))
              .required(t('a confirmacao de senha e obrigatoria')),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setCreateUserData({ ...createUserData, ...data });

          setLoading(true);
          const token = localStorage.getItem('@ib:register');
          await api.post(
            'accounts',
            {
              password: String(data.password_card),
              is_pool: !!localStorage.getItem('@is_pool'),
            },
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            },
          );

          formRef.current.reset();
          setCurrentStep('SUCCESS');
          setLoading(false);
          localStorage.removeItem('@is_pool');
        } catch (err) {
          setLoading(false);
          if (err instanceof Yup.ValidationError) {
            const errors = formErrors(err);
            formRef.current.setErrors(errors);
            return;
          }

          setError(getErrors(err));
        }
        break;

      default:
    }
    setLoading(false);
  }, [currentStep, formRef, createUserData, settings, termsAccepted]);

  const formContent = useCallback(() => {
    switch (currentStep) {
      case 'BASIC_DATA':
      default:
        return (
          <>
            {/* <Row gutter={16} style={{ justifyContent: 'left' }}>
              <h1>Para começar o seu cadastro complete os dados:</h1>
            </Row> */}
            <Row gutter={16}>
              <Col xs={24} sm={24} md={14} lg={14} xl={14} className="bottom">
                <Input
                  mask="999.999.999-99"
                  id="document"
                  name="document"
                  label={t('cpf')}
                  type="text"
                  onChange={(e) => getNameByDocument(e.target.value)}
                  defaultValue={params.document}
                  disabled
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Input
                  id="name"
                  name="name"
                  label={t('nome completo')}
                  type="text"
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10} className="bottom">
                <Input
                  mask="99/99/9999"
                  id="birth_date"
                  name="birth_date"
                  label={t('data de nascimento')}
                  type="text"
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Input
                  id="mother_name"
                  name="mother_name"
                  label={t('nome completo da mae')}
                  type="text"
                />
              </Col>
            </Row>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                value={termsAccepted}
                style={{
                  width: 20, height: 20, cursor: 'pointer', marginRight: 10,
                }}
                onClick={() => setTermsAccepted(!termsAccepted)}
              />
              <span style={{ fontSize: 16 }}>Li e aceito os termos descritos
                <a
                  style={{ marginTop: 0, padding: 0 }}
                  href={`${settings.ib_url}/terms`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{' '}AQUI
                </a>
              </span>
            </div>
            <Row gutter={16}>
              {!termsAccepted && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <span style={{ color: 'red' }}>* O aceite dos termos é obrigatório</span>
              </Col>
              )}

            </Row>
            <Row style={{ marginBottom: '40px', marginTop: '20px' }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>{t('proximo')}</span>
              </Button>
            </Row>
          </>
        );

      case 'PASSWORD':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <h1>{t('crie uma senha de acesso')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20, textAlign: 'center' }}>
              <h2>{t('dica use oito caracteres combinando letras...')}</h2>
            </Row>

            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Input
                  name="password"
                  id="password"
                  label={t('crie uma senha')}
                  type="password"
                  eye="true"
                />
              </Col>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Input
                  name="password_confirmation"
                  id="password_confirmation"
                  label={t('confirme a senha')}
                  type="password"
                  eye="true"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>{t('proximo')}</span>
              </Button>
            </Row>
          </>
        );

      case 'INDICATION':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                <Input
                  name="indication"
                  id="indication"
                  label={`${t('como voce conheceu o ')} ${settings.display_name}?`}
                  type="text"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>{t('proximo')}</span>
              </Button>
            </Row>
          </>
        );

      case 'EMAIL':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h1>{t('digite seu email')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                <Input
                  name="email"
                  id="email"
                  label={t('email')}
                  type="text"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>{t('proximo')}</span>
              </Button>
            </Row>
          </>
        );

      case 'PHONE':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h1>{t('digite o seu celular')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Input
                  mask="99 999999999"
                  name="phone"
                  id="phone"
                  label={t('celular')}
                  type="phone"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>{t('proximo')}</span>
              </Button>
            </Row>
          </>
        );

      case 'CEP':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h1>{t('digite o seu cep')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Input
                  mask="99999-999"
                  name="zip_code"
                  id="zip_code"
                  label={t('cep')}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>Próximo</span>
              </Button>
            </Row>
          </>
        );

      case 'ADDRESS':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h1>{t('complete o seu endereco')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <Input
                  mask="99999-999"
                  name="zip_code"
                  id="zip_code"
                  label={t('cep')}
                />
              </Col>
              <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                <Input
                  name="street"
                  id="street"
                  label={t('logradouro')}
                />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                <Input
                  name="number"
                  id="number"
                  label={t('numero')}
                />
              </Col>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Input
                  name="complement"
                  id="complement"
                  label={t('complemento')}
                />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Input
                  name="neighborhood"
                  id="neighborhood"
                  label={t('bairro')}
                />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Input
                  name="city"
                  id="city"
                  label={t('cidade')}
                />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <Input
                  name="state"
                  id="state"
                  label={t('estado')}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>{t('proximo')}</span>
              </Button>
            </Row>
          </>
        );

      case 'SELFIE':
        return (
          <>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
              {selfieStep === 'neutral_pic' && (
                <>
                  <WebcamDetectsNeutral
                    setNextStep={() => setCurrentStep('DOC_TYPE')}
                    setSelfieStep={setSelfieStep}
                    smileFace={smileFace}
                  />
                </>
              )}

              {selfieStep === 'smile_pic' && (
                <>
                  <WebcamDetectsSmile
                    setSelfieStep={setSelfieStep}
                    setSmileFace={setSmileFace}
                  />
                </>
              )}

              {selfieStep === 'no_webcam' && (
                <>
                  <NoWebcam />
                </>
              )}

              {/* <div>
                  <Upload
                    action="https://bank.qesh.ai/users/documents?type=SELFIE"
                    headers={{
                      authorization: `Bearer ${localStorage.getItem('@ib:register')}`,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, 'selfie')}
                  >
                    {selfie
                      ? (
                        <>
                          {enablePreview ? (
                            <img
                              src={selfie}
                              alt="Selfie"
                              style={{ width: '100%', marginTop: '60px', marginBottom: '60px' }}
                            />
                          ) : <FaFilePdf size={125} />}
                        </>
                      )
                      : (
                        <div>
                          <div style={{ marginBottom: '5px' }}>
                            {t('clique para fazer upload')}
                          </div>
                          {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                      )}

                  </Upload>
                </div> */}
            </div>
            {!!selfie && (
              <>
                <div style={{
                  marginBottom: '40px',
                  marginTop: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                }}
                >
                  <Button
                    type="button"
                    default
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setSelfie(false);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>{t('excluir')}</span>
                  </Button>

                  <Button
                    type="submit"
                    onClick={() => {
                      setSelfie(false);
                      setLoadingSelfie(false);
                      setCurrentStep('DOC_TYPE');
                    }}
                  >
                    <span>{t('proximo')}</span>
                  </Button>
                </div>
              </>
            )}
          </>
        );

      case 'DOC_TYPE':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h1>{t('selecione um documento')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                <Input
                  select
                  name="document_type"
                  id="document_type"
                  label={t('documento')}
                  options={[
                    { value: 'rg', label: t('carteira de identidade') },
                    { value: 'cnh', label: t('carteira de motorista') }]}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>{t('proximo')}</span>
              </Button>
            </Row>
          </>
        );

      case 'FRONT':
        // eslint-disable-next-line no-case-declarations
        const category = createUserData.document_type === 'cnh' ? 'DRIVER_LICENSE_FRONT'
          : 'IDENTITY_CARD_FRONT';
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              {createUserData.document_type === 'cnh' ? (
                <h1>{t('envie foto da frente da sua cnh')}</h1>
              ) : (
                <h1>{t('envie foto da frente do seu rg')}</h1>
              )}
            </Row>
            <>
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <div>
                  <Upload
                    action={`https://bank.qesh.ai/users/documents?type=${category}`}
                    headers={{
                      authorization: `Bearer ${localStorage.getItem('@ib:register')}`,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, 'selfie')}
                  >
                    {selfie
                      ? (
                        <>
                          {enablePreview ? (
                            <img
                              src={selfie}
                              alt="Selfie"
                              style={{ width: '100%', marginTop: '60px', marginBottom: '60px' }}
                            />
                          ) : <FaFilePdf size={125} />}
                        </>
                      )
                      : (
                        <div>
                          <div style={{ marginBottom: '5px' }}>
                            {t('clique para fazer upload')}
                          </div>
                          {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                      )}

                  </Upload>
                </div>

              </div>
            </>
            {!!selfie && (
              <>
                <div style={{
                  marginBottom: '40px',
                  marginTop: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                }}
                >
                  <Button
                    type="button"
                    default
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setSelfie(false);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>Excluir</span>
                  </Button>

                  <Button
                    type="submit"
                    onClick={() => {
                      setSelfie(false);
                      setLoadingSelfie(false);
                      setCurrentStep('VERSE');
                    }}
                  >
                    <span>{t('proximo')}</span>
                  </Button>
                </div>
              </>
            )}
          </>
        );

      case 'VERSE':
        // eslint-disable-next-line no-case-declarations
        const categoryVerse = createUserData.document_type === 'cnh' ? 'DRIVER_LICENSE_VERSE'
          : 'IDENTITY_CARD_VERSE';
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              {createUserData.document_type === 'cnh' ? (
                <h1>{t('envie foto do verso da sua cnh')}</h1>
              ) : (
                <h1>{t('envie foto do verso do seu rg')}</h1>
              )}
            </Row>
            <>
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <div>
                  <Upload
                    action={`https://bank.qesh.ai/users/documents?type=${categoryVerse}`}
                    headers={{
                      authorization: `Bearer ${localStorage.getItem('@ib:register')}`,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, 'selfie')}
                  >
                    {selfie
                      ? (
                        <>
                          {enablePreview ? (
                            <img
                              src={selfie}
                              alt="Selfie"
                              style={{ width: '100%', marginTop: '60px', marginBottom: '60px' }}
                            />
                          ) : <FaFilePdf size={125} />}
                        </>
                      )
                      : (
                        <div>
                          <div style={{ marginBottom: '5px' }}>
                            {t('clique para fazer upload')}
                          </div>
                          {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                      )}

                  </Upload>
                </div>

              </div>
            </>
            {!!selfie && (
              <>
                <div style={{
                  marginBottom: '40px',
                  marginTop: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                }}
                >
                  <Button
                    type="button"
                    default
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setSelfie(false);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>{t('excluir')}</span>
                  </Button>

                  <Button
                    type="submit"
                    onClick={() => {
                      setSelfie(false);
                      setLoadingSelfie(false);
                      setCurrentStep('PASSW');
                    }}
                  >
                    <span>{t('proximo')}</span>
                  </Button>
                </div>
              </>
            )}
          </>
        );

      case 'PASSW':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <h1>{t('crie uma senha de 4 digitos...')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20, textAlign: 'center' }}>
              <h2>{t('use apenas numeros')}</h2>
            </Row>

            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Input
                  name="password_card"
                  id="password_card"
                  maxLength="4"
                  label={t('crie uma senha')}
                  type="password"
                  eye="true"
                />
              </Col>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Input
                  name="password_card_confirmation"
                  id="password_card_confirmation"
                  maxLength="4"
                  label={t('confirme a senha')}
                  type="password"
                  eye="true"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button type="submit" style={{ marginLeft: 'auto' }}>
                <span>{t('criar conta')}</span>
              </Button>
            </Row>
          </>
        );

      case 'SUCCESS':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <h1>{t('parabens sua conta foi aberta')}</h1>
            </Row>
            {/* <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20, textAlign: 'center' }}>
              <h2>use apenas números</h2>
            </Row> */}

            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Lottie options={defaultOptions} height={314} width={348} />
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button
                type="button"
                style={{ margin: 'auto' }}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                <span>{t('acessar')}</span>
              </Button>
            </Row>
          </>
        );
    }
  }, [currentStep, enablePreview, selfie, loadingSelfie, handleChange, beforeUpload,
    createUserData.document_type, selfieStep, settings, termsAccepted,
  ]);

  return (
    <Content>
      <RegisterHeader title={title} />
      <FormContainer>
        <Notification
          type="error"
          title={t('erro ao criar conta')}
          visible={!!error}
          hidden={() => setError(false)}
        >
          {Array.isArray(error) && error.length > 0 ? (
            <>
              {error.map((e) => (
                <div key={e}>
                  {e}
                </div>
              ))}
            </>
          ) : (
            <>
              {error}
            </>
          )}
        </Notification>
        <Notification
          title={t('usuario criado com sucesso')}
          visible={!!success}
          hidden={() => setSuccess(false)}
        >
          {success}
        </Notification>
        <Loading hasLoading={loading} />
        <Form ref={formRef} onSubmit={handleNext}>
          {formContent()}
        </Form>
      </FormContainer>
      <Stepper numPag={numPag} />

    </Content>
  );
}

export default PFUser;
