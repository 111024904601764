export default function getErrors(error) {
  const hasBody = error.response && error.response.data;

  if (hasBody && error.response.data.error === 'Bad Request') {
    if (error.response.data.validation.body) {
      return error.response.data.validation.body.message;
    }

    if (error.response.data.validation.query) {
      return error.response.data.validation.query.message;
    }

    return 'Erro na validação dos parâmetros.';
  }

  if (hasBody && error.response.data.message) {
    return error.response.data.message;
  }

  if (hasBody && error.response.data === 'Too Many Requests') {
    return 'Muitas solicitações, agurde alguns segundos e tente novamente.';
  }

  return 'Erro desconhecido.';
}
