import api from '../api';

export const handleAdicionalData = (obj, token) => {
  const headers = {
    Authorization: `bearer ${token}`,
  };
  const finalObject = {
    monthly_income: obj.monthly_income[0].name,
    patrimony: obj.patrimony[0].name,
    relationship: obj.relationship[0].name,
    occupation: obj.occupation[0].name,
    education: obj.education[0].name,
    isPEP: obj.isPEP,
  };

  return api
    .post('onboarding/additional-data', finalObject, { headers })
    .then((response) => response.data)
    .catch((error) => {
      console.error('handleAdicionalData: ', error.response.data);
      return false;
    });
};
