/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-deprecated */
import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Webcam from 'react-webcam';
import { notification } from 'antd';
import * as faceapi from 'face-api.js';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer, Content, WebcamContainer, InfoContainer,
} from './styles';
import { Row } from '../../../../styles/components';
import Button from '../../../../components/Button';
import { useSettings } from '../../../../hooks/settings';

const WebcamDetectsSmile = (props) => {
  const minConfidenceFace = 0.5;

  const webcamRef = useRef(null);
  const { settings } = useSettings();
  const { t } = useTranslation();

  const [image, setImage] = useState();

  const notifyRenderer = useCallback((_command, payload) => {
    if (!payload.type) {
      return;
    }

    if (payload.type === 'not_recognized') {
      notification.error({ message: 'Não foi reconhecido nenhum rosto.' });

      return;
    }

    if (payload.type !== 'happy') {
      notification.error({ message: t('capture imagem sorrindo')/* 'Por favor capture uma imagem sorrindo.' */ });

      return;
    }

    const screenshot = webcamRef.current.getScreenshot({ width: 1920, height: 1080 });

    setImage(screenshot);
  }, [t]);

  const onReady = useCallback(() => {
    notifyRenderer('ready', {});
  }, [notifyRenderer]);

  const onExpression = useCallback((type) => {
    notifyRenderer('expression', { type });
  }, [notifyRenderer]);

  const loadNet = useCallback(async () => {
    const MODEL_URL = `${process.env.PUBLIC_URL}/models`;

    const detectionNet = faceapi.nets.ssdMobilenetv1;

    await detectionNet.load(MODEL_URL);
    await faceapi.loadFaceExpressionModel(MODEL_URL);
    await faceapi.loadAgeGenderModel(MODEL_URL);
    await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
    await faceapi.loadFaceLandmarkModel(MODEL_URL);
    await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
    await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
    await faceapi.loadFaceRecognitionModel(MODEL_URL);

    // setInterval(() => {
    //   detectExpressions();
    // }, 3000);

    return detectionNet;
  }, []);

  const detectExpressions = useCallback(async () => {
    try {
      await loadNet();
      const faceapiOptions = new faceapi.SsdMobilenetv1Options({ minConfidenceFace });

      const faceResult = await faceapi.detectSingleFace(webcamRef.current.video, faceapiOptions)
        .withFaceLandmarks().withFaceExpressions().withFaceDescriptor();

      onReady();

      if (typeof faceResult !== 'undefined') {
        let happiness = 0;
        let neutral = 0;
        let surprised = 0;
        let angry = 0;

        if (faceResult.expressions.happy) {
          happiness = faceResult.expressions.happy;

          props.setSmileFace(faceResult);
        }

        if (faceResult.expressions.neutral) {
          neutral = faceResult.expressions.neutral;
        }

        if (faceResult.expressions.surprised) {
          surprised = faceResult.expressions.surprised;
        }

        if (faceResult.expressions.angry) {
          angry = faceResult.expressions.angry;
        }

        if (happiness > 0.7) {
          onExpression('happy');
        } else if (neutral > 0.7) {
          onExpression('neutral');
        } else if (surprised > 0.7) {
          onExpression('surprised');
        } else if (angry > 0.7) {
          onExpression('angry');
        } else {
          onExpression('not_recognized');
        }
      } else {
        onExpression('not_recognized');
      }
    // eslint-disable-next-line no-empty
    } catch {}
  }, [onExpression, onReady, props, loadNet]);

  useEffect(() => {
    loadNet();

    navigator.mediaDevices.enumerateDevices()
      .then((res) => {
        const devices = res.filter(({ kind }) => kind === 'videoinput');

        if (devices.length <= 0) {
          props.setSelfieStep('no_webcam');
        }
      });
  }, [loadNet, props]);

  return (
    <Content>
      <WebcamContainer>
        {image ? (
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
          }}
          >
            <Row gutter={10} style={{ marginBottom: 20, justifyContent: 'center' }}>
              <h1>{t('foto ficou boa?')}</h1>
            </Row>

            <img alt="ksj" src={image} style={{ height: '306px' }} />

            <ButtonContainer>
              <Button
                onClick={() => { setImage(undefined); }}
                style={{ marginTop: '36px', marginRight: '6px' }}
              >
                {t('tirar outra')}
                {/* Tirar outra */}
              </Button>
              <Button
                onClick={() => props.setSelfieStep('neutral_pic')}
                style={{ marginTop: '36px' }}
              >
                {t('ficou boa')}
                {/* Ficou boa */}
              </Button>
            </ButtonContainer>
          </div>
        ) : (
          <>
            <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 0 }}>
              <h1>{t('envie foto sorrindo')}</h1>
            </Row>
            <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h2 style={{ fontSize: '16px' }}>
                {t('sorrindo descricao')}
                {/* Para garantir que somente você terá acesso a sua conta, tire uma foto sorrindo.
                Vamos lá? */}
              </h2>
            </Row>
            <Webcam
              onUserMediaError={() => { props.setSelfieStep('no_webcam'); }}
              ref={webcamRef}
              id="cam"
              style={{
                left: 0,
                right: 0,
                textAlign: 'center',
                zindex: 9,
                width: '100%',
                height: '360px',
                borderRadius: '8px',
              }}
              screenshotFormat="image/jpeg"
              width={1280}
              videoConstraints={{
                width: 1280,
                height: 720,
                facingMode: 'user',
              }}
            />
            <ButtonContainer>
              <Button
                onClick={detectExpressions}
                style={{ marginTop: '36px', maxWidth: '200px' }}
              >
                {t('tirar foto')}
                {/* Tirar foto */}
              </Button>
            </ButtonContainer>
          </>
        )}
      </WebcamContainer>

      <InfoContainer>
        <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 0 }}>
          <h1>
            {t('pode continuar pelo telefone')}
            {/* Você pode continuar pelo celular! */}
          </h1>
        </Row>
        <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 20 }}>
          <h2 style={{ fontSize: '16px', textAlign: 'center' }}>
            {t('aponte a camera')}
            {/* Aponte a câmera do seu celular para o código QR abaixo e continue a abertura da sua conta pelo app. */}
          </h2>
        </Row>
        <QRCode size={200} value={`${settings.ib_url}/invite`} />
      </InfoContainer>
    </Content>
  );
};

WebcamDetectsSmile.propTypes = {
  setSmileFace: PropTypes.func.isRequired,
  setSelfieStep: PropTypes.func.isRequired,
};

WebcamDetectsSmile.defaultProps = {

};

export default withRouter(WebcamDetectsSmile);
