/* eslint-disable react/button-has-type */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';

import { message, Spin, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Titulo } from './styles';

import ButtonN from '../../../../../components/ButtonN';
import InputN from '../../../../../components/InputN';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { CurrentDate, ageCalc, yearBirtday } from '../../../../../components/CurrentDate';

import { useSteps } from '../../../../../hooks/steps';
import { annualRevenue, legalNature } from '../../../../../utilitys/annualRevenue';
import { useSettings } from '../../../../../hooks/settings';
import api from '../../../../../services/api';

const BasicDataPj = () => {
  const {
    nextStep, resetStep, setNumPartners, prevStep, stepsPj, step, exactStep,
  } = useSteps();
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const { t } = useTranslation();
  const { settings } = useSettings();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cnpjData, setCnpjData] = useState({
    associacaoSFL: false,
    openingDate: '',
    corporateName: '',
    fantasyName: '',
    shareCapital: '',
    numberOfEmployees: '',
    annualBilling: undefined,
    numberOfPartners: {
      number: '',
      partners: {},
    },
    legalNature: '',
    categoriaDeNegocios: '',
    numberOfBranches: '',
    serviceTerms: true,
  });
  const [isMei, setIsMei] = useState();
  const [messageEmployees, setMessageEmployees] = useState();

  useEffect(() => {
    isMei
      ? setMessageEmployees('Uma empresa MEI aceita apenas 0 ou 1 funcionários')
      : setMessageEmployees('número não pode ser 0 ou menor que 0');
  }, [cnpjData.numberOfEmployees, isMei]);

  const handleSwich = () => {
    switch (cnpjData.legalNature) {
      case 'Associação/Organização sem fins lucrativos':
        return (
          !cnpjData.openingDate
 || !cnpjData.corporateName
 || cnpjData.openingDate === '0001-01-01'
 || !cnpjData.fantasyName
 || !cnpjData.serviceTerms
 || error
 || !cnpjData.shareCapital
 || !cnpjData.numberOfEmployees
 || cnpjData.numberOfEmployees === '-'
 || +cnpjData.numberOfEmployees < 0
 || !cnpjData.numberOfBranches
 || cnpjData.numberOfBranches < 0
 || cnpjData.numberOfBranches === '-'
 || cnpjData.numberOfPartners.number < 0
 || cnpjData.numberOfPartners.number === '-'
 || !cnpjData.numberOfPartners.number
 || ageCalc(cnpjData.openingDate) < 0
        );
      case 'MEI – Microempreendedor Individual':
        return (
          !cnpjData.openingDate
     || !cnpjData.corporateName
     || cnpjData.openingDate === '0001-01-01'
     || !cnpjData.fantasyName
     || !cnpjData.serviceTerms
     || error
     || !cnpjData.shareCapital
     || !cnpjData.numberOfEmployees
     || !cnpjData.numberOfBranches
     || cnpjData.numberOfBranches < 0
     || cnpjData.numberOfBranches === '-'
     || cnpjData.numberOfPartners.number < 0
     || cnpjData.numberOfPartners.number === '-'
     || !cnpjData.numberOfPartners.number
     || ageCalc(cnpjData.openingDate) < 0
        );
      default:
        return (
          !cnpjData.openingDate
     || !cnpjData.corporateName
     || cnpjData.openingDate === '0001-01-01'
     || !cnpjData.fantasyName
     || !cnpjData.serviceTerms
     || error
     || !cnpjData.shareCapital
     || !cnpjData.numberOfEmployees
     || cnpjData.numberOfEmployees === '0'
     || cnpjData.numberOfEmployees === '-'
     || +cnpjData.numberOfEmployees <= 0
     || !cnpjData.numberOfBranches
     || cnpjData.numberOfBranches < 0
     || cnpjData.numberOfBranches === '-'
     || cnpjData.numberOfPartners.number <= 0
     || cnpjData.numberOfPartners.number === '-'
     || !cnpjData.numberOfPartners.number
     || ageCalc(cnpjData.openingDate) < 0
        );
    }
  };
  useEffect(() => {
    if (
      checkpoint?.cnpjInfo?.cnaePrincipal?.descricao !== undefined
   && sessionStorage.getItem('@qesh:step') === '1'
    ) {
      setCnpjData({ ...cnpjData, categoriaDeNegocios: checkpoint.cnpjInfo.cnaePrincipal.descricao });
    } else {
      resetStep();
      history.replace('/register/');
    }
  }, []);

  const cnpjRegister = {
    name: cnpjData.corporateName,
    document: checkpoint.cpfCnpj,
    trading_name: cnpjData.fantasyName,
    share_capital: cnpjData.shareCapital,
    number_of_employees: cnpjData.numberOfEmployees,
    client_name: settings.name,
    revenues: cnpjData.annualBilling,
    number_of_partners: cnpjData.numberOfPartners.number,
    cnae: checkpoint?.cnpjInfo?.cnaePrincipal?.codigo,
    number_of_affiliates: cnpjData.numberOfBranches,
    opening_date: cnpjData.openingDate,
    legal_nature: cnpjData.legalNature,
  };

  useEffect(() => {
    if (checkpoint.cnpjData) {
      setCnpjData({
        ...checkpoint.cnpjData,
        serviceTerms: false,
        numberOfEmployees: '',
        numberOfPartners: {
          number: '',
          partners: {},
        },
        numberOfBranches: '',
      });
      exactStep(1);
    }
  }, []);

  const handleAdvance = () => {
    setNumPartners(cnpjData.numberOfPartners.number);
    nextStep();
    setLoading(false);
    if (stepsPj.length >= step + 1) {
      history.push(`/register/${stepsPj[step + 1]}`);
    } else {
      history.push(`/register/${stepsPj[step]}`);
    }
  };


  useEffect(() => {
    switch (cnpjData.legalNature) {
      case 'MEI – Microempreendedor Individual':
        setIsMei(true);
        setCnpjData({ ...cnpjData, numberOfBranches: '0' });
        break;
      default:
        setIsMei(false);
    }
  }, []);

  const handleBasicDataPJ = async () => {
    setLoading(true);
    const accountText = localStorage.getItem('@qesh:account');
    const object = JSON.parse(accountText);
    const headers = {
      'Content-Type': 'application/json',
      account: object.id,
    };
    const [openingDay, openingMonth, openingYear] = cnpjData.openingDate.split('/');
    const formatedOpeningDate = `${openingYear}-${openingMonth}-${openingDay}`;
    cnpjRegister.opening_date = formatedOpeningDate;

    return api
      .post('onboarding/companies/', cnpjRegister, { headers })
      .then((response) => {
        setCheckpoint({ ...checkpoint, pjUserId: response.data.id, cnpjData });
        localStorage.setItem('@resposta', JSON.stringify(response.data.id));
        localStorage.setItem('@qesh:accountPj', response.data.id);
        handleAdvance();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response.data || error);
        message.error('Ops, parece que ocorreu um erro. Tente novamente.', 4);
      });
  };

  return (
    <>
      <Titulo>Dados de Cadastro da empresa</Titulo>
      <InputN.CpfCnpj id="cpf-cnpj" label={t('CNPJ')} value={checkpoint.cpfCnpj} disabled />
      <InputN.SelectandSearchModal
        id="natureza-juridica"
        label={t('Natureza Juridica')}
        placeholder={t('Escolha uma opção')}
        value={cnpjData.legalNature ? `${cnpjData.legalNature}` : ''}
        onChange={(value) => {
          setCnpjData({ ...cnpjData, legalNature: value[0].name });
        }}
        list={legalNature}
        needSearchInput
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            {t('Natureza Juridica')}
          </p>
   )}
        subTitle=""
      />
      <InputN.Date
        id="abertura"
        label={t('Data de Abertura')}
        value={cnpjData.openingDate}
        placeholder="Insira apenas números"
        style={
     !cnpjData.openingDate || cnpjData.openingDate === '0001-01-01'
       ? { color: '#D6C5BF' }
       : { color: 'black' }
    }
        onChange={(e) => {
          let { value } = e.target;
          if (value.length > 10) {
            value = value.slice(0, value.length - 1);
          }
          setCnpjData({ ...cnpjData, openingDate: e.target.value });
        }}
        min="1900-10-01"
        max={CurrentDate}
      />
      <InputN
        id="razao-social"
        label="Razão Social"
        placeholder="Insira o nome completo"
        onChange={(e) => {
          setCnpjData({ ...cnpjData, corporateName: e.target.value });
        }}
        value={cnpjData.corporateName}
      />
      <InputN
        id="fantasia"
        label="Nome Fantasia"
        placeholder="Insira o nome completo"
        onChange={(e) => {
          setCnpjData({ ...cnpjData, fantasyName: e.target.value });
        }}
        value={cnpjData.fantasyName}
      />
      <InputN.SelectandSearchModal
        id="capital-social"
        label="Capital Social"
        placeholder="Escolha uma opção"
        value={cnpjData.shareCapital ? `${cnpjData.shareCapital}` : ''}
        onChange={(value) => {
          setCnpjData({ ...cnpjData, shareCapital: value[0].name });
        }}
        list={annualRevenue}
        needSearchInput={false}
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            {t('Capital Social')}
          </p>
   )}
        subTitle={
          <p style={{ fontSize: '0.875rem', padding: '0 3rem' }}>Informe o Capital Social da sua Empresa</p>
    }
      />

      <InputN.Num
        allowZero={isMei}
        onlyOneAndZero={isMei}
        id="funcionarios"
        label="Número de Funcionários"
        placeholder="Insira apenas números"
        maxLength={7}
        value={cnpjData.numberOfEmployees}
        onChange={(e) => {
          setCnpjData({ ...cnpjData, numberOfEmployees: e.target.rawValue });
        }}
        numeral
        numeralDecimalMark=","
        numeralDecimalScale={0}
        delimiter="."
        message={messageEmployees}
      />
      <InputN.SelectandSearchModal
        id="faturamento"
        label="Faturamento Anual"
        placeholder="Escolha uma opção"
        value={cnpjData.annualBilling ? `${cnpjData.annualBilling}` : ''}
        onChange={(value) => {
          setCnpjData({ ...cnpjData, annualBilling: value[0].name });
        }}
        list={annualRevenue}
        needSearchInput={false}
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            Faturamento Anual
          </p>
   )}
        subTitle={(
          <p style={{ fontSize: '0.875rem', padding: '0 3rem' }}>
            De acordo com o Banco Central, precisamos saber o total do seu patrimônio
          </p>
   )}
      />
      <InputN.Num
        id="partners"
        label="Número de Sócios/ Representante Legal"
        maxLength={5}
        placeholder="Insira apenas números"
        numeral
        numeralDecimalScale={0}
        numeralDecimalMark=","
        delimiter="."
        value={cnpjData.numberOfPartners.number}
        onChange={(e) => setCnpjData({ ...cnpjData, numberOfPartners: { number: e.target.rawValue } })}
      />
      <Dropdown
        overlay={(
          <div
            style={{
              width: '20rem',
              border: 'solid var(--primary) 1.5px',
              backgroundColor: '#ffffff',
              padding: '5px',
              borderRadius: '8px',
              margin: 'auto',
            }}
          >
            {cnpjData.categoriaDeNegocios ? cnpjData.categoriaDeNegocios : 'Carregando...'}
          </div>
   )}
        placement="topCenter"
      >
        <button style={{
          width: '100%', backgroundColor: 'transparent', border: 'none', textAlign: 'left',
        }}
        >
          <InputN
            id="categoria"
            label="Categoria de Négocio"
            placeholder="Escolha uma opção"
            value={cnpjData.categoriaDeNegocios ? cnpjData.categoriaDeNegocios : 'Carregando...'}
      // onChange={() =>
      //  setCnpjData({ ...cnpjData, categoriaDeNegocios: checkpoint.cnpjInfo.cnaePrincipal.descricao })
      // }
            readOnly
            disabled
            style={{ width: '100%' }}
          />
        </button>
      </Dropdown>
      <InputN.Num
        disabled={isMei}
        allowZero
        message="Número de filiais não pode ser menor que 0"
        id="filiais"
        label="Quantidade de Filiais"
        maxLength={5}
        placeholder={isMei ? '0' : 'Insira apenas números'}
        value={cnpjData.numberOfBranches}
        onChange={(e) => setCnpjData({ ...cnpjData, numberOfBranches: e.target.rawValue })}
        numeral
        numeralDecimalScale={0}
        numeralDecimalMark=","
        delimiter="."
      />
      {/* <Row wrap={false}>
    <Checkbox
     onClick={() => {
      setCnpjData({ ...cnpjData, serviceTerms: !cnpjData.serviceTerms })
     }}
     style={{ marginRight: '10px' }}
    />
    <div>
     Ao criar sua conta, você concorda com nossos{' '}
     <a href={`${settings.ib_url}/terms`} target='_blank' rel='noopener noreferrer'>
      <span style={{ color: 'var(--primary)' }}>Termos de Serviços </span>
     </a>
     e{' '}
     <a href={`${settings.ib_url}/terms`} target='_blank' rel='noopener noreferrer'>
      <span style={{ color: 'var(--primary)' }}>Política de Privacidade</span>
     </a>
     .
    </div>
   </Row> */}
      <ButtonN
        style={{ marginTop: '5px' }}
        type="primary"
        className="botao"
        onClick={() => handleBasicDataPJ()}
        disabled={handleSwich()}
        loading={loading}
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
      >
        {!loading && 'Continuar'}
      </ButtonN>
      <ButtonN
        onClick={() => {
          prevStep();
          history.push('basicDataPj');
        }}
      >
        Voltar
      </ButtonN>
    </>
  );
};

export default BasicDataPj;
