/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect } from 'react';
import Cleave from 'cleave.js/react';
import { cnpj } from 'cpf-cnpj-validator';

import { Alert } from 'antd';
import { InputPropTypes, DefaultPropTypes } from './Types';
import { Container, StyledInput } from './styles';
import messages from '../MessageError';

const Cnpj = ({
  setIsValid, value, className, ...rest
}) => {
  const optionsCNPJ = { blocks: [2, 3, 3, 4, 2], delimiters: ['.', '.', '/', '-'], numericOnly: true };

  useEffect(() => {
    if (typeof setIsValid === 'function') {
      if (cnpj.isValid(value)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [value, setIsValid]);

  return (
    <Container>
      {!!rest.label && <label htmlFor={rest.id}>{rest.label}</label>}
      <StyledInput
        className={`ant-input ${className}  ${
          value?.length === 14 ? (cnpj.isValid(value) ? 'is-valid' : 'is-invalid') : ''
        }`}
      >
        <Cleave {...rest} value={value} className="ant-input" options={optionsCNPJ} autoFocus />
        <i />
      </StyledInput>
      {!cnpj.isValid(value) && value.length >= 14 && (
      <Alert
        style={{ backgroundColor: 'transparent', border: 'none' }}
        type="error"
        message={cnpj.isValid(value) ? '' : messages.cnpjInvalid}
      />
      )}
    </Container>
  );
};

export default Cnpj;

Cnpj.propTypes = {
  ...InputPropTypes,
  value: (props) => props.value === undefined && new Error('Value necessário para validação!'),
  setIsValid: (props) => props.setIsValid && typeof props.setIsValid !== 'function' && new Error('setIsValid deve ser uma função!'),
};

Cnpj.defaultProps = {
  ...DefaultPropTypes,
  value: null,
  setIsValid: undefined,
};
