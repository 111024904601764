/* eslint-disable no-tabs */
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	margin: 32px auto 0;
	margin-bottom: 100px;
	/* span:first-child {
		margin-left: 64px;
	} */
`;

export const Item = styled.span`
	margin-right: 8px;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	font-size: 10px;
	color: white;
	text-align: center;
	background-color: ${(props) => (props.ativo ? props.color : 'gray')};
	opacity: ${(props) => (props.ativo ? 1 : 0.3)};
	transform: ${(props) => (props.ativo ? 'scaleY(1.5) scaleX(1.5)' : 'scaley(1)')};
	transition: 400ms ease-in-out;

`;
