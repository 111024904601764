/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ButtonN from '../../../../../components/ButtonN';
import { useSteps } from '../../../../../hooks/steps';
import { SubTitle, Title } from '../../../styles';
import Alert from '../../../../../components/SpanError';
import PasswordParamsOP from '../../../../../components/PasswordParamsOP';
import messages from '../../../../../components/MessageError';

import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { handleOpPasswordRequisition } from '../../../../../services/endPointRegister/senhaOpPost';
import PasswordDigits from '../../../../../components/PasswordDigits';
import { PasswordContainer } from '../../../../../components/PasswordParamsOP/styles';

const OperationalPassword = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const {
    nextStep, prevStep, step, stepsPf, stepsPj, exactStep, resetStep,
  } = useSteps();
  const history = useHistory();
  const token = localStorage.getItem('@qesh:token');

  const [trasactionPassword, setTrasactionPassword] = useState('');
  const [confirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('@qesh:token') && sessionStorage.getItem('@qesh:step') === '12') {
      exactStep(12);
    } else {
      resetStep();
      history.replace('/register/');
    }
  });

  const handleAdvance = async () => {
    setLoading(true);
    setCheckpoint({ ...checkpoint, trasactionPassword });
    let error = false;
    try {
      const requisition = await handleOpPasswordRequisition(trasactionPassword, token);
      error = requisition;
    } catch (err) {
      setLoading(false);
    } finally {
      if (!error.status) {
        message.error('Ops, parece que ocorreu um erro. Tente novamente.');
        setLoading(false);
      } else {
        setCheckpoint({ ...checkpoint, statusPageCode: 'ApprovedAccountPf' });
        localStorage.setItem('@qesh:statuspagecode', 'ApprovedAccountPf');
        nextStep();
        setLoading(false);
        if (stepsPf.length >= step + 1) {
          history.push(`/register/${stepsPf[step + 1]}`);
        } else {
          history.push(`/register/${stepsPf[step]}`);
        }
      }
    }
  };

  return (
    <>
      <Title>Crie uma senha de 4 dígitos</Title>
      <SubTitle>
        <span style={{ display: 'block', fontWeight: 'bold', fontSize: '0.9rem' }}>
          Hora de criar sua senha de 4 dígitos!
        </span>

        <span>Ela será usada para que você confirme suas transações.</span>
      </SubTitle>
      <PasswordParamsOP value={trasactionPassword} />
      <PasswordContainer>
        <PasswordDigits setPasswordValue={setTrasactionPassword} />
      </PasswordContainer>

      {!confirmPassword ? null : confirmPassword !== trasactionPassword ? (
        <Alert message={messages.passwordDifferent} type="error" />
      ) : null}
      <ButtonN
        type="primary"
        className="botao"
        disabled={trasactionPassword.length < 4 || !/^\d+$/gm.test(trasactionPassword)}
        onClick={() => handleAdvance()}
        loading={loading}
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
      >
        {!loading && 'Continuar'}
      </ButtonN>
      <ButtonN
        onClick={() => {
          prevStep();
          history.replace(`/register/${stepsPf[exactStep(step - 1)]}`);
        }}
        disabled={loading}
      >
        Voltar
      </ButtonN>
    </>
  );
};

export default OperationalPassword;
