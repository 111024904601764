/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  identityCardFront,
  identityCardVerse,
  driverLicenseFront,
  driverLicenseVerse,
  documentsForeign,
} from '../../../../services/endPointRegister/identityPost';

import {
  Text,
  Tips,
  Card,
  Container,
  DropButton,
  DropContainer,
  Preview,
  Buttons,
  MainTitle,
  Options,
  Option,
  Subtitle,
  Section,
  Box,
  Texts,
  Displayer,
  Conditions,
  ModalTitle,
  SubtitleModal,
  TextModal,
  DocumentsTextContainer,
} from './styles';
import ButtonN from '../../../../components/ButtonN';
import { useSettings } from '../../../../hooks/settings';
import ModalForTransition from '../../../../components/ModalForTransition';
import { useRefresh } from '../../../../hooks/refresh';
import RGFront from '../../../../assets/RG_front.png';
import RGVerse from '../../../../assets/RG_verse.png';
import CNHFront from '../../../../assets/CNH_front.png';
import CNHVerse from '../../../../assets/CNH_verse.png';

const Documents = () => {
  const { getEmailSupportMessage } = useSettings();

  const history = useHistory();
  const [cont, setCont] = useState(0);
  const [files, setFiles] = useState([]);
  const [blacklistTest, setBlackListTest] = useState(false);
  const [typeError, setTypeError] = useState('');
  const [nationality, setNationality] = useState('Brasil');
  const {
    address, token, refreshApi, setStep,
  } = useRefresh();
  const [frente, setFrente] = useState(true);
  const [documentsData, setDocumentsData] = useState({
    documentsType: '',
    documentsFront: '',
    documentsBack: '',
  });
  const [loading, setLoading] = useState(false);
  const documentContainerText = {
    title: cont === 0
      ? `Exemplo ${frente ? 'da frente' : 'do verso'} do documento`
      : 'Envie a foto da frente do seu RG',
    subtitle: cont === 0
      ? `Siga o exemplo abaixo e tire foto ${frente ? 'da frente' : 'do verso'} do documento`
      : '',
  };

  const getDocumentContainerPhoto = useCallback(
    () => {
      const documentsImage = documentsData.documentsType === 'RG'
        ? { front: RGFront, verse: RGVerse }
        : { front: CNHFront, verse: CNHVerse };
      return frente ? documentsImage.front : documentsImage.verse;
    },
    [frente, documentsData.documentsType],
  );

  useEffect(() => {
    const nat = address.country;
    if (nat) {
      setNationality(nat);
    } else {
      setNationality('Brasil');
    }
  });
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      const array = files;
      acceptedFiles.map((file) => {
        if (['png', 'jpg']
          .includes(file?.name?.slice(-3).toLowerCase()) || file?.name?.slice(-4).toLowerCase() === 'jpeg') {
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            if (files.length < 2) {
              setDocumentsData({ ...documentsData, documentsFront: files[0] });
            } else {
              setDocumentsData({
                ...documentsData,
                documentsBack: files[1],
              });
            }
          };
          array.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          );
        } else {
          message.error('Tipo de arquivo inválido, favor enviar nos seguintes formatos: png, jpg ou jpeg');
        }
      });

      if (files[files.length - 1].size <= 5000000) {
        setFiles(array);
        if (documentsData.documentsType === 'PAS') {
          setCont(cont + 1);
        } else {
          setCont(cont + 1);
        }
      } else {
        message.error('O Arquivo tem que ser menor ou igual que 5MB. Por favor nos envie um menor!', 6);
      }
    },
  });
  const handleDelete = () => {
    files.pop();
    setFiles((file) => file);
  };
  const handleFrontImageUpload = async () => {
    setLoading(true);
    let error;
    try {
      const requisition = async () => {
        switch (documentsData.documentsType) {
          case 'CNH':
            return driverLicenseFront(documentsData.documentsFront, null);
          case 'RG':
            return identityCardFront(documentsData.documentsFront, null);
          case 'PAS':
            return documentsForeign(documentsData.documentsFront, null, 'PASSPORT_CARD');
          case 'RNE':
            return documentsForeign(documentsData.documentsFront, null, 'RNE_FRONT');
          case 'RNM':
            return documentsForeign(documentsData.documentsFront, null, 'CRNM_FRONT');
          default:
        }
      };
      error = await requisition();
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
      if (error[0]) {
        if (error[1] === 'Person blocked - BLACKLIST ') {
          setBlackListTest(true);
          setTypeError('blacklist');
        } else if (error[1].attempts < 1) {
          setTypeError('attempts');
          setBlackListTest(true);
        } else if (error[1].message?.includes('Error data validate')) {
          setTypeError('blacklist');
        } else {
          setTypeError('error');
          setBlackListTest(true);
        }
      } else {
        setFrente(false);
        setCont(cont - 1);
      }
    }
  };

  const handleHome = async () => {
    setLoading(false);
    setBlackListTest(false);
    history.replace('/');
  };
  const handleAdvance = async () => {
    setLoading(true);
    let error;
    // let messageError
    try {
      const requisition = async () => {
        switch (documentsData.documentsType) {
          case 'CNH':
            return driverLicenseVerse(documentsData.documentsBack, null);
          case 'RG':
            return identityCardVerse(documentsData.documentsBack, null);
          case 'RNE':
            return documentsForeign(documentsData.documentsBack, null, 'RNE_VERSE');
          case 'RNM':
            return documentsForeign(documentsData.documentsBack, null, 'CRNM_VERSE');
          default:
        }
      };
      error = await requisition();
      message.success('Documento enviado com sucesso', 4);
    } catch {
      setLoading(false);
    } finally {
      if (error[0]) {
        if (error[1] === 'Person blocked - BLACKLIST ') {
          setBlackListTest(true);
          setTypeError('blacklist');
        } else if (error[1].attempts === 0) {
          setBlackListTest(true);
          setTypeError('attempts');
        } else {
          setBlackListTest(true);
          setTypeError('error');
        }
      } else {
        try {
          await refreshApi.classify();
          setLoading(false);
          setStep('done');
        } catch (err) {
          message.error(err.message);
          setLoading(false);
        }
      }
    }
  };

  const modalText = () => {
    if (typeError === 'blacklist') {
      return getEmailSupportMessage();
    }
    if (typeError === 'attempts') {
      return `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`;
    }
    if (typeError === 'error') {
      return 'Tente tirar uma nova foto e reenviar';
    }
  };

  return (
    <>
      <Section>
        <Texts>
          <MainTitle>Agora é hora de enviar as fotos do seu documento</MainTitle>
          {!documentsData.documentsType && (
          <Subtitle>
            Escolha qual documento você deseja nos enviar e siga as instruções para tirar uma foto da
            frente e outra do verso.
          </Subtitle>
          )}
        </Texts>
        <Card>
          {(!documentsData.documentsType && (
          <>
            <MainTitle style={{ color: 'var(--label)' }}>Qual documento quer nos enviar?</MainTitle>
            <h5 style={{ padding: '.4rem', textAlign: 'center' }}>
              Escolha uma das opções abaixo e siga as instruções de envio.
            </h5>
            <Conditions>Envie apenas arquivos de imagem com no máximo 5MB</Conditions>
            <Options>
              {(nationality.toLowerCase() === 'brasil' && (
              <>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'RG' })}
                >
                  Carteira de identidade (RG)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'CNH' })}
                >
                  Carteira de motorista (CNH)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
              </>
              )) || (
              <>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'RNM' })}
                >
                  Carteira Nacional Migratório (CRNM)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'RNE' })}
                >
                  Registro Nacional Estrangeiros (RNE)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>

                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'PAS' })}
                >
                  Passaporte
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
              </>
              )}
            </Options>
          </>
          )) || (
          <>
            <Container>
              <h1>Agora é hora de enviar as fotos do seu documento</h1>
              <Box>
                {documentsData.documentsType === 'PAS' ? (
                  <h2>Envie foto do seu PASSAPORTE</h2>
                ) : (
                  <DocumentsTextContainer>
                    <h2>
                      {documentContainerText.title}
                    </h2>
                    <p>{documentContainerText.subtitle}</p>
                  </DocumentsTextContainer>
                )}
                {cont === 0 && (
                  <>
                    <Preview style={{ width: '45%' }} src={getDocumentContainerPhoto()} alt="img" />
                    <DropContainer {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
                        <DropButton>
                          <FontAwesomeIcon icon={faFileUpload} size="5x" />
                        </DropButton>
                        <h2>Fazer o upload</h2>
                      </div>
                    </DropContainer>
                  </>
                )}
                {cont === 1 && (
                <Displayer>
                  <Preview src={files[files.length - 1].preview} alt="img" />
                </Displayer>
                )}
              </Box>
            </Container>
          </>
          )}
        </Card>

        {cont === 1 && (
        <Buttons>
          <ButtonN
            onClick={() => {
              handleDelete();
              setCont(0);
            }}
            disabled={loading}
          >
            Excluir
          </ButtonN>
          <ButtonN
            type="primary"
            className="botao"
            loading={loading}
            onClick={() => {
              if (files.length < 2) {
                if (documentsData.documentsType === 'PAS') {
                  handleAdvance();
                } else handleFrontImageUpload();
              } else {
                handleAdvance();
              }
            }}
            icon={loading && <Spin indicator={<LoadingOutlined />} />}
          >
            {!loading && 'Próximo'}
          </ButtonN>
        </Buttons>
        )}
      </Section>
      <ModalForTransition visibility={blacklistTest} setVisibility={setBlackListTest} style={{ height: '100%' }}>
        <>
          <ModalTitle>Sentimos muito!</ModalTitle>
          <SubtitleModal>
            {typeError === 'blacklist' ? 'Sentimos muito, não será possível prosseguir' : ''}
          </SubtitleModal>
          <TextModal>{modalText()}</TextModal>
          <ButtonN
            type="primary"
            className="botao"
            onClick={() => {
              if (typeError === 'blacklist') {
                handleHome();
              } else {
                setBlackListTest(false);
                setTypeError('');
              }
            }}
          >
            Confirmar
          </ButtonN>
        </>
      </ModalForTransition>
    </>
  );
};

export default Documents;
