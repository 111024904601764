/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-deprecated */
import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { withRouter } from 'react-router-dom';
import Webcam from 'react-webcam';
import { notification } from 'antd';
import * as faceapi from 'face-api.js';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../../styles/components';
import {
  ButtonContainer, Content, WebcamContainer, InfoContainer,
} from './styles';
import api from '../../../../services/api';
import Button from '../../../../components/Button';
import { useSettings } from '../../../../hooks/settings';

const WebcamDetectsNeutral = (props) => {
  const { settings } = useSettings();
  const minConfidenceFace = 0.5;
  const { t } = useTranslation();

  const webcamRef = useRef(null);

  const [image, setImage] = useState();

  const notifyRenderer = useCallback((_command, payload) => {
    if (!payload.type) {
      return;
    }

    if (payload.type === 'not_recognized') {
      notification.error({ message: 'Não foi reconhecido nenhum rosto.' });

      return;
    }

    if (payload.type !== 'neutral') {
      notification.error({ message: 'Por favor capture uma imagem sério(a).' });

      return;
    }

    const screenshot = webcamRef.current.getScreenshot({ width: 1920, height: 1080 });

    setImage(screenshot);
  }, []);

  const onReady = useCallback(() => {
    notifyRenderer('ready', {});
  }, [notifyRenderer]);

  const onExpression = useCallback((type) => {
    notifyRenderer('expression', { type });
  }, [notifyRenderer]);

  const detectExpressions = useCallback(async () => {
    const faceapiOptions = new faceapi.SsdMobilenetv1Options({ minConfidenceFace });

    const faceResult = await faceapi.detectSingleFace(webcamRef.current.video, faceapiOptions)
      .withFaceLandmarks().withFaceExpressions().withFaceDescriptor();

    onReady();

    if (typeof faceResult !== 'undefined') {
      let happiness = 0;
      let neutral = 0;
      let surprised = 0;
      let angry = 0;

      if (faceResult.expressions.happy) {
        happiness = faceResult.expressions.happy;
      }

      if (faceResult.expressions.neutral) {
        neutral = faceResult.expressions.neutral;
      }

      if (faceResult.expressions.surprised) {
        surprised = faceResult.expressions.surprised;
      }

      const euclideanDistance = await faceapi.euclideanDistance(faceResult.descriptor, props.smileFace.descriptor);

      if (euclideanDistance > 0.5) {
        notification.error({ message: 'Os rostos não batem.', duration: 3000, top: true });

        props.setSelfieStep('smile_pic');

        return;
      }

      if (faceResult.expressions.angry) {
        angry = faceResult.expressions.angry;
      }

      if (happiness > 0.7) {
        onExpression('happy');
      } else if (neutral > 0.7) {
        onExpression('neutral');
      } else if (surprised > 0.7) {
        onExpression('surprised');
      } else if (angry > 0.7) {
        onExpression('angry');
      } else {
        onExpression('not_recognized');
      }
    } else {
      onExpression('not_recognized');
    }
  }, [onExpression, onReady, props]);

  const loadNet = useCallback(async () => {
    const MODEL_URL = `${process.env.PUBLIC_URL}/models`;

    const detectionNet = faceapi.nets.ssdMobilenetv1;

    await detectionNet.load(MODEL_URL);
    await faceapi.loadFaceExpressionModel(MODEL_URL);
    await faceapi.loadAgeGenderModel(MODEL_URL);
    await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
    await faceapi.loadFaceLandmarkModel(MODEL_URL);
    await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
    await faceapi.loadFaceRecognitionModel(MODEL_URL);

    return detectionNet;
  }, []);

  useEffect(() => {
    loadNet();

    navigator.mediaDevices.enumerateDevices()
      .then((res) => {
        const devices = res.filter(({ kind }) => kind === 'videoinput');

        if (devices.length <= 0) {
          props.setSelfieStep('no_webcam');
        }
      });
  }, [loadNet, props]);

  const handleSendSelfie = useCallback(async () => {
    try {
      const formData = new FormData();

      const byteCharacters = atob(image.split(',')[1]);

      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: 'image/jpeg;base64' });

      formData.append('file', blob, 'selfie.png');

      await api.post('users/documents?type=SELFIE', formData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('@ib:register')}`,
        },
      });

      props.setNextStep();
    } catch (e) {
      notification.error({ message: 'Ocorreu um erro tente novamente mais tarde.' });
    }
  }, [image, props]);

  return (
    <Content>
      <WebcamContainer>
        {image ? (
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
          }}
          >
            <Row gutter={10} style={{ marginBottom: 20 }}>
              <h1>{t('foto ficou boa?')}</h1>
            </Row>
            <img alt="ksj" src={image} style={{ height: '306px' }} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button
                onClick={() => { setImage(undefined); }}
                style={{ marginTop: '36px', marginRight: '6px' }}
              >
                {t('tirar outra')}
                {/* Tirar outra */}
              </Button>
              <Button
                onClick={handleSendSelfie}
                style={{ marginTop: '36px' }}
              >
                {t('ficou boa')}
                {/* Ficou boa */}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 0 }}>
              <h1>
                {t('tire serio')}
                {/* Envie uma foto como expressão séria. */}
              </h1>
            </Row>
            <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h2 style={{ fontSize: '16px' }}>
                {t('tire serio descricao')}
                {/* Para garantir que somente você terá acesso a sua conta, tire uma foto sério (a).
                Vamos lá? */}
              </h2>
            </Row>
            <Webcam
              ref={webcamRef}
              id="cam"
              style={{
                left: 0,
                right: 0,
                zindex: 9,
                width: '100%',
                height: '360px',
              }}
              screenshotFormat="image/jpeg"
              width={1280}
              videoConstraints={{
                width: 1280,
                height: 720,
                facingMode: 'user',
              }}
              onUserMediaError={() => props.setSelfieStep('no_webcam')}
            />

            <ButtonContainer>
              <Button
                onClick={detectExpressions}
                style={{ marginTop: '36px', marginLeft: 30, maxWidth: '200px' }}
              >
                {t('tirar foto')}
                {/* Tirar foto */}
              </Button>
            </ButtonContainer>
          </>
        )}
      </WebcamContainer>

      <InfoContainer>
        <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 0 }}>
          <h1>
            {t('pode continuar pelo telefone')}
            {/* Você pode continuar pelo celular! */}
          </h1>
        </Row>
        <Row gutter={10} style={{ justifyContent: 'center', marginBottom: 20 }}>
          <h2 style={{ fontSize: '16px', textAlign: 'center' }}>
            {t('aponte a camera')}
            {/* Aponte a câmera do seu celular para o código QR abaixo e continue a abertura da sua conta pelo app. */}
          </h2>
        </Row>
        <QRCode size={200} value={`${settings.ib_url}/invite`} />
      </InfoContainer>
    </Content>
  );
};

WebcamDetectsNeutral.propTypes = {
  setNextStep: PropTypes.func.isRequired,
  setSelfieStep: PropTypes.func.isRequired,
  smileFace: PropTypes.shape({ descriptor: PropTypes.shape({}).isRequired }).isRequired,
};

WebcamDetectsNeutral.defaultProps = {

};

export default withRouter(WebcamDetectsNeutral);
