import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import StylesGlobal from './styles/global';

import Routes from './routes';

import AppProvider from './hooks';
import { getSettings } from './services/client';

function App() {
  const [settings, setSettings] = useState(undefined);
  useEffect(() => getSettings().then((res) => setSettings(res)), []);

  return (
    <AppProvider>
      <Router>
        <Routes />
        <StylesGlobal settings={settings} />
      </Router>
    </AppProvider>
  );
}

export default App;
