/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import Alert from '../SpanError';
import messages from '../MessageError';

import { InputPropTypes, DefaultPropTypes } from './Types';
import { Container, StyledInput } from './styles';

// Mask input usando Cleave.js => https://nosir.github.io/cleave.js/
const Num = (props) => {
  // Todos os valores disponíveis no options => https://github.com/nosir/cleave.js/blob/master/doc/options.md
  const {
    blocks,
    className,
    creditCard,
    creditCardStrictMode,
    date,
    value,
    dateMin,
    dateMax,
    datePattern,
    delimiter,
    delimiters,
    delimiterLazyShow,
    lowercase,
    noImmediatePrefix,
    numeral,
    numericOnly,
    numeralIntegerScale,
    numeralDecimalMark,
    numeralDecimalScale,
    numeralPositiveOnly,
    numeralThousandsGroupStyle,
    onCreditCardTypeChanged,
    onValueChanged,
    phone,
    phoneRegionCode,
    prefix,
    rawValueTrimPrefix,
    signBeforePrefix,
    stripLeadingZeroes,
    swapHiddenInput,
    tailPrefix,
    time,
    timeFormat,
    timePattern,
    uppercase,
    allowZero,
    onlyOneAndZero,
    message,
    ...rest
  } = props;
  const options = {
    blocks,
    creditCard,
    creditCardStrictMode,
    date,
    dateMin,
    dateMax,
    datePattern,
    delimiter,
    delimiters,
    delimiterLazyShow,
    lowercase,
    noImmediatePrefix,
    numeral,
    numericOnly,
    numeralIntegerScale,
    numeralDecimalMark,
    numeralDecimalScale,
    numeralPositiveOnly,
    numeralThousandsGroupStyle,
    onCreditCardTypeChanged,
    onValueChanged,
    phone,
    phoneRegionCode,
    prefix,
    rawValueTrimPrefix,
    signBeforePrefix,
    stripLeadingZeroes,
    swapHiddenInput,
    tailPrefix,
    time,
    timeFormat,
    timePattern,
    uppercase,
    onlyOneAndZero,
  };

  return (
    <Container>
      {!!props.label && <label htmlFor={props.id}>{props.label}</label>}
      <StyledInput
        className={`ant-input ${className} ${
          value === ''
            ? ''
            : value === '-' || (allowZero ? value < 0 : value <= 0) || (onlyOneAndZero && value > 1)
              ? 'is-invalid'
              : value?.length >= 1 || value > 0
                ? 'is-valid'
                : 'is-invalid'
        }`}
      >
        <Cleave {...rest} options={options} />
        <i />
      </StyledInput>
      {value === '' ? null : (!allowZero && +value === 0) || value < 0 || (onlyOneAndZero && value > 1) ? (
        <Alert message={message} type="error" />
      ) : null}
    </Container>
  );
};

export default Num;

Num.propTypes = {
  ...InputPropTypes,
  allowZero: PropTypes.bool,
  onlyOneAndZero: PropTypes.bool,
  message: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.number),
  delimiters: PropTypes.arrayOf(PropTypes.string),
  numericOnly: PropTypes.bool,
};

Num.defaultProps = {
  ...DefaultPropTypes,
  message: messages.numberInvalid,
  allowZero: false,
  onlyOneAndZero: false,
  numericOnly: false,
  blocks: undefined,
  delimiters: undefined,
};
